import React from "react";
import axios from 'axios';
import DefaultModal from "./DefaultModal";
import i18n from "i18n-react";
const url = process.env.REACT_APP_API_URL;

export const DefaultAjax = (params,responseType) => {
  const config = {
      baseURL: url,
      data: params,
      headers: { 'Authorization': document.querySelector("#token").value },
      transformResponse: [(data) => {
          return data;
      }]
  };

  if (responseType) {
      config.responseType = responseType;
  }

  let api = axios.create(config)

  return api;
}

export const functionFordibben = () =>{
  window.location.href = document.querySelector("#platformUrl").value+document.querySelector("#pathRoot").value;
} 

export const modalForbidden = () =>{
  let modalContent = i18n.translate("forbidden");
  
  let buttons = [{text: i18n.translate("default_accept"), function: functionFordibben}];  
  return <DefaultModal show={true} title={i18n.translate("default_error")}  
  content={modalContent} buttons={buttons} modalClass="" closeFunction={functionFordibben} />;
} 