import React from "react";
import { SidebarDataVideos } from "./SidebarDataVideos";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import FooterSidebar from "./FooterSidebar";

const SidebarVideos = () => {
	 
	return (
		<IconContext.Provider value={{ color: "#fff" }}>
			
			<ul className="ulSidebar" id="ulSidebar">
				{SidebarDataVideos.map((item, index) => {
					return <SubMenu item={item} key={index} />;
				})}
			</ul>
			<FooterSidebar url="tutorialVideos"/>
			
		</IconContext.Provider>
	);
};

export default SidebarVideos;
