import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import RemoveContent from "../img/remove_content.png";
import AddContent from "../img/add_content.png";
import Select from 'react-select';
import { toastFunction } from '../pages/../components/mainFunctions'; 

class DefaultDayAndHours extends React.Component{
    constructor(props){
        const optionsHour = [
            { value: '00',label:'00'},{ value: '01',label:'01'},{ value: '02',label:'02'},{ value: '03',label:'03'},{ value: '04',label:'04'},
            { value: '05',label:'05'},{ value: '06',label:'06'},{ value: '07',label:'07'},{ value: '08',label:'08'},{ value: '09',label:'09'},
            { value: '10',label:'10'},{ value: '11',label:'11'},{ value: '12',label:'12'},{ value: '13',label:'13'},{ value: '14',label:'14'},
            { value: '15',label:'15'},{ value: '16',label:'16'},{ value: '17',label:'17'},{ value: '18',label:'18'},{ value: '19',label:'19'},
            { value: '20',label:'20'},{ value: '21',label:'21'},{ value: '22',label:'22'},{ value: '23',label:'23'}
        ]
        const optionsMinute = [
            { value: "00",label:'00'},{ value: "05",label:'05'},{ value: "10",label:'10'},{ value: "15",label:'15'},{ value: "20",label:'20'},
            { value: "25",label:'25'},{ value: "30",label:'30'},{ value: "35",label:'35'},{ value: "40",label:'40'},{ value: "45",label:'45'},
            { value: "50",label:'50'},{ value: "55",label:'55'}, 
        ]

        const dayMapping = {
            "monday": "monday",
            "tuesday": "tuesday",
            "wednesday": "wednesday",
            "thursday": "thursday",
            "friday": "friday",
            "saturday": "saturday",
            "sunday": "sunday"
        };
        
        super(props);
        this.state = {
            checkDays: props.checkDays,
            optionsHour:optionsHour,
            optionsMinute:optionsMinute,
            isUpdate:props.isUpdate,
            dayMapping: dayMapping
        } 
        this.updateIntervalDayAndHour = props.updateIntervalDayAndHour;
    }

    splitHourAndMinutes = (time,index) => {
        let timeSplited = time.split(":");
        return timeSplited[index]; 
    } 

    addOption = () => {
        const checkDaysForAdd = [{
            "monday": false,
            "tuesday": false,
            "wednesday": false,
            "thursday": false,
            "friday": false,
            "saturday": false,
            "sunday": false,
            "startHour": "07:00",
            "endHour": "17:00",
            "administrativeNotificationInstanceId": 0,
            "id": 0,
            "operationType": "A"
        }];
    
        this.setState((prevState) => {
            const updatedCheckDays = [...prevState.checkDays, checkDaysForAdd[0]];
            
            this.setIntervalDayAndHour(updatedCheckDays, true);
            
            return { checkDays: updatedCheckDays };
        });
    } 

    removeOption = (index) => { 
        this.setState((prevState) => {
            let arrayCheckDays = [...prevState.checkDays];
            if(prevState.isUpdate){
                if(arrayCheckDays[index].operationType == 'A'){
                    arrayCheckDays.splice(index,1)
                }else{
                    arrayCheckDays[index] = {
                        ...arrayCheckDays[index],
                        operationType: "D"
                    };
                }  
            }
            this.setIntervalDayAndHour(arrayCheckDays);

            return { checkDays: arrayCheckDays }; 
        })
    } 

    checkTheDay = (index,event) => {
        const dayMapping = {...this.state.dayMapping};

        const dayName = event.target.name;
        
        if (dayMapping[dayName] !== undefined) {
            const isActive = event.target.value === "false";
            const updatedArray = [...this.state.checkDays];
            updatedArray[index] = {
                ...updatedArray[index],
                [dayMapping[dayName]]: isActive
            };

            if (this.state.isUpdate &&
                updatedArray[index].operationType !== "A" &&
                updatedArray[index].operationType !== "D") {
                updatedArray[index].operationType = "M";
            }

            this.setState({ checkDays: updatedArray }, () => {
                this.validateOtherDaysOfWeek(index, event);
            });
        } 
    } 

    validateOtherDaysOfWeek = (index,event) => {
        const dayMapping = {...this.state.dayMapping};
        let arrayToUpdate = [...this.state.checkDays]; 

        const dayName = event.target.name;
        let isChecked = false;

        arrayToUpdate.forEach((ar,i) => {
            if(index != i){
                const currentDay = dayMapping[dayName];
                if(currentDay && (ar[currentDay] === true || ar[currentDay] === "true")){
                    ar[currentDay] = false;
                    isChecked = true; 
                } 
                if(isChecked && this.state.isUpdate && ar.operationType != 'D' && ar.operationType != 'A'){
                    ar.operationType = "M";
                }  
            } 
        }) 

        this.setState({checkDays:arrayToUpdate}, () => {
            this.setIntervalDayAndHour(arrayToUpdate);
        })
    } 

    setIntervalDayAndHour = (arrayToUpdate) => {
        this.updateIntervalDayAndHour(arrayToUpdate);
    } 

    handleChangeTime = (index,event,typeTime) => {
        let arrayForUpdate = this.state.checkDays;
        let TimeToSplit;
        let checkedHourMinute = false;
        if(typeTime == "startHour"){
            TimeToSplit = arrayForUpdate[index].startHour.split(":");
            arrayForUpdate[index].startHour = event.label+":"+TimeToSplit[1];
            checkedHourMinute = true; 
            if(arrayForUpdate[index].startHour > arrayForUpdate[index].endHour){
                toastFunction(i18n.translate("start_must_be_less"),"warning");
                document.querySelector(".buttonMzatePrimary").style.pointerEvents = 'none';
                document.querySelector(".buttonMzatePrimary").classList.add("buttonMzateSecondary");
            }else{
                document.querySelector(".buttonMzatePrimary").style.pointerEvents = '';
                document.querySelector(".buttonMzatePrimary").classList.remove("buttonMzateSecondary")
            }  
        }else if(typeTime == "startHour2"){
            TimeToSplit = arrayForUpdate[index].startHour.split(":");
            arrayForUpdate[index].startHour = TimeToSplit[0]+":"+event.label;
            checkedHourMinute = true;
        }else if(typeTime == "endHour"){
            TimeToSplit = arrayForUpdate[index].endHour.split(":");
            arrayForUpdate[index].endHour = event.label+":"+TimeToSplit[1];
            checkedHourMinute = true;
            if(arrayForUpdate[index].endHour < arrayForUpdate[index].startHour){
                toastFunction(i18n.translate("end_must_be_greater"),"warning");
                document.querySelector(".buttonMzatePrimary").style.pointerEvents = 'none';
                document.querySelector(".buttonMzatePrimary").classList.add("buttonMzateSecondary");
            }else{
                document.querySelector(".buttonMzatePrimary").style.pointerEvents = '';
                document.querySelector(".buttonMzatePrimary").classList.remove("buttonMzateSecondary")
            }  
        }else if(typeTime == "endHour2"){
            TimeToSplit = arrayForUpdate[index].endHour.split(":");
            arrayForUpdate[index].endHour = TimeToSplit[0]+":"+event.label;
            checkedHourMinute = true;
        } 
        if(checkedHourMinute && this.state.isUpdate){
            arrayForUpdate[index].operationType = "M";
        }  
        this.setState({checkDays:arrayForUpdate});
        this.setIntervalDayAndHour(arrayForUpdate);  
    } 


    render(){
        
        let stylesForSelectContent = {
            menuPortal: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'}),
            menu: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'})
        }
        return(
                <div className='table-responsive'>
                <table className='inJQueryAlerts table-sm table-borderless reducedSize'>
                    <thead>
                        <tr>
                            <th></th>
                            <th className='weekday'>{i18n.translate("monday")}</th>
                            <th className='weekday'>{i18n.translate("tuesday")}</th>
                            <th className='weekday'>{i18n.translate("wednesday")}</th>
                            <th className='weekday'>{i18n.translate("thursday")}</th>
                            <th className='weekday'>{i18n.translate("friday")}</th>
                            <th className='weekday'>{i18n.translate("saturday")}</th>
                            <th className='weekday'>{i18n.translate("sunday")}</th>
                            <th className='weekday'>{i18n.translate("default_schedule")}</th>
                            <th className='button'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.checkDays.map((days,index) =>{
                            return(
                                <tr className={days.operationType == 'D' ? 'trWeek displayNone' : 'trWeek'}
                                 key={index}>
                                    <td className='button'>
                                        <img className='removeContent' onClick={() => this.removeOption(index)}  src={RemoveContent} width="20px" height="20px"/>
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="monday" type="checkbox" checked={days.monday}  value={days.monday}/>
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="tuesday" type="checkbox" checked={days.tuesday} value={days.tuesday}/> 
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="wednesday" type="checkbox" checked={days.wednesday} value={days.wednesday}/> 
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="thursday" type="checkbox" checked={days.thursday} value={days.thursday}/> 
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="friday" type="checkbox" checked={days.friday} value={days.friday}/> 
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="saturday" type="checkbox" checked={days.saturday} value={days.saturday}/> 
                                    </td>
                                    <td className={'weekDay-'+index} name={index}>
                                        <input onClick={(event) => this.checkTheDay(index,event)} name="sunday" type="checkbox" checked={days.sunday} value={days.sunday}/> 
                                    </td>
                                    <td>
                                        <div className={'timeInterval-'+index} name={index} style={{display:'inline-flex',width: '150px',marginBottom:'5px'}}>
                                            <Select menuShouldScrollIntoView={false} onChange={(event) => this.handleChangeTime(index,event,'startHour')} maxMenuHeight={220}
                                            options={this.state.optionsHour} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                                            defaultValue={{ label: this.splitHourAndMinutes(days.startHour,0) , value: this.splitHourAndMinutes(days.startHour,0) }}/>
                                            :
                                            <Select menuShouldScrollIntoView={false} onChange={(event) => this.handleChangeTime(index,event,'startHour2')} maxMenuHeight={220}
                                            options={this.state.optionsMinute} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                                            defaultValue={{ label: this.splitHourAndMinutes(days.startHour,1) , value: this.splitHourAndMinutes(days.startHour,1) }}/>
                                        </div>
                                        <div className={'timeInterval-'+index} name={index} style={{display:'inline-flex',width: '150px',marginBottom:'5px'}}>
                                            <Select menuShouldScrollIntoView={false} onChange={(event) => this.handleChangeTime(index,event,'endHour')} maxMenuHeight={220}
                                            options={this.state.optionsHour} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                                            defaultValue={{ label: this.splitHourAndMinutes(days.endHour,0) , value: this.splitHourAndMinutes(days.endHour,0) }}/>
                                            :
                                            <Select menuShouldScrollIntoView={false} onChange={(event) => this.handleChangeTime(index,event,'endHour2')} maxMenuHeight={220}
                                            options={this.state.optionsMinute} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                                            defaultValue={{ label: this.splitHourAndMinutes(days.endHour,1) , value: this.splitHourAndMinutes(days.endHour,1) }}/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })} 
                        <div>
                            <img onClick={this.addOption} className='addContent' src={AddContent} width="30px"/>
                        </div>
                    </tbody>
                </table>
                </div>
        )
    } 
} 

export default DefaultDayAndHours;