import '../App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import { toastFunction } from '../components/mainFunctions'; 
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DefaultModalError = ({errorList,status}) => {
    const [errorListToShow] = useState(errorList);

    const [isOpenModal, setIsOpenModal] = useState(true);
    useEffect(()=>{
		setIsOpenModal(true);
	}, [])

    let buttons = [{text: i18n.translate("default_accept"), function: ""}];

    if(status === -1 || status === "-1"){
        toastFunction(i18n.translate("an_error_has_ocurred"),"error"); 
    }else if(status === -2 || status === "-2"){
        return(
            <Modal isOpen={isOpenModal} 
            modalTransition={{ timeout: 0 }} backdropTransition={{ timeout: 0 }}>
                <ModalHeader className='modalHeader'>
                    {i18n.translate("data_validations")} 
                    <button className='close' data-dismiss="close" onClick={()=>setIsOpenModal(false)}>x</button>
                </ModalHeader>
                <ModalBody className={'modalBody'}>
                    <div>
                        {status === -2 && errorListToShow.length > 1 ? <label>{i18n.translate("the_following_datas_did_not_pass_validations")}</label> : <label>{i18n.translate("the_following_data_did_not_pass_validation")}</label>} 
                        <ul>
                            {errorListToShow.map((error) => {
                                return (
                                    <li key={error}>
                                        {error}
                                    </li>
                                )
                            })}
                        </ul>
                    </div> 
                </ModalBody>
                <ModalFooter>
                    {buttons.map((b,i) =>{
                        return (
                            <button key={i} className={i != 0 ? 'buttonMzateSecondary' : 'buttonMzatePrimary'}
                            onClick={b.function != '' ? b.function : ()=>setIsOpenModal(false)}>{b.text}</button> 
                        )
                    })} 
                </ModalFooter>
            </Modal>
        )
    }else if(status === -3 || status === "-3"){
        toastFunction(i18n.translate("record_does_not_exist"),"error");
    }   
};

export default DefaultModalError;