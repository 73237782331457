import i18n from "i18n-react";
import { Col } from 'reactstrap';
import moment from 'moment';

export const getDateTimeForShow = (date) => {
    var startDate = new Date(date);
    var m = startDate.getMonth() + 1;
    var mes = (m < 10) ? '0' + m : m;
    var day = startDate.getDate();
    var dayForShow = (day < 10) ? '0' + day : day;
    var minutes = startDate.getMinutes();
    var min = (minutes < 10) ? '0' + minutes : minutes;
    var hour = startDate.getHours();
    var hourForShow = (hour < 10) ? '0' + hour : hour;
    var startTime = hourForShow+":"+min;
    startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
    return startDate+" - "+startTime
}

export const setBackgroundToTask = (items) => {
    var dateToCompare = new Date();
    for(var i = 0; i < items.length; i++){
        var dateStart = new Date(items[i].start_time._d);
        var dateEnd = new Date(items[i].end_time._d);
        if(dateToCompare < dateStart && items[i].itemProps.startDate == null && items[i].itemProps.endDate == null){//Pendiente
            items[i].itemProps.style.background = "#1B9CD9";
        }else if(dateToCompare < dateEnd && items[i].itemProps.startDate != null && items[i].itemProps.endDate == null){//En proceso
            items[i].itemProps.style.background = "#93ccea";
        }else if(dateEnd < dateToCompare && items[i].itemProps.startDate != null && items[i].itemProps.endDate == null){//Finalizando
            items[i].itemProps.style.background = "#ffe440";
        }else if(dateStart < dateToCompare && items[i].itemProps.startDate == null){//Inicio atrasado
            items[i].itemProps.style.background = "#F08080";
        }else if(items[i].itemProps.startDate != null && items[i].itemProps.endDate != null){//Terminado
            items[i].itemProps.style.background = "#8DBE22";
        }
    }
    return items
}

export const prepareDateAndTime = (date) => {
    let startDate = new Date(date);
    let m = startDate.getMonth() + 1;
    let mes = (m < 10) ? '0' + m : m;
    let day = startDate.getDate();
    let dayForShow = (day < 10) ? '0' + day : day;
    let minutes = startDate.getMinutes();
    let min = (minutes < 10) ? '0' + minutes : minutes;
    let hour = startDate.getHours();
    let hourForShow = (hour < 10) ? '0' + hour : hour;
    let startTime = hourForShow+":"+min+":00";
    startDate = startDate.getFullYear()+"-"+mes+"-"+dayForShow;
    return startDate+" / "+startTime;
}

export const BooleanSelect = () => {
    return (
        <>
            <option value="true">{i18n.translate("true")}</option> 
            <option value="false">{i18n.translate("false")}</option>
        </>
    )
} 

export const ImgReportTask = (obj) => {
    return(
        <Col style={obj.colStyle}>
            <label style={obj.labelStyle}>{i18n.translate(obj.message)}</label>
                <img src={"data:image/png;base64,"+obj.multimediaFile} className="img-fluid img-thumbnail taskDetailPhoto"
                width="200px" height="auto"/>
        </Col>
    )
} 

export const convertEstimatedDuration = (num) => {
    num = num * 60;
    let horas = Math.floor(num / 3600);
    horas = horas < 10 ? '0' + horas : horas;
    let minutos = Math.floor((num / 60) % 60);
    minutos = minutos < 10 ? '0' + minutos : minutos;
    let segundos = num % 60;
    segundos = segundos < 10 ? '0' + segundos : segundos;
    return horas + ':' + minutos + ':' + segundos;
}

export const setItemsTask = (result,items,flagResponsible) => {
    for(let i = 0; i < result.tasksList.length; i++){
        if(result.tasksList[i].responsibleUsername === "" || result.tasksList[i].responsibleUsername === undefined){
            result.tasksList[i].responsibleUsername = null;
        } 
        items.push({"id":result.tasksList[i].id,"group":result.tasksList[i].responsibleUsername,
        "title":result.tasksList[i].description,"start_time":moment(result.tasksList[i].plannedDateStart),
        "end_time":moment(result.tasksList[i].plannedDateEnd),"canMove":true,"canResize":false,"canChangeGroup":true,
        "itemProps":result.tasksList[i]
        });
        items[i].itemProps.className = 'weekend';
        items[i].itemProps.ariaHidden = true;
        items[i].itemProps.style = {"background":'#1B9CD9',"borderRadius":'5px'};
        if(items[i].itemProps.responsibleFullname == null || items[i].itemProps.responsibleFullname === "" || items[i].itemProps.responsibleFullname === undefined){
            flagResponsible = true;
        } 
    }
    return flagResponsible;
} 
