import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormGroup, Row, Col } from 'reactstrap';
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import { saveEditExtraStorage } from "./AbmStorageFunctions"; 
import { toastFunction, catchErrorAjax } from '../../components/mainFunctions'; 
import * as FaIcons from "react-icons/fa";
import { getDateForShow } from '../../components/SidebarFunctions';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import DefaultSpinner from "../../components/DefaultSpinner";
import AddContent from "../../img/add_content.png";
import RemoveContent from "../../img/remove_content.png";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

const url = process.env.REACT_APP_API_URL;

class AbmStorage extends React.Component {
    constructor(props) {
		super(props);
        this.state={
            data: [],
            listStorage: [],
            listStorageToUpdate: [], 
            dataExtraStorage: [], 
            listExtraStorage: [], 
            listStorageContent:"",
            isUpdate: false,
            modalContent: "",
            modalContent2: "",
            spinner: "",
            filterContent: "",
            modalContentDefaultStorage: "",
            form:{
                id:'',
                qtyFrom: '',
                qtyTo:'',
                storageSize: ''
            },
            formExtraStorage:{
                id:'',
                moduleCode: '',
                moduleDescription: '',
                storageSize: '',
                status:'',
                price:'',
                stripePricesId:'',
                detail: []
            } 
        };
	}

    getPlansStorage=()=>{
        let controllerAction;
        controllerAction = "extraStoragePack/extraStoragePacks?pageNumber=0&limit=50&isFromSupport=true";
        let instanceType = document.querySelector("#instanceType").value;
        let independent = document.querySelector("#independent").value;
        this.setState({spinner:<DefaultSpinner instanceType={instanceType} 
            independent={independent}/>})

        DefaultAjax('').get(controllerAction)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    listExtraStorage:result,
                    dataExtraStorage:result,
                    spinner: ""
                });
                setTimeout(()=> {
                    this.setState({
                        listStorageContent: this.extraStorageContent(),
                        filterContent: this.getFilterContent()
                    });
                    setTimeout(()=>{
                        this.filterExtraStorage(1,"");
                    }, 100)
                }, 100);
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    defaultStorageContent = () => {
        return <Row>
            {this.state.listStorage.map((list,index) =>{
                return(
                    <Col sm={12} md={6} lg={6} xl={6} key={list.id} >
                        <div className='boxList' style={{cursor:'auto'}}>
                            <div>
                                Mb <FaIcons.FaHdd/>
                                <ReactTooltip id={"delete_storage_range"+list.id} place="top" effect="solid"/>
                                <img style={{float: 'right',cursor:'pointer'}}  
                                src={RemoveContent} width="20px" height="20px" 
                                onClick={() => this.removeDefaultStorage(index)} 
                                data-tooltip-id={"delete_storage_range"+list.id} data-tooltip-content={i18n.translate("delete_storage_range")}/>
                                <input style={{textAlign:'center'}} 
                                className='form-control inputStandard' value={list.storageSize}
                                onChange={(e) => this.calculateValueForDefaultStorage(list.id,index,'storageSize',list.storageSize,e)}
                                type='number'/>
                            </div>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12} style={{marginTop:'10px'}}>
                                    {i18n.translate("default_modules")} <FaIcons.FaBoxes/>
                                </Col>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    {i18n.translate("default_from")}
                                    <input style={{textAlign:'center'}} 
                                    className='form-control inputStandard' value={list.qtyFrom}
                                    onChange={(e) => this.calculateValueForDefaultStorage(list.id,index,'qtyFrom',list.qtyFrom,e)}
                                    type='number'/>
                                </Col>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    {i18n.translate("default_to")}
                                    <input style={{textAlign:'center'}} 
                                    className='form-control inputStandard' value={list.qtyTo}
                                    onChange={(e) => this.calculateValueForDefaultStorage(list.id,index,'qtyTo',list.qtyTo,e)}
                                    type='number'/>  
                                </Col>
                            </Row>
                        </div>
                    </Col>
                )
            })}
            <div>
                <ReactTooltip id={"new_storage_range"} place="top" effect="solid"/>
                <img className='addContent' src={AddContent} width="20px" 
                height="20px" onClick={() => this.addDefaultStorage()} 
                data-tooltip-id={"new_storage_range"} data-tooltip-content={i18n.translate("new_storage_range")}/>
            </div>
        </Row>
    } 

    removeDefaultStorage = (index) => {
        let setConcat = true;
        let arrayListStorage = this.state.listStorage;
        if(arrayListStorage[index] != undefined){
            arrayListStorage[index].operationType = "D";
        } 

        if(arrayListStorage[index].id != null){
            setConcat = this.checkIfExistsInListToUpdate(arrayListStorage[index],true);

            if(setConcat){
                this.setState({
                    listStorageToUpdate: this.state.listStorageToUpdate.concat(arrayListStorage[index])
                })
            }
        }else{
            for(let i = 0; i < this.state.listStorageToUpdate.length; i++){
                if(this.state.listStorageToUpdate[i].qtyFrom == arrayListStorage[index].qtyFrom){
                    this.state.listStorageToUpdate.splice(i,1);
                } 
            } 
        }  
         
        arrayListStorage.splice(index,1);
        if(arrayListStorage[index-1] != undefined){
            if(arrayListStorage[index] != undefined){
                arrayListStorage[index-1].qtyTo = arrayListStorage[index].qtyFrom-1;
            } 
            arrayListStorage[index-1].operationType = 'M';
            setConcat = this.checkIfExistsInListToUpdate(arrayListStorage[index-1]);
            if(setConcat){
                setTimeout(()=> { 
                    this.setState({
                        listStorageToUpdate: this.state.listStorageToUpdate.concat(arrayListStorage[index-1])
                    })
                }, 20);
            } 
        } 
        this.setState({
            listStorage:arrayListStorage
        });
        this.updateModalContent();
    }  

    addDefaultStorage = () => {
        let arrayListStorage = this.state.listStorage;
        let index = arrayListStorage.length;
        if(index != 0){
            if(arrayListStorage[index-1].qtyTo == '' || arrayListStorage[index-1].qtyTo == null || 
            arrayListStorage[index-1].qtyTo == undefined){
                toastFunction(i18n.translate("you_must_define_the_until"),"warning");
                return;
            } 
            arrayListStorage.push({id:null,storageSize:arrayListStorage[index-1].storageSize+1,
                qtyTo:"",qtyFrom:arrayListStorage[index-1].qtyTo+1,
                operationType:"A"});
            this.setState({
                listStorageToUpdate: this.state.listStorageToUpdate.concat({id:null,storageSize:arrayListStorage[index-1].storageSize+1,
                    qtyTo:"",qtyFrom:arrayListStorage[index-1].qtyTo+1,
                    operationType:"A"})
            })
        }else{
            arrayListStorage.push({id:null,storageSize:10,
                qtyTo:"",qtyFrom:1,
                operationType:"A"});
            this.setState({listStorageToUpdate:arrayListStorage});
        }  
            
        this.setState({
            listStorage:arrayListStorage
        });
        this.updateModalContent();
    } 

    updateModalContent = () => {
        setTimeout(()=> {
            this.setState({modalContentDefaultStorage:this.defaultStorageContent()})
        }, 10);
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("save"), function: this.saveDefaultRangeStorage},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("standard_spaces")}
            content={this.state.modalContentDefaultStorage} modalClass={'modalContentDefaultStorage'} 
            buttons={buttons} size={'lg'} />}) 
        }, 20);
    } 

    saveDefaultRangeStorage = () => {
        DefaultAjax('').put("defaultStorageRange/defaultStorageRange",this.state.listStorageToUpdate)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(res.status == 200){
                    toastFunction(i18n.translate("new_storage_range_created"),"success");
                    this.setState({
                        spinner:"",
                        modalContent:"",
                        listStorage:[],
                        listStorageToUpdate: [] 
                    });
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    calculateValueForDefaultStorage = (id, index, type, value, e) => {
        let arrayListStorage = [...this.state.listStorage];
        this.setState({ currentMapStorage: arrayListStorage });
    
        const validateQty = (value, minValue, maxValue, errorMessage) => {
            if (value === "") return false;
            const parsedValue = parseInt(value);
            if (parsedValue === 0) {
                toastFunction(i18n.translate("the_minimum_quantity_is_1"), "warning");
                return false;
            }
            if (parsedValue < minValue || parsedValue > maxValue) {
                toastFunction(i18n.translate(errorMessage), "warning");
                return false;
            }
            return true;
        };
    
        const updateQtyTo = (value) => {
            if (arrayListStorage[index + 1]) {
                if (!validateQty(value, arrayListStorage[index].qtyFrom, arrayListStorage[index + 1].qtyTo, "cannot_be_greater_than_up_to")) {
                    arrayListStorage[index].qtyTo = "";
                    return;
                }
                arrayListStorage[index].qtyTo = parseInt(value);
                arrayListStorage[index + 1].qtyFrom = parseInt(value) + 1;
                arrayListStorage[index + 1].operationType = "M";
                this.updateListStorage(arrayListStorage[index + 1]);
            } else {
                arrayListStorage[index].qtyTo = value === "" ? "" : Math.max(arrayListStorage[index].qtyFrom + 1, parseInt(value));
            }
        };
    
        const updateQtyFrom = (value) => {
            if (!validateQty(value, 1, Infinity, "value_cannot_be_empty")) {
                arrayListStorage[index].qtyFrom = this.state.currentMapStorage[index].qtyFrom;
                return;
            }
            if (arrayListStorage[index - 1]) {
                const prev = arrayListStorage[index - 1];
                const parsedValue = parseInt(value);
                if (parsedValue <= prev.qtyFrom) {
                    toastFunction(i18n.translate("cannot_be_greater_than_from"), "warning");
                    return;
                }
                if (parsedValue >= (arrayListStorage[index].qtyTo || Infinity)) {
                    toastFunction(i18n.translate("cannot_be_greater_than_up_to"), "warning");
                    return;
                }
                prev.qtyTo = parsedValue - 1;
                prev.operationType = "M";
                this.updateListStorage(prev);
            }
            arrayListStorage[index].qtyFrom = parseInt(value);
        };
    
        if (arrayListStorage[index].id !== null) {
            arrayListStorage[index].operationType = "M";
        }
    
        switch (type) {
            case 'storageSize':
                arrayListStorage[index].storageSize = parseInt(e.target.value);
                break;
    
            case 'qtyTo':
                updateQtyTo(e.target.value);
                break;
    
            case 'qtyFrom':
                updateQtyFrom(e.target.value);
                break;
    
            default:
                break;
        }
    
        setTimeout(() => {
            this.updateListStorage(arrayListStorage[index]);
            this.setState({ listStorage: arrayListStorage });
            this.updateModalContent();
        }, 20);
    };
    
    
    updateListStorage = (item) => {
        let setConcat = this.checkIfExistsInListToUpdate(item);
        if (setConcat) {
            this.setState(prevState => ({
                listStorageToUpdate: [...prevState.listStorageToUpdate, item]
            }));
        }
    };
    
    checkIfExistsInListToUpdate = (arrayListInstance, isDelete) => {
        const listToUpdate = [...this.state.listStorageToUpdate];
        let setConcat = true;
        for (let i = 0; i < listToUpdate.length; i++) {
            if (listToUpdate[i].id === null || listToUpdate[i].id === arrayListInstance.id) {
                listToUpdate[i] = arrayListInstance;
                setConcat = false;
                if (isDelete) {
                    listToUpdate[i].operationType = 'D';
                }
                break;
            }
        }
        this.setState({ listStorageToUpdate: listToUpdate });
        return setConcat;
    };
     

    getFilterContent = () => {
        return <>
            <label onClick={(e)=>this.filterExtraStorage(0,"all")}>
                <input className='filterExtraStorageClass' type='radio'/>{i18n.translate("default_all")}
            </label>
            <label onClick={(e)=>this.filterExtraStorage(1,"")}>
                <input className='filterExtraStorageClass' type='radio'/>{i18n.translate("default_active")}
            </label>
            <label onClick={(e)=>this.filterExtraStorage(2,"")}>
                <input className='filterExtraStorageClass' type='radio'/>{i18n.translate("default_inactive")}
            </label>
        </>
    }

    extraStorageContent = () => {
        return <>
            <Row>
                {this.state.listExtraStorage.map((list) => {
                    return(
                        <Col sm={6} md={6} lg={6} xl={6} onClick={() => this.showExtraStorage(list.id)} key={list.id} >
                            <div className='boxList'>
                                <div>{list.moduleCode} - {list.moduleDescription}</div>
                                <ReactTooltip id={"storageSize"+list.id} place="top" effect="solid"/>
                                <div data-tooltip-id={"storageSize"+list.id} data-tooltip-content={i18n.translate("default_storage")} >{list.storageSize} Mb <FaIcons.FaHdd/></div>
                                <ReactTooltip id={"price"+list.id} place="top" effect="solid"/>
                                <div data-tooltip-id={"price"+list.id} data-tooltip-content={i18n.translate("storage_price")}>{list.price}<FaIcons.FaDollarSign/></div>
                                <div style={{display: 'flow-root'}}>
                                    <div style={{display: 'inline-block',float: 'left'}}>
                                        <ReactTooltip id={"status"+list.id} place="top" effect="solid"/>
                                        {list.status == '0' ? <FaIcons.FaPowerOff data-tooltip-id={"status"+list.id} data-tooltip-content={i18n.translate("default_enabled")} style={{color:'green'}}/> : <FaIcons.FaPowerOff data-tooltip-id={"status"+list.id} data-tooltip-content={i18n.translate("default_disabled")} style={{color:'#8B0000'}}/>}
                                    </div>
                                    {list.serviceExtraStoragePackList && 
                                        <div style={{display: 'inline-block',float: 'right'}}>({list.serviceExtraStoragePackList.length}) {i18n.translate("default_services")}</div>
                                    }
                                    {!list.serviceExtraStoragePackList && 
                                        <div style={{display: 'inline-block',float: 'right'}}>(0) {i18n.translate("default_services")}</div>
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })} 
            </Row>
        </>
    } 

    componentDidMount(){
        this.getPlansStorage();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbarT").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 90;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
    }

    filterPlans =e=>{
        let filterSearch = e.target.value.toLowerCase();
        
        let searchRes = this.state.dataExtraStorage.filter((e) => {
            let moduleDescription = e.moduleDescription.toLowerCase();
            let storageSize = e.storageSize.toString();
            let price = e.price.toString();
            return moduleDescription.indexOf(filterSearch) !== -1 || storageSize.indexOf(filterSearch) !== -1 || price.indexOf(filterSearch) !== -1
        })
        if(e.target.value === ''){
            searchRes = this.state.dataExtraStorage;
        }
        this.setState({listExtraStorage:searchRes});
        setTimeout(()=> {
            this.setState({listStorageContent: this.extraStorageContent()}); 
        }, 100);
    }
    filterExtraStorage = (indexInput, type) =>{
        let inputList = document.querySelectorAll(".filterExtraStorageClass");
        for(let i = 0; i < inputList.length; i++){
            if(i != indexInput){
                inputList[i].checked = false;
            }else{
                inputList[i].checked = true;
            }
        }
        let searchRes;
        if(type === "all"){
            searchRes = this.state.dataExtraStorage;
        }else{
            if(indexInput == 1){
                indexInput = 0;
            }else{
                indexInput = 1;
            }
            searchRes = this.state.dataExtraStorage.filter((e) => {
                let Status = e.status.toLowerCase();
                return Status.indexOf(indexInput.toString()) !== -1 
            })
        }
        this.setState({listExtraStorage:searchRes});
        setTimeout(()=> {
            this.setState({listStorageContent: this.extraStorageContent()}); 
        }, 100);
    }
    
    newRangeStorageModal = () => {
        this.setState({isUpdate:false})
        this.setState({modalContent:""});
        let modalContentDiv;
        
        this.setState({formExtraStorage:{
            id:'',
            moduleCode: '',
            moduleDescription: '',
            storageSize: '',
            price:'',
            status:'',
            stripePricesId: '',
            detail: []
        }})
        setTimeout(()=> {
            modalContentDiv = this.createModalContentExtraStorageHtml(false);
        }, 10); 
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("save"), function: this.saveRangeStorage},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("new_extra_space")}
            content={modalContentDiv} buttons={buttons}/>}) 
        }, 10);
    } 

    standardModal = () => {
        let controllerAction;
        controllerAction = "defaultStorageRange/defaultStorageRangeList";
        let instanceType = document.querySelector("#instanceType").value;
        let independent = document.querySelector("#independent").value;
        this.setState({
            spinner:<DefaultSpinner instanceType={instanceType} 
            independent={independent}/>,
            modalContent:"",
            listStorageToUpdate: [] 
        })

        DefaultAjax('').get(controllerAction)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    spinner:"",
                    listStorage:result,
                    data:result
                });
                setTimeout(()=> {
                    this.setState({modalContentDefaultStorage:this.defaultStorageContent()});
                }, 10); 
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("save"), function: this.saveDefaultRangeStorage},
                    {text: i18n.translate("cancel"), function: ""}]  
                    this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("standard_spaces")}
                    content={this.state.modalContentDefaultStorage} modalClass={'modalContentDefaultStorage'} 
                    buttons={buttons} size={'lg'} />}) 
                }, 20);
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }  

    createModalContentExtraStorageHtml = (isUpdate, detail) => {
        let serviceExtraStoragePackList = detail;
        return <div>
                    <FormGroup>
                        <label>
                            {i18n.translate("default_code")}
                        </label>
                        <input className='form-control inputStandard' id='moduleCode' type="text" name="moduleCode" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <label>
                            {i18n.translate("description")}
                        </label>
                        <input className='form-control inputStandard' id='moduleDescription' type="text" name="moduleDescription" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <label>
                            {i18n.translate("default_storage")}
                        </label>
                        <input className='form-control inputStandard' id='storageSize' type="number" name="storageSize" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <label>
                            {i18n.translate("storage_price")}
                        </label>
                        <input className='form-control inputStandard' id='price' type="number" name="price" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <label>
                            {i18n.translate("stripe_price_id")}
                        </label>
                        <input className='form-control inputStandard' id='stripePricesId' type="text" name="stripePricesId" onChange={this.handleChange}/>
                    </FormGroup>
                    {isUpdate && 
                        <>
                        {serviceExtraStoragePackList.map((list) =>{
                            return(
                                <>  
                                    <div style={{textAlign:'center',cursor:'pointer'}} onClick={()=>this.showServicesHistoric(list.tenantId)}>
                                        {list.serviceName}-{list.tenantId} <FaIcons.FaCaretRight id={'arrowRight-'+list.tenantId} style={{display:''}}/><FaIcons.FaCaretDown id={'arrowDown-'+list.tenantId} style={{display:'none'}}/>
                                    </div>
                                    <div className='serviceHistoricDetail' id={'serviceHistoricDetail-'+list.tenantId} style={{display:'none'}}>
                                        <div style={{display:'inline-block',float: 'left'}}>
                                            <b>{i18n.translate("state")}: </b>
                                            {list.status == '0' &&  <>{i18n.translate("default_active")}</>}
                                            {list.status == '1' &&  <>{i18n.translate("default_inactive")}</>}
                                            {list.status == '3' &&  <>{i18n.translate("default_suspended")}</>}
                                        </div>
                                        <div style={{display:'inline-block',float:'right'}}>
                                            <b>{i18n.translate("default_from")}: </b> 
                                            {list.status == '1' ? 
                                            <>{getDateForShow(list.inactiveDate)}</> 
                                            : 
                                            <>{getDateForShow(list.dateOfAcquisition)}</>}
                                        </div>
                                    </div>
                                </>
                            )
                        })} 
                        </>
                    } 
        </div>
    }
    
    showServicesHistoric = (tenantId) => {
        let idSplit;
        let divDetail = document.querySelectorAll(".serviceHistoricDetail");
        for (const each of divDetail) {
            if(each.id == "serviceHistoricDetail-"+tenantId){
                if(document.querySelector("#serviceHistoricDetail-"+tenantId).style.display == "none"){
                    document.querySelector("#serviceHistoricDetail-"+tenantId).style.display = "flow-root";
                    document.querySelector("#arrowDown-"+tenantId).style.display = "";
                    document.querySelector("#arrowRight-"+tenantId).style.display = "none";
                }else{
                    document.querySelector("#serviceHistoricDetail-"+tenantId).style.display = "none";
                    document.querySelector("#arrowDown-"+tenantId).style.display = "none";
                    document.querySelector("#arrowRight-"+tenantId).style.display = "";
                }  
            }else{
                each.style.display = "none";
                idSplit = each.id.split("serviceHistoricDetail-");
                document.querySelector("#arrowDown-"+idSplit[1]).style.display = "none";
                document.querySelector("#arrowRight-"+idSplit[1]).style.display = "";
            }
        }
    } 

    handleChange = e => {
        if(this.state.selectedInputRado == i18n.translate("storage_basic")){
            setTimeout(()=> {
                this.setState({
                    form:{
                      ...this.state.form,
                      [e.target.name]: e.target.value,
                    }
                });
            }, 100);
        }else{
            setTimeout(()=> {
                this.setState({
                    formExtraStorage:{
                      ...this.state.formExtraStorage,
                      [e.target.name]: e.target.value,
                    }
                });
            }, 100);
        } 
    } 

    saveRangeStorage = () => {
        let controllerAction,newPlan,status;
        newPlan = {...this.state.formExtraStorage};
        status = saveEditExtraStorage(newPlan);
        controllerAction = "extraStoragePack/extraStoragePack";
        if(status == 1){
            toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
        }else{
            DefaultAjax('').post(controllerAction,newPlan)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    
                    if(res.status == 200){
                        toastFunction(i18n.translate("new_storage_range_created"),"success");
                        this.setState({modalContent:""});
                        this.setState({listExtraStorage:[]});
                        setTimeout(()=> {
                            this.getPlansStorage();
                        }, 100);
                    }else{
                        this.prepareModalForShowErrors(result.errorsList);
                    } 
                }
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }
    }
    
    prepareModalForShowErrors = (errorsList) => {
        this.setState({modalContent2:""});
        toastFunction(i18n.translate("an_error_has_ocurred"),"error");
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_accept"), function: ""}]  
            this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("default_errors")}
            content={this.errorsListHtml(errorsList)} buttons={buttons}/>}) 
        }, 10);
    } 

    errorsListHtml = (list) => {
        return <ul>
           {list.map((l) =>{
            return <li key={l}>{l}</li>
           })} 
        </ul>
    } 

    showExtraStorage = (id) => {
        let controllerAction, modalContentDiv;
        controllerAction = "extraStoragePack/extraStoragePack?id=";

        DefaultAjax('').get(controllerAction+id)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    formExtraStorage:{
                        id:result.id,
                        moduleCode: result.moduleCode,
                        moduleDescription: result.moduleDescription,
                        storageSize: result.storageSize,
                        status:result.status,
                        price:result.price,
                        stripePricesId:result.stripePricesId,
                        detail:result.detail
                    } 
                });
                setTimeout(()=> {
                    modalContentDiv = this.createModalContentExtraStorageHtml(true,result.detail);
                }, 10); 
                this.setState({
                    isUpdate:true,
                    modalContent:""
                });
                let disableButtonText = i18n.translate("default_disable");
                let disableButtonFunction =  this.disableRangeStorage;
                if(result.status == 1 || result.status == "1"){
                    disableButtonText = i18n.translate("default_enable");
                    disableButtonFunction =  this.enableRangeStorage;
                } 
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("update"), function: this.updateRangeStorage},
                    {text: disableButtonText, function: disableButtonFunction}, 
                    {text: i18n.translate("cancel"), function: ""}]  
                    this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("update_storage_range")}
                    content={modalContentDiv} buttons={buttons}/>}) 
                }, 100);
                setTimeout(()=> {
                    document.querySelector("#storageSize").value = this.state.formExtraStorage.storageSize;
                    document.querySelector("#moduleCode").value = this.state.formExtraStorage.moduleCode;
                    document.querySelector("#moduleDescription").value = this.state.formExtraStorage.moduleDescription;
                    document.querySelector("#storageSize").value = this.state.formExtraStorage.storageSize;
                    document.querySelector("#price").value = this.state.formExtraStorage.price;
                    document.querySelector("#stripePricesId").value = this.state.formExtraStorage.stripePricesId;
                },200)
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    } 

    updateRangeStorage = () => {
        let controllerAction, newPlan, status;
  
        controllerAction = "extraStoragePack/extraStoragePack";
        newPlan = {...this.state.formExtraStorage};
        status = saveEditExtraStorage(newPlan);
        if(status == 1){
            toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
        }else{
            DefaultAjax('').put(controllerAction,newPlan)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.handleResponseCreateUpdateStorage(result.status,i18n.translate("updated_storage_range"),true);
                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        } 
    } 

    handleResponseCreateUpdateStorage = (status,message,isUpdate) => {
        if(status != -1 || status != "-1"){
            if(!isUpdate){
                this.setState({modalContent2:""});
            } 
            toastFunction(message,"success");
            this.setState({modalContent:""});
            this.setState({listExtraStorage:[]});
            setTimeout(()=> {
                this.getPlansStorage();
            }, 100);
        }else{
            toastFunction(i18n.translate("an_error_has_ocurred"),"error");
        }
    } 

    disableRangeStorage = () => {
        let canDeleted = false;
        if(this.state.formExtraStorage.detail.length > 0){
            toastFunction(i18n.translate("cannot_disable_a_plan_with_active_services"),"warning");
        }else{
            canDeleted = true;
        }
        if(canDeleted){
            this.setState({modalContent2:""});
            setTimeout(()=> {
                let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmDisableRangeStorage},
                {text: i18n.translate("cancel"), function: ""}]  
                this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("disable_storage_range")}
                content={this.createModalConfirmHtml()} buttons={buttons}/>}) 
            }, 10);
        }
    } 

    enableRangeStorage = () => {
        this.setState({modalContent2:""});
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmEnableRangeStorage},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("disable_storage_range")}
            content={i18n.translate("confirm_enable_storage_range")} buttons={buttons}/>}) 
        }, 10);
    } 

    createModalConfirmHtml = () => {
        return <div>
            {i18n.translate("confirm_disable_storage_range")} 
        </div>
    } 

    confirmEnableRangeStorage = () => {
        let id = this.state.formExtraStorage.id;
        DefaultAjax('').put("extraStoragePack/enableExtraStoragePack?id="+id)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseCreateUpdateStorage(result.status,i18n.translate("storage_range_enabled"),false);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    confirmDisableRangeStorage = () => {
        let controllerAction, id;
        controllerAction = "extraStoragePack/extraStoragePack?id=";
        id = this.state.formExtraStorage.id;
        DefaultAjax('').delete(controllerAction+id)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseCreateUpdateStorage(result.status,i18n.translate("storage_range_disabled"),false);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    render(){
        return (
          <>
            {this.state.modalContent} 
            {this.state.modalContent2} 
            <br/>
            <nav className='navbarT'>
                <Row>
                    <Col sm={12} md={4} lg={4} xl={2}>
                        <input className="form-control inputStandard" type='text' onChange={this.filterPlans}/>
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={4} style={{paddingTop:'5px'}}>
                        {this.state.filterContent}
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={6}>
                        <button style={{float: 'right'}} className='buttonMzateSecondary' onClick={()=>this.newRangeStorageModal()}>{i18n.translate("create_extra_space")}</button>
                        {''}
                        <button style={{float: 'right',marginRight:'5px'}} className='buttonMzateSecondary' onClick={()=>this.standardModal()}>{i18n.translate("standard_spaces")}</button>
                    </Col>
                </Row>
                <br/><br/>
            </nav>
            <div className='bodyContent' id='bodyContent'>
                {this.state.spinner}
                {this.state.listStorageContent}
            </div>
          </>
        );
    }
} 
export default AbmStorage;