import axios from 'axios';
import i18n from 'i18n-react';
import { toastFunction } from '../../components/mainFunctions';
import { Col, Row, Table } from 'reactstrap';
import React from "react";
import SelectGIE from '../../components/SelectGIE';
import * as FaIcons from "react-icons/fa";
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const url = process.env.REACT_APP_API_URL;

export const downloadInvoicePdf = (billing) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${url}fePyInvoice/download?feInvoiceReplicaId=${encodeURI(billing.replicaId)}`,
            headers: { 'Authorization': document.querySelector('#token').value },
            responseType: 'blob',
        })
        .then(response => {
            if (response.status === 200) {
                const FileDownload = require('js-file-download');
                toastFunction(i18n.translate('download_started'), 'success');
                FileDownload(response.data, `${billing.invoiceNumber}.pdf`);
                resolve(true); 
            } else {
                toastFunction(i18n.translate('an_error_has_ocurred'), 'error');
                resolve(false); 
            }
        })
        .catch(error => {
            reject(error); 
        });
    });
};

export const billingContentShow = (billing,isFromInvoicePanel,sifenAndStatusFields) => {
    const handleDocumentNumberChange = (e) => {
        billing.customerFiscalNumber = e.target.value;
    };

    const handleCustomerNameChange = (e) => {
        billing.customerName = e.target.value;
    };

    const handleTypeDocumentChange = (e) => {
        if(e.target.value === '1'){
            document.querySelector(".typeTaxPayerRow").classList.remove("displayNone");
        }else{
            document.querySelector(".typeTaxPayerRow").classList.add("displayNone");
        }  
    } 

    return (
        <>
            {isFromInvoicePanel && <div className='electronicDocumentEvent'>
                <Row style={{textAlign:'center',fontWeight:'bolder',marginBottom:'10px'}}>
                    <label style={{color:'#001188'}}>{i18n.translate("electronic_document")}</label>
                </Row>
                <Row>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("sending_status")} 
                        </label> 
                        <br></br>
                        <label>
                            {i18n.translate(sifenAndStatusFields.status)} 
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("response_date")} 
                        </label> 
                        <br></br>
                        <label>
                            {sifenAndStatusFields.sifenResponseDate} 
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("default_result")} 
                        </label> 
                        <br></br>
                        <label>
                            {sifenAndStatusFields.sifenResultStatus} 
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("result_description")} 
                        </label> 
                        <br></br>
                        <label>
                            {sifenAndStatusFields.sifenResult} 
                        </label>
                    </Col>
                </Row>
                {sifenAndStatusFields.eventType && <>
                    <Row style={{textAlign:'center',fontWeight:'bolder',marginBottom:'10px',marginTop:'10px'}}>
                        <label style={{color:'#001188'}}>{i18n.translate("events")}</label>
                    </Row>
                    <Row>
                        <Col>
                            <label style={{fontWeight:'bolder'}}>
                                {i18n.translate("event_type")} 
                            </label> 
                            <br></br>
                            <label>
                                {sifenAndStatusFields.eventType} 
                            </label>
                        </Col>
                        <Col>
                            <label style={{fontWeight:'bolder'}}>
                                {i18n.translate("event_date")} 
                            </label>
                            <br></br>
                            <label>
                                {sifenAndStatusFields.eventDate}
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label style={{fontWeight:'bolder'}}>
                                {i18n.translate("state")} 
                            </label> 
                            <br></br>
                            <label>
                                {sifenAndStatusFields.eventStatus}
                            </label>
                        </Col>
                        <Col>
                            <label style={{fontWeight:'bolder'}}>
                                {i18n.translate("event_reason")} 
                            </label> 
                            <br></br>
                            <label>
                                {sifenAndStatusFields.eventReason}
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label style={{fontWeight:'bolder'}}>
                                {i18n.translate("default_result")} 
                            </label> 
                            <br></br>
                            <label>
                                {sifenAndStatusFields.eventResults}
                            </label>
                        </Col>
                    </Row>
                </>} 
            </div>} 
            {isFromInvoicePanel && 
            <Row style={{textAlign:'center',fontWeight:'bolder',marginBottom:'10px'}}>
                <label style={{color:'#001188'}}>{i18n.translate("default_bill")}</label>
            </Row>} 
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("date_issue")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.invoicingDate}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_number")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.invoiceNumber}  
                    </label>

                </Col>
            </Row>
            <Row className='showWhenEditInvoice displayNone'>
                <Col>
                    <label>
                        {i18n.translate("type_of_document")} 
                    </label>
                    <br></br>
                    <select className="form-select selectStandard" id="typeOfDocument" onChange={handleTypeDocumentChange}>
                        <option value=''>{i18n.translate("nameless")}</option>
                        <option value='1'>{i18n.translate("document_type_1")}</option>
                        <option value="0">{i18n.translate("document_type_0")}</option>
                        <option value="2">{i18n.translate("document_type_2")}</option>
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("document_number")} 
                    </label>
                    <br></br>
                    <label className='hideWhenEditInvoice'>
                        {billing.customerFiscalNumber}  
                    </label>
                    <input
                        type='text'
                        onChange={handleDocumentNumberChange}
                        id='documentNumber'
                        className="form-control inputStandard showWhenEditInvoice displayNone"
                    />
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_customer_name")} 
                    </label>
                    <br></br>
                    <label className='hideWhenEditInvoice'>
                        {billing.customerName}  
                    </label>
                    <input
                        type='text'
                        onChange={handleCustomerNameChange}
                        id='customerName'
                        className="form-control inputStandard showWhenEditInvoice displayNone"
                    />
                </Col>
            </Row>
            <Row className='typeTaxPayerRow displayNone'>
                <Col className='displayNone'>
                    <label>
                        {i18n.translate("default_taxpayer")} 
                    </label>
                    <br></br>
                    <select className="form-select selectStandard" id="taxPayer">
                        <option value="0">{i18n.translate("false")}</option>
                        <option value="1">{i18n.translate("true")}</option>
                    </select>
                </Col>
                <Col>
                    <label>
                        {i18n.translate("type_of_taxpayer")} 
                    </label>
                    <br></br>
                    <select className="form-select selectStandard" id="typeTaxPayer">
                        <option value="1">{i18n.translate("physical_person")}</option>
                        <option value="2">{i18n.translate("legal_person")}</option>
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_address")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.customerAddress}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_sale_condition")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.type}  
                    </label>
                </Col>
                {billing.type === i18n.translate("default_credit") && 
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            ({billing.term} {i18n.translate("default_days")}) 
                        </label>
                    </Col>
                } 
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_currency")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.currency}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_user")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.responsible}  
                    </label>
                </Col>
            </Row>
            {!isFromInvoicePanel && billing.isCancelled === true && <>
                <Row style={{paddingTop:'10px',textAlign:'center'}}>
                    <Col>
                        <label style={{color:'#018',fontWeight:'bold'}}>
                            {i18n.translate("invoice_cancelled_title")}
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{fontWeight:'bold'}}>
                            {i18n.translate("cancellation_date")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.cancelledDate}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bold'}}>
                            {i18n.translate("responsible_user")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.cancelledResponsibleUsername} - {billing.cancelledResponsibleFullname}
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("reason_for_cancellation")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.cancelledReason}  
                        </label>
                    </Col>
                </Row>
            </>}
            <Table className='table table-hover'>
                <tbody>
                    <tr>
                        <th className='firstcell'>{i18n.translate("default_code")}</th>
                        <th className='cellgroup'>{i18n.translate("description")}</th>
                        <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                        <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_exempt")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_vat_5")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_vat_10")}</th>
                        <th className='cellgroup'>{i18n.translate("default_subtotal")}</th>
                    </tr>
                    {billing.detail.map((det) =>{
                        return(
                            <tr key={det.vproductCode}>
                                <td>
                                    {det.vproductCode} 
                                </td>
                                <td>
                                    {det.vproductName} 
                                </td>
                                <td>
                                    {det.price} 
                                </td>
                                <td>
                                    {det.quantity} 
                                </td>
                                <td>
                                    {det.exemptAmount} 
                                </td>
                                <td>
                                    {det.productVatRate === 5 ? det.vatAmount : 0} 
                                </td>
                                <td>
                                    {det.productVatRate === 10 ? det.vatAmount : 0} 
                                </td>
                                <td>
                                    {det.subTotal} 
                                </td>
                            </tr>
                        )
                    })} 
                </tbody>
            </Table>
            <Row style={{marginBottom:'10px'}}>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_total_exempt")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.exemptTotal}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("total_5")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.vat05Total}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("total_10")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.vat10Total}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("net_total")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.netTotal}  
                        </label>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export const creditNoteContentShow = (creditNote) => {
    return(
        <>
            <Row style={{textAlign:'center'}}>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("credit_number")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.creditNoteNumber}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("date_issue")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.dateIssue}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("expiration_date")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.expirationDate}  
                    </label>
                </Col>
            </Row>
            <Row style={{textAlign:'center'}}>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_fiscal_stamp_number")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.invoiceFiscalStampNumber}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_customer_name")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.customerName}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_sale_condition")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.invoiceSaleCondition}  
                    </label>
                </Col>
            </Row>
            <Row style={{textAlign:'center'}}>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("start_of_validity")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.startOfValidity}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_customer_ruc")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.customerFiscalNumber}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_currency")} 
                    </label>
                    <br></br>
                    <label>
                        {creditNote.currency}  
                    </label>
                </Col>
            </Row>
            <Row style={{textAlign:'start'}}>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("reason_for_return")}:  
                    </label>
                    <label style={{marginLeft:'5px'}}>
                        {creditNote.reasonForReturn}  
                    </label>
                </Col>
            </Row>
            <Table className='table table-hover'>
                <tbody>
                    <tr>
                        <th className='firstcell'>{i18n.translate("default_code")}</th>
                        <th className='cellgroup'>{i18n.translate("description")}</th>
                        <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                        <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                        <th className='cellgroup'>{i18n.translate("default_iva")}</th>
                        <th className='cellgroup'>{i18n.translate("vat_description")}</th>
                        <th className='cellgroup'>{i18n.translate("vat_taxable_proportion")}</th>
                    </tr>
                    {creditNote.detail.map((det) =>{
                        return(
                            <tr key={det.code}>
                                <td>
                                    {det.code} 
                                </td>
                                <td>
                                    {det.description} 
                                </td>
                                <td>
                                    {det.price} 
                                </td>
                                <td>
                                    {det.amount} 
                                </td>
                                <td>
                                    {det.vat} 
                                </td>
                                <td>
                                    {det.vatDescription} 
                                </td>
                                <td>
                                    {det.vatTaxableProportion} 
                                </td>
                            </tr>
                        )
                    })} 
                </tbody>
            </Table>
        </>
    )
} 

export const renderBillingRow = (billing, showElectronicBilling, downloadBillingPdf) => (
    <tr key={billing.id} className='cellOptions' onClick={() => showElectronicBilling(billing)}>
        <td>{billing.invoicingDate}</td>
        <td>{billing.invoiceNumber}</td>
        <td>{billing.customerName}</td>
        <td>{billing.customerFiscalNumber}</td>
        <td>{billing.isCancelled === true? i18n.translate("true") : i18n.translate("false") }</td>
        <td>{billing.exemptTotal}</td>
        <td>{billing.vat05Total}</td>
        <td>{billing.vat10Total}</td>
        <td>{billing.netTotal}</td>
        <td>{billing.responsible}</td>
        <td style={{textAlign:'center',lineHeight:'35px'}}>
            <ReactTooltip id={"download_invoice"+billing.id} place="top" effect="solid"/>
            <FaIcons.FaDownload className='iconDownloadStandard' 
            data-tooltip-id={"download_invoice"+billing.id} 
            data-tooltip-content={i18n.translate("default_download")} 
            onClick={(e) => downloadBillingPdf(e, billing)}/>
        </td>
    </tr>
);

export const renderElectronicDocumentRow = (eDocument,showElectronicDocument) => (
    <tr key={eDocument.id} className='cellOptions' onClick={() => showElectronicDocument(eDocument)}>
        <td>{eDocument.invoicingDate}</td>
        <td>{eDocument.invoiceNumber}</td>
        <td>{eDocument.customerName}</td>
        <td>{eDocument.customerFiscalNumber}</td>
        <td>{eDocument.isCancelled === true? i18n.translate("true") : i18n.translate("false") }</td>
        <td>{eDocument.netTotal}</td>
        <td>{i18n.translate(eDocument.status)}</td>
        <td style={{color:eDocument.sifenResultStatus === i18n.translate("default_refused") ? '#b81414' : ''}} >{eDocument.sifenResultStatus}</td>
        <td>{eDocument.sifenResponseDate}</td>
    </tr>
) 

export const getDataForSearchOrShowAll = (isSearch,isShowAll,dateStart,dateEnd,max,pageNumber,setState) => {
    let responsibleUsername = "";
    let customer = ""; 
    let documentNumber = "";
    let invoiceNumber = "";
    let startDateToSearch = dateStart;
    let endDateToSearch = dateEnd;
    let resultStatus = "";
    let status = "";
    let invoiceCancelled = "0";

    if(isSearch){
        if(document.querySelector("#userSelectedDescription").value !== i18n.translate("default_all")){
            responsibleUsername = document.querySelector("#usernameSelected").value;
        } 

        if(document.querySelector("#selectInvoiceCancelled")){
            if(document.querySelector("#selectInvoiceCancelled").value !== '0'){
                invoiceCancelled = document.querySelector("#selectInvoiceCancelled").value
            }
        }
           
        customer = document.querySelector("#customerToSearch").value;
        documentNumber = document.querySelector("#documentNumberToSearch").value;
        invoiceNumber = document.querySelector("#invoiceNumberToSearch").value;
        if(document.querySelector("#statusForFilter")){
            status = document.querySelector("#statusForFilter").value
        }
        if(document.querySelector("#resultForFilter")){
            resultStatus = document.querySelector("#resultForFilter").value
        }  
    }else if(isShowAll){
        document.querySelector("#customerToSearch").value = '';
        document.querySelector("#documentNumberToSearch").value = '';
        document.querySelector("#invoiceNumberToSearch").value = '';
        document.querySelector(".DateRangePicker").value = ''
        startDateToSearch = "";
        endDateToSearch = "";
        document.querySelector("#dateInInputStart").value = startDateToSearch;
        document.querySelector("#dateInInputEnd").value = endDateToSearch;
        setState({
            dateRange: [startDateToSearch, endDateToSearch]
          })
        if(document.querySelector("#statusForFilter")){
            document.querySelector("#statusForFilter").value = '';
        }
        if(document.querySelector("#resultForFilter")){
            document.querySelector("#resultForFilter").value = 0;
        }
        if(document.querySelector("#selectInvoiceCancelled")){
            document.querySelector("#selectInvoiceCancelled").value = '0';
        } 
        setState({selectOrgType:""});
        setTimeout(()=> {
            setState({selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2 displayNone"}/>
            })
        }, 100);
    } 

    const params = new URLSearchParams({
        startDate: startDateToSearch,
        endDate: endDateToSearch,
        responsibleUsername:responsibleUsername,
        customer: customer,
        documentNumber: documentNumber,
        invoiceNumber: invoiceNumber,
        invoiceCancelled: invoiceCancelled,
        resultStatus:resultStatus,
        status: status,
        max : max,
        pageNumber : pageNumber
    });
    return params;
} 
