import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import i18n from "i18n-react";
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL

class DynamicSelectedValuesTemplate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: "",
            form:props.array,
            detail: props.det
        } 
        let selected;
        if(this.state.detail){
            selected = this.state.detail.value;
        }else{
            selected = this.state.form.detail[0].value;
            this.state.detail = this.state.form.detail[0];
        }
        
    }

    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.form.key == "FE_SEND_INVOICE_EMAIL" &&
                        i18n.translate("FE_SEND_INVOICE_EMAIL_"+this.state.detail.value)} 
                    </div>
                </div>
                {this.state.form.update != false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update == false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                    <select className="form-select selectStandard newValue" onChange={this.handleChange} name="tenantId">
                        <option value="0" selected={this.state.detail.value === "0"}>
                            {i18n.translate("FE_SEND_INVOICE_EMAIL_0")}
                        </option>
                        <option value="1" selected={this.state.detail.value === "1"}>
                            {i18n.translate("FE_SEND_INVOICE_EMAIL_1")}
                        </option>
                        <option value="2" selected={this.state.detail.value === "2"}>
                            {i18n.translate("FE_SEND_INVOICE_EMAIL_2")}
                        </option>
                    </select>
                    </div>
                </div>
            </div>
        )
    } 
} 

export default DynamicSelectedValuesTemplate;