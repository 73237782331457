import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarVideos from "../components/SidebarVideos";
import VideosIndex from "./videosContent/VideosIndex";
import PropTypes from 'prop-types';
import { windowHistoryReplaceState } from "../components/mainFunctions";

const Videos = ({token,independent,instanceType}) =>{
    let content = <VideosIndex token={token} independent={independent} instanceType={instanceType}/>;

    windowHistoryReplaceState(independent,instanceType);

    let flag = 0;
	const showSidebarCollapse = () => {
        if(document.querySelector(".sidebar2Collapse")){
            document.querySelector(".mzateLogo").classList.remove("displayNone");
			document.querySelector(".mzateCollapseLogo").classList.add("displayNone");
			let span = document.querySelectorAll(".sidebar2 a div span");
			let arraySpan = Array.from(span);
			let arraySpanItem = arraySpan.forEach((s) => s.classList.remove("displayNone"));
			document.querySelector(".sidebar2").classList.remove("sidebar2Collapse");
			document.querySelector(".align-items-center").classList.remove("containerNoCollapse");
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            document.querySelector(".mzateLogo").classList.add("displayNone");
            document.querySelector(".mzateCollapseLogo").classList.remove("displayNone");
            let span = document.querySelectorAll(".sidebar2 a div span");
            let arraySpan = Array.from(span);
            let arraySpanItem = arraySpan.forEach((s) => s.classList.add("displayNone"))
            document.querySelector(".sidebar2").classList.add("sidebar2Collapse");
            document.querySelector(".align-items-center").classList.add("containerNoCollapse");
            document.querySelector("#nav-top").classList.add("nav-topCollase");
            flag = 0;
        } 
    }
    return (
        <div className="tab-pane fade" id="nav-controlTasks" role="tabpanel" aria-labelledby="nav-controlTasks-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarVideos/>
            </div>
            <div className="content" id="contentAdministration">
                {content}
            </div>
        </div>
    );
};

Videos.propTypes = {
    token: PropTypes.string.isRequired,
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired
}; 

export default Videos;