import React from "react";
import * as FaIcons from "react-icons/fa";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import i18n from "i18n-react";

class FooterSidebar extends React.Component{
	constructor(props) {
		super(props);
		this.state = { 
			FooterSidebar: false,
			url: props.url,
		};
	}
	 componentDidMount() {
		if(document.querySelector(".fixed-topCollapse") != null){
			this.state.FooterSidebar = false;
			this.showSidebar();
		}
		setTimeout(()=> {
			let li = document.querySelectorAll("a");

			for (let item of li) {
				if (item.innerText == i18n.translate("tasks_title") && this.state.url == "tasks") {
					item.classList.add("active");
				} else if (item.innerText == i18n.translate("myAccount_title") && this.state.url == "MyAccount") {
					item.classList.add("active");
				} else if (item.innerText == i18n.translate("administration_title") && this.state.url == "AdministrationMzate") {
					item.classList.add("active");
				} else if (item.innerText == i18n.translate("default_billing") && this.state.url == "electronicBilling") {
					item.classList.add("active");
				} else if (item.innerText == i18n.translate("administration_title") && this.state.url == "Administration") {
					const notificationElement = document.querySelector("#administrationNotification");
					if (notificationElement) {
						const mouseOverEvent = new MouseEvent('mouseover', {
							bubbles: true,
							cancelable: true,
							view: window
						});
						notificationElement.dispatchEvent(mouseOverEvent);
						setTimeout(() => {
							const secondElement = document.querySelector("#administrationEvents"); // Cambia por el selector adecuado
							if (secondElement) {
								const clickEvent = new MouseEvent('click', {
									bubbles: true,
									cancelable: true,
									view: window
								});
								secondElement.dispatchEvent(clickEvent);
							}
						}, 10);
					};
					break;
				}
			} 
		}, 500);
		if(this.state.url == "tasks"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "MyAccount"){
			document.querySelectorAll(".sidebar2 a")[3].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[3].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[3].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "AdministrationMzate"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "tutorialVideos"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "electronicBilling"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "Administration"){
			document.querySelectorAll(".sidebar2 a")[8].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[8].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[8].children[0].children[0].style.color = "#8DBE22";
		}    
	 }
	componentDidUpdate() {
		if(this.state.FooterSidebar == true){
			this.state.FooterSidebar = false;
			this.showSidebar();
		}
	}
	showSidebar = () => {
		if(this.state.FooterSidebar){
			document.querySelector(".mzateLogo").classList.remove("displayNone");
			document.querySelector(".mzateCollapseLogo").classList.add("displayNone");
			let span = document.querySelectorAll(".sidebar2 a div span");
			let arraySpan = Array.from(span);
			let arraySpanItem = arraySpan.map((s) =>s.classList.remove("displayNone"));
			document.querySelector(".sidebar2").classList.remove("sidebar2Collapse");
			document.querySelector(".align-items-center").classList.remove("containerNoCollapse");
			document.querySelector(".collapseIcon").classList.remove("displayNone");
			document.querySelector(".noCollapseIcon").classList.add("displayNone");
			document.querySelector(".fixed-top").classList.remove("fixed-topCollapse");
			document.querySelector(".tab-pane").classList.remove("tab-paneCollapse");
			this.state.FooterSidebar = false;
		}else{
			document.querySelector(".mzateLogo").classList.add("displayNone");
			document.querySelector(".mzateCollapseLogo").classList.remove("displayNone");
			let span = document.querySelectorAll(".sidebar2 a div span");
			let arraySpan = Array.from(span);
			let arraySpanItem = arraySpan.map((s) => s.classList.add("displayNone"))
			document.querySelector(".sidebar2").classList.add("sidebar2Collapse");
			document.querySelector(".align-items-center").classList.add("containerNoCollapse");
			document.querySelector(".collapseIcon").classList.add("displayNone");
			document.querySelector(".noCollapseIcon").classList.remove("displayNone");
			document.querySelector("#nav-top").classList.add("nav-topCollase");
			document.querySelector(".fixed-top").classList.add("fixed-topCollapse");
			document.querySelector(".tab-pane").classList.add("tab-paneCollapse");
			this.state.FooterSidebar = true;
		} 
	}
	
	stopPropagation =e=>{
		e.stopPropagation();
	}
	render(){
		return (
			<div className="d-flex w-100 justify-content-start align-items-center containerCollapse" 
			data-tip data-for="collapse" onClick={()=>this.showSidebar()} onMouseOver={this.stopPropagation}>
				<FaIcons.FaAngleDoubleLeft  className="collapseIcon"/>
				<FaIcons.FaAngleDoubleRight className="noCollapseIcon displayNone"/>
			</div>
		);
	} 
    
}
    
 export default FooterSidebar;