import React from "react";
import i18n from "i18n-react";
import {  Row, Col } from 'reactstrap';
import { toastFunction, handleKeyDown, handlePaste } from "../../components/mainFunctions";
import AddContent from "../../img/add_content.png";
import RemoveContent from "../../img/remove_content.png";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import Select from 'react-select';
import DefaultSpinner from "../../components/DefaultSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloneDeep from 'lodash/cloneDeep';
import * as FaIcons from "react-icons/fa";
import DefaultModalError from "../../components/DefaultModalError";
import DefaultModal from "../../components/DefaultModal";
import Switch from "react-switch";

class InvoiceData extends React.Component {
    constructor(props){
        super(props);
        this.state={
            issuerData: {},
            digitalSignature: {},
            stampData: {},
            currentPhaseView:1,
            currentBillingPhase:1,
            currentBillingPhaseToCompare: 1,
            userCategory: [],
            usersForStamp: [], 
            usersForStampToCompare:[], 
            users:[], 
            keyStoreBase64:"",
            keyStoreBase64ToCompare:"",
            enterpriseLogo: "",
            showPassword: false,
            showPassword2: false,
            modalContent: "",
            modalContent2: "", 
            isEdit: false,
            dateStamp: new Date(),
            resultInitialValue:"",
            isProduction:false,
            isProductionToCompare:false
        } 
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getUserBillingPhase();
        this.getUsersForStamp();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let altura = window.innerHeight - nav;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
    } 

    getUsersForStamp = () => {
        let users = [];
        DefaultAjax('').get("/orgNode/allOrgNodeList?moduleCode=FE")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                for(let i = 0; i < result.eonList.length; i++){
                    users.push({"value":result.eonList[i].username,"label":result.eonList[i].username})
                } 
                this.setState({users:users})
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    getUserBillingPhase = (isCancel,divToChange,currentPhaseView,isDiscardChange,phaseToChange,contentIdPhase) => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').get("/fePy/getPhases")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result){
                    this.setState({resultInitialValue: cloneDeep(result),isEdit:false})
                    this.setUserPhasesValues(result,isCancel,divToChange,currentPhaseView,isDiscardChange,phaseToChange,contentIdPhase);
                } 
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    setIssuerDataValues = (result) => {
        if(Object.keys(result.fePyServiceIssuerData).length > 0){
            document.querySelector("#customerFiscalNumber").value = result.fePyServiceIssuerData.ruc;
            document.querySelector("#rucVerificationDigit").value = result.fePyServiceIssuerData.checkDigit;
            document.querySelector("#customerName").value = result.fePyServiceIssuerData.businessName;
            document.querySelector("#customerAlias").value = result.fePyServiceIssuerData.businessAlias;
            document.querySelector("#address").value = result.fePyServiceIssuerData.address;
            document.querySelector("#houseNumber").value = result.fePyServiceIssuerData.houseNumber;
            document.querySelector("#phoneNumber").value = result.fePyServiceIssuerData.phone;
            document.querySelector("#email").value = result.fePyServiceIssuerData.email
            document.querySelector("#typeOfTaxpayer").value = result.fePyServiceIssuerData.taxPayerType;
            document.querySelector("#address1").value = result.fePyServiceIssuerData.complementAddress1;
            document.querySelector("#address2").value = result.fePyServiceIssuerData.complementAddress2;
            document.querySelector("#departmentCode").value = result.fePyServiceIssuerData.departmentCode;
            document.querySelector("#departmentDescription").value = result.fePyServiceIssuerData.departmentDescription;
            document.querySelector("#cityCode").value = result.fePyServiceIssuerData.cityCode;
            document.querySelector("#cityDescription").value = result.fePyServiceIssuerData.cityDescription;
        }
    } 

    setDigitalSignatureValues = (result) => {
        if(Object.keys(result.fePyDigitalSignature).length > 0){
            document.querySelector("#pass").value = result.fePyDigitalSignature.pass;
            document.querySelector("#alias").value = result.fePyDigitalSignature.alias;
            document.querySelector("#passAlias").value = result.fePyDigitalSignature.passAlias;

            setTimeout(()=> { 
                if (result?.fePyDigitalSignature?.keystore) {
                    let keystoreBytes = new Uint8Array(result.fePyDigitalSignature.keystore);
                
                    let base64String = btoa(String.fromCharCode.apply(null, keystoreBytes));
                
                    this.setState({
                        keyStoreBase64: base64String,
                        keyStoreBase64ToCompare: base64String
                    });
                }

            }, 100);
        }
    } 

    setFiscalStampValues = (result) => {
        if(Object.keys(result.fePyServiceFiscalStampData).length > 0){
            if(result.fePyServiceFiscalStampData.idCsc){
                document.querySelector("#idCSC").value = result.fePyServiceFiscalStampData.idCsc;
            } 
            if(result.fePyServiceFiscalStampData.csc){
                document.querySelector("#csc").value = result.fePyServiceFiscalStampData.csc;
            }
            if(result.fePyServiceFiscalStampData.fiscalStampNumber){
                document.querySelector("#fiscalStampNumber").value = result.fePyServiceFiscalStampData.fiscalStampNumber;
            }
            if(result.fePyServiceFiscalStampData.stampedStartDate){
                document.querySelector("#stampedStartDate").value = result.fePyServiceFiscalStampData.stampedStartDate;
            }
            if(result.fePyServiceFiscalStampData.replicaId){
                this.setState({fePyServiceFiscalStampDataReplicaId:result.fePyServiceFiscalStampData.replicaId});
            } 
            if(result.fePyServiceFiscalStampData.fiscalStampStartValidityDate){
                let dateString = result.fePyServiceFiscalStampData.fiscalStampStartValidityDate;
                let parts = dateString.split('-'); // Separar por guiones
                let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`; // Formato MM/DD/YYYY

                this.setState({ dateStamp: new Date(formattedDate) });
            }
            this.setState({isProduction:result.fePyServiceFiscalStampData.isProduction,isProductionToCompare:result.fePyServiceFiscalStampData.isProduction})
            document.querySelector("#switchToProduction").style.display = 'none';
        }else{
            document.querySelector("#switchToProduction").style.display = 'contents';
        } 
        
    } 

    setUserPhasesValues = (result,isCancel,divToChange,phaseViewToChange,isDiscardChange,phaseToChange,contentIdPhase) => {
        let currentPhaseView;
        if(phaseViewToChange){
            currentPhaseView = phaseViewToChange;
        }else{
            currentPhaseView = result.pendingFePhase;
        }  
        if(result.pendingFePhase == 0 || result.pendingFePhase == "0"){
            currentPhaseView = 3;
        }
        if(result.fePyEconomicActivitiesList.length > 0){
            setTimeout(()=> { 
                let category = result.fePyEconomicActivitiesList;
                category.forEach((c) => {
                    c.operationType = "N"
                })
                this.setState({userCategory:category})
            }, 100);
        }else{
            setTimeout(()=> { 
                let category = [{code:"",description:"",operationType:"A"}];
                this.setState({userCategory:category})
            }, 100);
        }

        if(result.pendingFePhase == 2 || result.pendingFePhase == "2"){
            setTimeout(()=> { 
                this.displayInputsIssuerData(true);
            }, 120);
        }else if(result.pendingFePhase == 3 || result.pendingFePhase == "3"){
            setTimeout(()=> { 
                this.displayInputsIssuerData(true);
                this.displayInputsDigitalSignature(true);
            }, 120);
        }else if(result.pendingFePhase == 0 || result.pendingFePhase == "0"){
            setTimeout(()=> { 
                this.displayInputsStamp(true);
                this.displayInputsIssuerData(true);
                this.displayInputsDigitalSignature(true);
            }, 120);
        }

        let usersForStampToCompare = [];
        let userStampList = [];
        if(result.fePyUserFiscalStampDataList.length > 0){
            usersForStampToCompare = cloneDeep(result.fePyUserFiscalStampDataList);
            userStampList = result.fePyUserFiscalStampDataList;
            userStampList.forEach((stamp) => {
                stamp.operationType = 'N';
                stamp.lastUsedNumber = parseInt(stamp.lastUsedNumber+1) 
            })
            usersForStampToCompare.forEach((stampCompare) => {
                stampCompare.operationType = 'N';
                stampCompare.lastUsedNumber = parseInt(stampCompare.lastUsedNumber+1)
            })
        } 

        setTimeout(()=> { 
            this.setState({
                currentPhaseView: currentPhaseView,
                issuerData: result.fePyServiceIssuerData,
                currentBillingPhase: parseInt(result.pendingFePhase),
                currentBillingPhaseToCompare: result.pendingFePhase,
                digitalSignature: result.fePyDigitalSignature,
                stampData: result.fePyServiceFiscalStampData,
                spinner: "",
                usersForStamp: userStampList,
                usersForStampToCompare: usersForStampToCompare
            })
            if(isDiscardChange){
                setTimeout(()=> {
                    this.navigateBetweenFeTabs(phaseToChange,contentIdPhase);
                    setTimeout(()=> {
                        this.editPhase()
                    }, 10);
                }, 50);
            }else if(isCancel){
                setTimeout(()=> {
                    this.changeBillingPhase(phaseViewToChange,divToChange);
                }, 10);
            }else if(currentPhaseView === 3){
                setTimeout(()=> {
                    this.navigateBetweenFeTabs(1,'contentIssuerData');
                }, 10);
            }
        }, 100);

        this.setIssuerDataValues(result);
        
        this.setDigitalSignatureValues(result); 

        this.setFiscalStampValues(result) 
    }

    showHideAnswer = (id) => {
        const answer = document.querySelectorAll('.billingFaqAnswer');
        answer.forEach(a => {
            if(!a.classList.contains("displayNone")){
                if(a.id != "billingFaqAnswer-"+id){
                    a.classList.add('displayNone');
                } 
            } 
        });
        document.querySelector("#billingFaqAnswer-"+id).classList.toggle('displayNone');
    } 

    addUserForStamp = () => {
        let userForStamp = this.state.usersForStamp;
        userForStamp.push({seriesNumber:"",branch:"",printer:"",initialNumber:"1",lastUsedNumber:"",responsibleUsername:"",userDescription:"",fiscalStampStartValidityDate:"",operationType:"A",id:"",replicaId:"",fePyServiceFiscalStampDataReplicaId:this.state.fePyServiceFiscalStampDataReplicaId});
        this.setState({userForStamp:userForStamp});
        setTimeout(() => {
            const contentStamp = document.querySelector("#contentStamp");
            if (contentStamp) {
                contentStamp.scrollIntoView({ behavior: 'smooth', block: 'end' }); 
            }
            const lengthToLastUser = document.querySelectorAll(".userForStamp").length;
            document.querySelectorAll(".userForStamp")[lengthToLastUser-1].classList.add("highlight");
            setTimeout(() => {
                document.querySelectorAll(".userForStamp")[lengthToLastUser-1].classList.remove("highlight");
            }, 1000);
        }, 10);
    }
    
    removeUserForStamp = (index) => {
        let userForStamp = this.state.usersForStamp;
        if(userForStamp[index].id != ""){
            userForStamp[index].operationType = "D";
            document.querySelector("#userForStampDiv-"+userForStamp[index].id).style.display = 'none';
        }else{
            userForStamp.splice(index,1);
            this.setState({userForStamp:userForStamp});
        }  
    } 

    handleChangeUser = (e,index) => {
        let usersForStamp = this.state.usersForStamp;
        let newUsername = e.value;
        const usernameExists = usersForStamp.some((user, idx) => user.responsibleUsername === newUsername 
        && idx !== index && 
        user.operationType !== 'D');

        if (usernameExists) {
            toastFunction(i18n.translate("no_more_than_one_shipping_point"),"warning");
        } else {
            usersForStamp[index].responsibleUsername = newUsername;
            this.setOperationType(usersForStamp, index);
        }
    };

    handleChangeUserForStamp = (e,index,fieldToChange) => {
        let usersForStamp = this.state.usersForStamp;
        if(fieldToChange == 'establishment'){
            usersForStamp[index].branch = e.target.value; 
        }else if(fieldToChange == 'invoicePrinter'){
            usersForStamp[index].printer = e.target.value;
        }else if(fieldToChange == 'initialNumber'){
            usersForStamp[index].initialNumber = e.target.value;
        }else if(fieldToChange == 'numberingSeries'){
            usersForStamp[index].seriesNumber = e.target.value;
        }else if(fieldToChange == 'lastUsedNumber'){
            usersForStamp[index].lastUsedNumber = e.target.value;  
        } 
        this.setOperationType(usersForStamp,index);   
    } 

    setOperationType = (usersForStamp,index) => {
        if(usersForStamp[index].id != ""){
            this.state.usersForStampToCompare.forEach((uToCompare) => {
                if(uToCompare.id == usersForStamp[index].id){
                    if(uToCompare.seriesNumber != usersForStamp[index].seriesNumber || 
                        uToCompare.branch != usersForStamp[index].branch ||  
                        uToCompare.printer != usersForStamp[index].printer || 
                        uToCompare.initialNumber != usersForStamp[index].initialNumber || 
                        uToCompare.responsibleUsername != usersForStamp[index].responsibleUsername  
                    ){
                        usersForStamp[index].operationType = "M";
                    }else{
                        usersForStamp[index].operationType = "N";
                    }  
                } 
            })
        }  
        this.setState({usersForStamp:usersForStamp});
    } 

    setShowPassword = (prev) => {
        this.setState({showPassword:!prev})
    } 

    setShowPassword2 = (prev) => {
        this.setState({showPassword2:!prev})
    } 

    addCategory = () => {
        let category = this.state.userCategory;
        category.push({code:"",description:"",operationType:"A"});
        this.setState({userCategory:category})
    } 

    removeCategory = (index) => {
        let category = this.state.userCategory;
        if(category[index].id){
            category[index].operationType = 'D';
            document.querySelector("#userCategoryRow-"+category[index].id).style.display = 'none';
        }else{
            category.splice(index,1);
        } 
        this.setState({userCategory:category})
    } 

    handleChangeFileSignature = event => {
        const file = event.target.files[0];
        document.querySelector("#spanSelectedFileName").textContent = file.name;
        const reader = new FileReader();

        reader.onloadend = () => {
            // El resultado de reader.result contiene el contenido del archivo en Base64
            const base64String = reader.result.split(',')[1]; // Elimina 'data:application/octet-stream;base64,'
            // Actualiza el estado con la cadena Base64
            this.setState({ keyStoreBase64: base64String });
        };

        // Lee el contenido del archivo como una URL de datos (data URL)
        reader.readAsDataURL(file);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    } 

    handleButtonClick = () => {
        // Simular clic en el input file cuando se hace clic en el botón
        this.fileInputRef.current.click();
    };

    handleChangeUserCategory = (e,codeCategory,index) => {
        let category = this.state.userCategory;
        if(codeCategory === 'code'){
            category[index].code = e.target.value; 
        }else if(codeCategory === 'category'){
            category[index].description = e.target.value;
        } 
        if(category[index].operationType == 'N'){
            category[index].operationType = 'M'
        } 
        this.setState({userCategory:category})
    } 
    
    changeBillingPhase = (phaseToChange,contentIdPhase) => {
        if(this.state.isEdit){
            this.checkIfExistChange(phaseToChange,contentIdPhase);
        }else if((this.state.currentBillingPhase == 0 || this.state.currentBillingPhase == 3) || 
        (phaseToChange < this.state.currentBillingPhase || phaseToChange == this.state.currentBillingPhase)){
            this.navigateBetweenFeTabs(phaseToChange,contentIdPhase);
        }else{
            this.saveElectronicBillingPhase();
        }  
    }

    checkIfExistChange = (phaseToChange,contentIdPhase) => {
        let areEqual = true;
        if(this.state.currentBillingPhase === 3){
            areEqual = this.compareStampData();
        }else if(this.state.currentBillingPhase === 2){
            areEqual = this.compareDigitalSignature()
        }else if(this.state.currentBillingPhase === 1){
            areEqual = this.compareIssuerDate();
        }  

        if(areEqual){
            this.navigateBetweenFeTabs(phaseToChange,contentIdPhase);
            const elements = document.querySelectorAll(".mzatePhasesContainerOptions .col");

            elements.forEach(element => {
                element.style.pointerEvents = '';
                element.style.cursor = 'pointer';
            });
            setTimeout(() => {
                this.editPhase();
            }, 10);
        }else{
            this.modalToDescartOConfirmChanges(phaseToChange,contentIdPhase);
        }   
    }

    modalToDescartOConfirmChanges = (phaseToChange,contentIdPhase) => {
        this.setState({
            modalContent:""
        });
        let modalContent;
        modalContent = i18n.translate("confirm_discard_cancel_changes");
        let modalTitle = i18n.translate("choose_an_option");
        let buttons = [{text: i18n.translate("default_confirm"), function: () => {this.saveElectronicBillingPhase(true,phaseToChange,contentIdPhase);this.setState({modalContent:""})}},
        {text: i18n.translate("default_discard"), function: () => {this.discardChangesAndNavigate(phaseToChange, contentIdPhase);}},
        {text: i18n.translate("cancel"), function: ""}];
        setTimeout(()=> {
            this.setState({modalContent:<DefaultModal show={true} title={modalTitle}
            content={<div dangerouslySetInnerHTML={{ __html: modalContent }} />} buttons={buttons}/>})
        }, 10);
    } 

    discardChangesAndNavigate = (phaseToChange,contentIdPhase) => {
        this.setState({
            modalContent:""
        });
        this.cancelEdit(true,phaseToChange,contentIdPhase);
    } 

    compareIssuerDate = () => {
        let areEqual = true;
        let initialIssuerData = this.state.resultInitialValue.fePyServiceIssuerData;
        if(initialIssuerData.ruc != document.querySelector("#customerFiscalNumber").value || initialIssuerData.checkDigit != parseInt(document.querySelector("#rucVerificationDigit").value) 
        || initialIssuerData.businessName != document.querySelector("#customerName").value || initialIssuerData.businessAlias != document.querySelector("#customerAlias").value 
        || initialIssuerData.phone != document.querySelector("#phoneNumber").value || initialIssuerData.email != document.querySelector("#email").value 
        || initialIssuerData.address != document.querySelector("#address").value || initialIssuerData.houseNumber != parseInt(document.querySelector("#houseNumber").value) 
        || initialIssuerData.complementAddress1 != document.querySelector("#address1").value || initialIssuerData.complementAddress2 != document.querySelector("#address2").value 
        || initialIssuerData.departmentCode != parseInt(document.querySelector("#departmentCode").value) || initialIssuerData.departmentDescription != document.querySelector("#departmentDescription").value 
        || initialIssuerData.cityCode != parseInt(document.querySelector("#cityCode").value) || initialIssuerData.cityDescription != document.querySelector("#cityDescription").value 
        || initialIssuerData.taxPayerType != document.querySelector("#typeOfTaxpayer").value){
            areEqual = false;
        } 

        if(areEqual){
            areEqual = this.arraysEconomicActiviriesAreEqual(this.state.userCategory, this.state.resultInitialValue.fePyEconomicActivitiesList);
        }
        
        return areEqual;
    } 

    compareDigitalSignature = () => {
        let areEqual = true;
        let initialDigitalSignatureData = this.state.resultInitialValue.fePyDigitalSignature;
        if(this.state.keyStoreBase64 != this.state.keyStoreBase64ToCompare || initialDigitalSignatureData.pass != document.querySelector("#pass").value 
        || initialDigitalSignatureData.alias != document.querySelector("#alias").value || initialDigitalSignatureData.passAlias != document.querySelector("#passAlias").value){
            areEqual = false;
        }
        
        return areEqual;
    } 
    
    compareStampData = () => {
        let areEqual = true;
        let initialStampData = this.state.resultInitialValue.fePyServiceFiscalStampData;
        let dateString = initialStampData.fiscalStampStartValidityDate;
        let parts = dateString.split('-'); 
        let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`; 

        let stateDateStamp = new Date(this.state.dateStamp);

        if (initialStampData.idCsc != document.querySelector("#idCSC").value || 
            initialStampData.csc != document.querySelector("#csc").value ||
            initialStampData.fiscalStampNumber != document.querySelector("#fiscalStampNumber").value ||
            new Date(formattedDate).getTime() != stateDateStamp.getTime()) {
                areEqual = false;
        }

        if(areEqual){
            areEqual = this.arraysAreEqual(this.state.usersForStampToCompare, this.state.usersForStamp);
        } 

        return areEqual;
    }

    arraysEconomicActiviriesAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
  
        const sortedArr1 = [...arr1].sort((a, b) => a.code.localeCompare(b.code));
        const sortedArr2 = [...arr2].sort((a, b) => a.code.localeCompare(b.code));
        
        return sortedArr1.every((item, index) => this.areObjectsEconomicActivitiesEqual(item, sortedArr2[index]));
    }
    
    areObjectsEconomicActivitiesEqual = (obj1, obj2) => {
        return obj1.code === obj2.code &&
         obj1.description === obj2.description
    } 
    
    arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        
        const sortedArr1 = [...arr1].sort((a, b) => a.seriesNumber.localeCompare(b.seriesNumber));
        const sortedArr2 = [...arr2].sort((a, b) => a.seriesNumber.localeCompare(b.seriesNumber));
        
        return sortedArr1.every((item, index) => this.areObjectsEqual(item, sortedArr2[index]));
    };

    areObjectsEqual = (obj1, obj2) => {
    return obj1.seriesNumber === obj2.seriesNumber &&
            obj1.branch === obj2.branch &&
            obj1.printer === obj2.printer &&
            obj1.initialNumber === obj2.initialNumber &&
            obj1.lastUsedNumber === obj2.lastUsedNumber &&
            obj1.responsibleUsername === obj2.responsibleUsername;
    };

    navigateBetweenFeTabs = (phaseToChange,contentIdPhase) => {
        const cols = document.querySelectorAll('.mzatePhasesContainerOptions .col');
            cols.forEach(col => {
                col.classList.remove('activeItem');
                col.classList.add('inactiveItem');
            });
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[phaseToChange-1].classList.remove('inactiveItem'); 
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[phaseToChange-1].classList.add('activeItem'); 
            if(phaseToChange == 1){
                document.querySelector("#contentDigitalSignature").classList.add("displayNone");
                document.querySelector("#contentStamp").classList.add("displayNone");
                this.setState({currentPhaseView:1});
                if(this.state.currentBillingPhase > 1){
                    this.displayInputsIssuerData(true);
                }
            }else if(phaseToChange == 2){
                document.querySelector("#contentIssuerData").classList.add("displayNone");
                document.querySelector("#contentStamp").classList.add("displayNone");
                this.setState({currentPhaseView:2})
                if(this.state.currentBillingPhase > 2 || this.state.currentBillingPhase == 0){
                    this.displayInputsDigitalSignature(true);
                }else{
                    this.displayInputsDigitalSignature(false);
                }   
            }else if(phaseToChange == 3){
                this.setState({currentPhaseView:3});
                if(this.state.currentBillingPhase == 0){
                    this.displayInputsStamp(true);
                }else{
                    this.displayInputsStamp(false)
                } 
                document.querySelector("#contentIssuerData").classList.add("displayNone");
                document.querySelector("#contentDigitalSignature").classList.add("displayNone");
            }   
            document.querySelector("#"+contentIdPhase).classList.remove("displayNone");
    } 

    displayInputsStamp = (displayTrueFalse, isUpdate) => {
        document.querySelector("#idCSC").disabled = displayTrueFalse;
        document.querySelector("#csc").disabled = displayTrueFalse;
        document.querySelector("#fiscalStampNumber").disabled = displayTrueFalse;
        document.querySelector("#datePickerInput").disabled = displayTrueFalse;
        if(!isUpdate){
            if(displayTrueFalse){
                document.querySelectorAll(".addContent")[1].style.pointerEvents = 'none';
            }else{
                document.querySelectorAll(".addContent")[1].style.pointerEvents = '';
            }  

            const userForStamp = document.querySelectorAll(".divUserForStamp .css-b62m3t-container");
            userForStamp.forEach(input => {
                if(displayTrueFalse){
                    input.style.pointerEvents = 'none';
                }else{
                    input.style.pointerEvents = '';
                }   
            });
            const removeUserForStamp = document.querySelectorAll(".removeContentImg");
            removeUserForStamp.forEach(input => {
                if(displayTrueFalse){
                    input.style.pointerEvents = 'none';
                }else{
                    input.style.pointerEvents = '';
                }   
            });
            const stampStablishment = document.querySelectorAll(".stampStablishment");
            stampStablishment.forEach(input => {
                input.disabled = true;
            });
            const stampInvoicePrinter = document.querySelectorAll(".stampInvoicePrinter");
            stampInvoicePrinter.forEach(input => {
                input.disabled = true;
            });
            const stampInitialNumber = document.querySelectorAll(".stampInitialNumber");
            stampInitialNumber.forEach(input => {
                input.disabled = true;
            });
            const stampNumberingSeries = document.querySelectorAll(".stampNumberingSeries");
            stampNumberingSeries.forEach(input => {
                input.disabled = true;
            });
            const lastUsedNumber = document.querySelectorAll(".lastUsedNumber");
            lastUsedNumber.forEach(input => {
                input.disabled = true;
            });
        } 
    } 

    displayInputsIssuerData = (displayTrueFalse) => {
        document.querySelector("#customerFiscalNumber").disabled = displayTrueFalse;
        document.querySelector("#rucVerificationDigit").disabled = displayTrueFalse;
        document.querySelector("#customerName").disabled = displayTrueFalse;
        document.querySelector("#customerAlias").disabled = displayTrueFalse;
        document.querySelector("#address").disabled = displayTrueFalse;
        document.querySelector("#houseNumber").disabled = displayTrueFalse;
        document.querySelector("#phoneNumber").disabled = displayTrueFalse;
        document.querySelector("#email").disabled = displayTrueFalse;
        document.querySelector("#typeOfTaxpayer").disabled = displayTrueFalse;
        document.querySelector("#address1").disabled = displayTrueFalse;
        document.querySelector("#address2").disabled = displayTrueFalse;
        document.querySelector("#departmentCode").disabled = displayTrueFalse;
        document.querySelector("#departmentDescription").disabled = displayTrueFalse;
        document.querySelector("#cityCode").disabled = displayTrueFalse;
        document.querySelector("#cityDescription").disabled = displayTrueFalse;
        if(displayTrueFalse){
            document.querySelectorAll(".addContent")[0].style.pointerEvents = 'none';
        }else{
            document.querySelectorAll(".addContent")[0].style.pointerEvents = '';
        }  
        
        const removeUserForStamp = document.querySelectorAll(".removeContentImg");
        removeUserForStamp.forEach(input => {
            if(displayTrueFalse){
                input.style.pointerEvents = 'none';
            }else{
                input.style.pointerEvents = '';
            }   
        });
        const categoryCodeInput = document.querySelectorAll(".categoryCode");
        categoryCodeInput.forEach(input => {
            input.disabled = displayTrueFalse;
        });
        const categoryDescriptionInput = document.querySelectorAll(".categoryDescription");
        categoryDescriptionInput.forEach(input => {
            input.disabled = displayTrueFalse;
        }); 
    } 

    displayInputsDigitalSignature = (displayTrueFalse) => {
        document.querySelector("#pass").disabled = displayTrueFalse;
        document.querySelector("#alias").disabled = displayTrueFalse;
        document.querySelector("#passAlias").disabled = displayTrueFalse;
        document.querySelectorAll(".inputFile")[0].disabled = displayTrueFalse;
        const spanIcon = document.querySelectorAll(".spanIconShowHidePass");
        spanIcon.forEach(input => {
            if(displayTrueFalse){
                input.style.pointerEvents = 'none';
            }else{
                input.style.pointerEvents = '';
            }
        });
    } 

    executeCreateIssuerData = (issuerData) => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').post("/fePyServiceIssuerData",issuerData)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseIssuerData(result); 
                this.setState({spinner:"",modalContent2:""})
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    executeUpdateIssuerData = (issuerData,isConfirmEdit,phaseToChange,contentIdPhase) => {
        issuerData.id = this.state.issuerData.id;
        issuerData.replicaId = this.state.issuerData.replicaId;
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').put("/fePyServiceIssuerData",issuerData)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseIssuerData(result,isConfirmEdit,phaseToChange,contentIdPhase);
                this.setState({spinner:""})
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    handleResponseIssuerData = (result,isConfirmEdit,phaseToChange,contentIdPhase) => {
        if(result.status === 0 || result.status === "0"){
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[0].style.pointerEvents = 'all';
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[0].style.cursor = 'pointer';
            if(this.state.isEdit){
                this.setState({
                    isEdit:false
                });
            }
            setTimeout(()=> { 
                this.getUserBillingPhase(false,'','',isConfirmEdit,phaseToChange,contentIdPhase);
            }, 50);
        }else{
            setTimeout(()=> { 
                this.setState({
                    modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
                });
            }, 10);
        } 
    }

    executeUpdateDigitalSignature = (digitalSignature,isConfirmEdit,phaseToChange,contentIdPhase) => {
        digitalSignature.id = this.state.digitalSignature.id;
        digitalSignature.replicaId = this.state.digitalSignature.replicaId;
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').put("/fePyDigitalSignature",digitalSignature)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseDigitalSignature(result,isConfirmEdit,phaseToChange,contentIdPhase);
                this.setState({spinner:"",modalContent2:""})
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    executeCreateDigitalSignature = (digitalSignature) => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').post("/fePyDigitalSignature",digitalSignature)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseDigitalSignature(result);
                this.setState({spinner:""})
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    handleResponseDigitalSignature = (result,isConfirmEdit,phaseToChange,contentIdPhase) => {
        if(result.status == 0 || result.status == "0"){
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[1].style.pointerEvents = 'all';
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[1].style.cursor = 'pointer';
            if(this.state.isEdit){
                this.setState({
                    isEdit:false
                });
            }
            setTimeout(()=> { 
                this.getUserBillingPhase(false,'','',isConfirmEdit,phaseToChange,contentIdPhase);
            }, 50);
        }else{
            setTimeout(()=> { 
                this.setState({
                    modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
                });
            }, 10);
        }
    }

    executeUpdateFiscalStampData = (stamp,isConfirmEdit,phaseToChange,contentIdPhase) => {
        stamp.id = this.state.stampData.id;
        stamp.replicaId = this.state.stampData.replicaId;
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').put("/fePyServiceFiscalStampData",stamp)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseFiscalStampData(result,isConfirmEdit,phaseToChange,contentIdPhase);
                this.setState({spinner:"",modalContent2:""}) 
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    executeCreateFiscalStampData = (stamp) => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').post("/fePyServiceFiscalStampData",stamp)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    spinner:"",
                    fePyServiceFiscalStampDataReplicaId: result.replicaId
                });
                this.handleResponseFiscalStampData(result)
                
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    handleResponseFiscalStampData = (result,isConfirmEdit,phaseToChange,contentIdPhase) => {
        if(result.status == 0 || result.status == "0"){
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[2].style.pointerEvents = 'all';
            document.querySelectorAll(".mzatePhasesContainerOptions .col")[2].style.cursor = 'pointer';
            if(this.state.isEdit){
                this.setState({
                    currentPhaseView:3,
                    isEdit:false
                });
            }
            if(!this.state.stampData.id){
                //pedir datos de timbrado
                this.getFiscalStampData(isConfirmEdit,phaseToChange,contentIdPhase);
            }else if(this.state.usersForStamp.length > 0){
                this.saveUsersForStamp(result.replicaId,isConfirmEdit,phaseToChange,contentIdPhase);
            }else{
                this.getUserBillingPhase(false,'','',isConfirmEdit,phaseToChange,contentIdPhase);
            }
        }else{
            setTimeout(()=> { 
                this.setState({
                    modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
                });
            }, 10);
        }
    }

    getFiscalStampData = (isConfirmEdit,phaseToChange,contentIdPhase) =>{
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').get("/fePy/getFiscalStampData")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result){
                    this.setState({
                        stampData:result.fePyServiceFiscalStampData,
                        spinner:""
                    })
                    if(this.state.usersForStamp.length > 0){
                        this.saveUsersForStamp(result.fePyServiceFiscalStampData.replicaId);
                    }else{
                        this.getUserBillingPhase(false,'','',isConfirmEdit,phaseToChange,contentIdPhase);
                    }
                } 
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    prepareToCreateUpdateIssuerData = (isConfirmEdit,phaseToChange,contentIdPhase) => {  
        let issuerData = {}
        
        issuerData.ruc = document.querySelector("#customerFiscalNumber").value;
        issuerData.checkDigit = document.querySelector("#rucVerificationDigit").value;
        issuerData.taxpayerType = document.querySelector("#typeOfTaxpayer").value;
        issuerData.businessName = document.querySelector("#customerName").value;
        issuerData.businessAlias = document.querySelector("#customerAlias").value;
        issuerData.address = document.querySelector("#address").value;
        issuerData.phone = document.querySelector("#phoneNumber").value;
        issuerData.email = document.querySelector("#email").value;
        issuerData.houseNumber = document.querySelector("#houseNumber").value;

        issuerData.economicActivities = this.state.userCategory;

        issuerData.complementAddress1 = document.querySelector("#address1").value;
        issuerData.complementAddress2 = document.querySelector("#address2").value;
        issuerData.departmentCode = document.querySelector("#departmentCode").value;
        issuerData.departmentDescription = document.querySelector("#departmentDescription").value;
        issuerData.cityCode = document.querySelector("#cityCode").value;
        issuerData.cityDescription = document.querySelector("#cityDescription").value;

        let userCategory = false;
        this.state.userCategory.forEach((user) => {
            if(user.code == '' || user.categoty == ''){
                    userCategory = true;
            } 
        })
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(issuerData.ruc === '' || issuerData.checkDigit === '' || issuerData.taxpayerType === '' || issuerData.businessName === '' || 
            issuerData.address === '' || issuerData.phone === '' || userCategory || issuerData.email === '' || issuerData.houseNumber === '' || 
            issuerData.cityCode === '' || issuerData.cityDescription === '' || issuerData.departmentCode === '' || issuerData.departmentDescription === ''){
            toastFunction(i18n.translate("must_complete_all_issuer_information"),"warning");
        }else if(!emailPattern.test(issuerData.email)){
            toastFunction(i18n.translate("enter_a_valid_email_address"),"warning");
        }else if(this.state.currentBillingPhase === 1 || this.state.currentBillingPhase == '1'){
            if(this.state.isEdit){
                this.executeUpdateIssuerData(issuerData,isConfirmEdit,phaseToChange,contentIdPhase);
            }else{
                this.executeCreateIssuerData(issuerData);
            }
        }
    }
    
    prepareToCreateUpdateDigitalSignature = (isConfirmEdit,phaseToChange,contentIdPhase) => {
        let digitalSignature = {
            
        } 

        digitalSignature.pass = document.querySelector("#pass").value;
        digitalSignature.alias = document.querySelector("#alias").value;
        digitalSignature.passAlias = document.querySelector("#passAlias").value;
        digitalSignature.keyStoreBase64 = this.state.keyStoreBase64;

        if(digitalSignature.pass === '' || digitalSignature.alias === ''
            || digitalSignature.passAlias === '' || digitalSignature.keyStoreBase64 === ''){
            toastFunction(i18n.translate("must_complete_all_digital_signature_data"),"warning");
        }else if(this.state.currentBillingPhase === 2){
            if(this.state.isEdit){
                this.executeUpdateDigitalSignature(digitalSignature,isConfirmEdit,phaseToChange,contentIdPhase);
            }else{
                this.executeCreateDigitalSignature(digitalSignature);
            }
        }
    }
    
    prepareToCreateUpdateStampData = (isConfirmEdit,phaseToChange,contentIdPhase) => {
        let stamp = {
                
        } 
        stamp.idCsc = document.querySelector("#idCSC").value;
        stamp.csc = document.querySelector("#csc").value;
        stamp.fiscalStampNumber = document.querySelector("#fiscalStampNumber").value;
        stamp.fiscalStampStartValidityDateStr = this.getDateStamp(this.state.dateStamp);
        stamp.isProduction = this.state.isProduction;
        if(stamp.idCsc == '' || stamp.csc == '' || stamp.fiscalStampNumber == '' || stamp.fiscalStampStartValidityDateStr == ''){
            toastFunction(i18n.translate("must_complete_all_stamping_information"),"warning");
        }else if(this.state.currentBillingPhase == 3){
            if(this.state.isEdit){
                this.executeUpdateFiscalStampData(stamp,isConfirmEdit,phaseToChange,contentIdPhase);
            }else{
                this.executeCreateFiscalStampData(stamp);
            }
        }
    } 

    saveElectronicBillingPhase = (isConfirmEdit,phaseToChange,contentIdPhase) => {
        if(this.state.currentBillingPhase == 1 || this.state.currentBillingPhase == '1'){
            this.prepareToCreateUpdateIssuerData(isConfirmEdit,phaseToChange,contentIdPhase);
        }else if(this.state.currentBillingPhase === 2){
            this.prepareToCreateUpdateDigitalSignature(isConfirmEdit,phaseToChange,contentIdPhase)
        }else if(this.state.currentBillingPhase === 3){
            this.prepareToCreateUpdateStampData(isConfirmEdit,phaseToChange,contentIdPhase);
        } 
    }

    getDateStamp = (stampDate) => {
        let m = stampDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = stampDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        stampDate = stampDate.getFullYear()+"-"+mes+"-"+dayForShow;
        return stampDate;
    } 

    showFaqSection = () => {
        document.querySelector("#colFAQ").classList.toggle('displayNone');
    } 

    editPhase = () => {
        this.setState({isEdit:true});
        let indexCol;
        if(this.state.currentPhaseView == 1){
            this.displayInputsIssuerData(false);
            this.setState({currentBillingPhase:1});
            indexCol = 0;
        }else if(this.state.currentPhaseView == 2){
            this.displayInputsDigitalSignature(false);
            this.setState({currentBillingPhase:2});
            indexCol = 1;
        }else if(this.state.currentPhaseView == 3){
            this.displayInputsStamp(false);
            this.setState({currentBillingPhase:3});
            indexCol = 2;
        } 
        document.querySelectorAll(".mzatePhasesContainerOptions .col")[indexCol].style.pointerEvents = 'none';
        document.querySelectorAll(".mzatePhasesContainerOptions .col")[indexCol].style.cursor = 'none'; 
        document.querySelector("#switchToProduction").style.display = 'contents'; 
    } 

    cancelEdit = (isDiscardChange,phaseToChange,contentIdPhase) => {
        let currentPhaseViewDiv;
        let indexCol;
        if(this.state.currentPhaseView == 1 || this.state.currentPhaseView == "1"){
            currentPhaseViewDiv = 'contentIssuerData';
            indexCol = 0;
        }else if(this.state.currentPhaseView == 2 || this.state.currentPhaseView == "2"){
            currentPhaseViewDiv = 'contentDigitalSignature';
            indexCol = 1;
        }else{
            currentPhaseViewDiv = 'contentStamp';
            indexCol = 2;
        }   
        document.querySelectorAll(".mzatePhasesContainerOptions .col")[indexCol].style.pointerEvents = '';
        document.querySelectorAll(".mzatePhasesContainerOptions .col")[indexCol].style.cursor = 'pointer';
        this.getUserBillingPhase(true,currentPhaseViewDiv,this.state.currentPhaseView,isDiscardChange,phaseToChange,contentIdPhase);
    } 
    

    saveUsersForStamp = (fePyServiceFiscalStampDataReplicaId, isConfirmEdit, phaseToChange, contentIdPhase) => {
        fePyServiceFiscalStampDataReplicaId = fePyServiceFiscalStampDataReplicaId || this.state.fePyServiceFiscalStampDataReplicaId;
        const usersForStamp = this.state.usersForStamp;
        let usersForStampComplete = true;
        const seenUsernames = new Map();
        for (const stamp of usersForStamp) {
            if(!stamp.fePyServiceFiscalStampDataReplicaId) {
                stamp.fePyServiceFiscalStampDataReplicaId = fePyServiceFiscalStampDataReplicaId;
            }
            if([stamp.seriesNumber, stamp.branch, stamp.printer, stamp.initialNumber, stamp.lastUsedNumber, stamp.responsibleUsername].some(field => field === '')) {
                usersForStampComplete = false;
            }
            if(stamp.operationType != 'D' && !stamp.responsibleUsername){
                toastFunction(i18n.translate("you_must_select_a_user"), "warning");
                this.setState({ isEdit: true });
                return;
            }else if (stamp.operationType !== 'D') {
                if (seenUsernames.has(stamp.responsibleUsername)) {
                    const previousOperationType = seenUsernames.get(stamp.responsibleUsername);
                    if (previousOperationType !== 'D') {
                        toastFunction(i18n.translate("no_more_than_one_shipping_point"), "warning");
                        this.setState({ isEdit: true });
                        return;
                    }
                }
            }
            seenUsernames.set(stamp.responsibleUsername, stamp.operationType);
            if(stamp.operationType != 'D' && !/^\d{3}$/.test(stamp.branch)) {
                toastFunction(i18n.translate("establishment_field_must_have_3_digits"), "warning");
                this.setState({ isEdit: true });
                return;
            }
            if(stamp.operationType != 'D' && !/^\d{3}$/.test(stamp.printer)) {
                toastFunction(i18n.translate("printer_field_must_have_3_digits"), "warning");
                this.setState({ isEdit: true });
                return;
            }
            if(stamp.operationType != 'D' && (parseInt(stamp.lastUsedNumber) < parseInt(stamp.initialNumber))) {
                toastFunction(i18n.translate("the_last_number_cannot_be_less_than_initial_number"), "warning");
                this.setState({ isEdit: true });
                return;
            }
        }
        this.validateAndExecuteSaveUsersForStamp(fePyServiceFiscalStampDataReplicaId, usersForStampComplete, usersForStamp, isConfirmEdit, phaseToChange, contentIdPhase);
    }
    
    
    validateAndExecuteSaveUsersForStamp = (fePyServiceFiscalStampDataReplicaId,usersForStampComplete,usersForStamp,isConfirmEdit, phaseToChange, contentIdPhase) => {
        if (!fePyServiceFiscalStampDataReplicaId) {
            toastFunction(i18n.translate("must_complete_all_stamping_information"), "warning");
            this.setState({ isEdit: true });
        } else if (!usersForStampComplete) {
            toastFunction(i18n.translate("you_must_complete_all_user_data"), "warning");
            this.setState({ isEdit: true });
        } else {
            this.setState({
                spinner: <DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
                independent={document.querySelector("#independent").value} />,
                modalContent2: ""
            });
            DefaultAjax('').post("/fePyUserFiscalStampData", usersForStamp)
            .then((res) => {
                if (res) {
                    const result = JSON.parse(res.data);
                    this.setState({ spinner: "" });
                    
                    if (result.status == 0 || result.status == "0") {
                        toastFunction(i18n.translate("users_successfully_assigned"), "success");
                        this.getUserBillingPhase(false, '', '', isConfirmEdit, phaseToChange, contentIdPhase);
                    } else {
                        setTimeout(() => {
                            this.setState({
                                modalContent2: <DefaultModalError errorList={result.errorsList} status={result.status} />,
                                isEdit: true
                            });
                        }, 10);
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    modalContent: "",
                    spinner: "",
                    component: error.message
                });

                if (error.response && error.response.status === 403) {
                    setTimeout(() => {
                        this.setState({ modalContent: modalForbidden() });
                    }, 10);
                }
            });
        }
    } 
    
    contenIssuerDataHtml = () => {
        return(
            <>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("invoice_customer_ruc")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" id="customerFiscalNumber"
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("check_digit")}
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" id="rucVerificationDigit" maxLength={1}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("invoice_customer_name")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="customerName"/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("fantasy_name")} 
                        </label>
                        <input type="text" className="form-control inputStandard"
                        id="customerAlias"/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("default_phone")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="phoneNumber"/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("default_email")}
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="email"/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("default_address")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="address"/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("house_number")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" id="houseNumber"
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            {i18n.translate("complementary_address_1")} 
                        </label>
                        <input type="text" className="form-control inputStandard"
                        id="address1"/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("complementary_address_2")} 
                        </label>
                        <input type="text" className="form-control inputStandard"
                        id="address2"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            {i18n.translate("department_code")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" id="departmentCode" 
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("department_description")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="departmentDescription"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            {i18n.translate("city_code")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" id="cityCode" 
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("city_description")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="cityDescription"/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("type_of_taxpayer")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <select className="form-select selectStandard" id="typeOfTaxpayer">
                            <option value="1">{i18n.translate("physical_person")}</option>
                            <option value="2">{i18n.translate("legal_person")}</option>
                        </select>
                    </Col>
                </Row>
                <div className="economicActivitiesContent">
                    <div>
                        <span style={{fontWeight:'bolder'}}>{i18n.translate("economic_activities")}</span> 
                        <img className='addContent' style={{marginLeft:'5px'}} onClick={()=> this.addCategory()} src={AddContent} width="20px" height="20px"/>
                    </div>
                    {this.state.userCategory.map((userCat, index) => {
                        return (
                            <Row key={index} className="colWithMarginBottom" id={'userCategoryRow-'+userCat.id}>
                                <Col>
                                    <label>
                                        {i18n.translate("category_code")} 
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input type="text" className="form-control inputStandard categoryCode"
                                    value={userCat.code} onChange={(e)=> this.handleChangeUserCategory(e,'code',index)}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}/>
                                </Col>
                                <Col>
                                    <label>
                                        {i18n.translate("category_description")} 
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input type="text" className={index > 0 ? 'form-control categoryDescription inputStandard inputWidth94' : 'form-control categoryDescription inputStandard'} 
                                    value={userCat.description} onChange={(e)=> this.handleChangeUserCategory(e,'category',index)}/>
                                    {index > 0 && 
                                    <img className='removeContentImg' onClick={() => this.removeCategory(index)} src={RemoveContent} width="20px" height="20px"/>}
                                </Col>
                            </Row>
                        )
                    })}
                </div>
            </>
        )
    } 

    digitalSignatureDataHtml = () => {
        return(
            <>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            Keystore
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input className="form-control inputStandard" onClick={this.handleButtonClick}
                        value={this.state.keyStoreBase64 ? i18n.translate("modify_file") : i18n.translate("select_file")} readOnly 
                        style={{cursor:'pointer',marginBottom:'10px'}}/>
                        <input type="file" id="file-upload" className="form-control inputFile" 
                        ref={this.fileInputRef} onChange={this.handleChangeFileSignature}
                        style={{ height: '30px', display: 'none' }}/>
                        <span id="spanSelectedFileName"></span>
                    </Col>
                    <Col style={{position:'relative'}}>
                        <label>
                            {i18n.translate("default_pass")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type={this.state.showPassword ? "text" : "password"} 
                        className="form-control inputStandard"
                        id="pass"/>
                        <br></br> 
                        <span onClick={() => this.setShowPassword(this.state.showPassword)} className="spanIconShowHidePass">
                            {this.state.showPassword ? <FaIcons.FaEyeSlash /> : <FaIcons.FaEye />}
                        </span>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            Alias
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="alias"/>
                    </Col>
                    <Col style={{position:'relative'}}>
                        <label>
                            {i18n.translate("default_pass")} Alias
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type={this.state.showPassword2 ? "text" : "password"} 
                        className="form-control inputStandard" id="passAlias"/>
                        <br></br> 
                        <span onClick={() => this.setShowPassword2(this.state.showPassword2)} className="spanIconShowHidePass">
                            {this.state.showPassword2 ? <FaIcons.FaEyeSlash /> : <FaIcons.FaEye />}
                        </span>
                    </Col>
                </Row>
            </>
        )
    }

    passToProduction = () => {
        this.setState({isProduction:true,modalContent:""})
    } 
    
    stampDataHtml = () => {
        const stylesForSelectContent = {
            menuPortal: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'}),
            menu: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'})
        }
        const handleDate = (selectedDate) => {
            this.setState({dateStamp:selectedDate})
        }
        const handleChangeSwitch = (e) => {
            if(e){
                this.setState({
                    modalContent:""
                });
                let modalContent = i18n.translate("confirm_pass_to_production_fe");
                let buttons = [{text: i18n.translate("default_confirm"), function: this.passToProduction},
                            {text: i18n.translate("cancel"), function: ""}];
                setTimeout(() => { 
                    this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("move_to_production_environment")}
                    content={modalContent} buttons={buttons}/>})
                }, 10);
            }else if(!this.state.isProductionToCompare){
                this.setState({isProduction:e})
            } 
        } 
        return(
            <>
                <div style={{textAlign:'end'}}>
                    <label style={{fontWeight:'bold',marginRight:'5px',verticalAlign:'super'}}>
                        {this.state.isProduction ? i18n.translate("production_environment") : i18n.translate("test_environment")}
                    </label>
                    <div id="switchToProduction" style={{display:'none'}}>
                        <Switch onChange={handleChangeSwitch} checked={this.state.isProduction}></Switch> 
                    </div>
                </div>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            idCSC
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="idCSC"/>
                    </Col>
                    <Col>
                        <label>
                            csc
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="csc"/>
                    </Col>
                </Row>
                <Row className="colWithMarginBottom">
                    <Col>
                        <label>
                            {i18n.translate("invoice_fiscal_stamp_number")}   
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard"
                        id="fiscalStampNumber"
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}/>
                    </Col>
                    <Col>
                        <label>
                            {i18n.translate("stamped_start_date")} 
                        </label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <div>
                            <DatePicker id="datePickerInput" className="form-control inputStandard" selected={this.state.dateStamp} onChange={handleDate} />
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop:'15px'}}>
                    {i18n.translate("add_user")} 
                    <img className='addContent' style={{marginLeft:'5px'}} onClick={()=> this.addUserForStamp()} src={AddContent} width="20px" height="20px"/>
                </div>
                
                {this.state.usersForStamp.map((userStamp,index) => {
                    return(
                        <div key={index} className="userForStamp" id={'userForStampDiv-'+userStamp.id}>
                            <Row>
                                <Col>
                                    <label>
                                        {i18n.translate("default_user")}
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input id={'userSelected-'+index} type="text" readOnly style={{ display: 'none' }}/>
                                    <input id={'userSelectedDescription-'+index} readOnly type="text" style={{ display: 'none' }}/>
                                    <Row>
                                        <div className="divUserForStamp col">
                                            <Select menuShouldScrollIntoView={false} onChange={(e) => this.handleChangeUser(e,index)} maxMenuHeight={220} 
                                            options={this.state.users} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                                            value={{ label: userStamp.responsibleUsername, value: userStamp.responsibleUsername }} />
                                        </div>
                                        <div style={{display: 'inline'}} className="col">                                                        
                                            <img className='removeContentImg' onClick={() => this.removeUserForStamp(index)} src={RemoveContent} width="20px" height="20px"/>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="colWithMarginBottom">
                                <Col>
                                    <label>
                                        {i18n.translate("stablishment")} 
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input type="text" className="form-control stampStablishment inputStandard" name="establishment" 
                                    value={userStamp.branch} onChange={(e) => this.handleChangeUserForStamp(e,index,'establishment')} 
                                    maxLength={3} 
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}/>
                                </Col>
                                <Col>
                                    <label>
                                        {i18n.translate("default_printer")} 
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input type="text" className="form-control stampInvoicePrinter inputStandard" name="invoicePrinter" 
                                    value={userStamp.printer} maxLength={3} onChange={(e) => this.handleChangeUserForStamp(e,index,'invoicePrinter')}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}/>
                                </Col>
                            </Row>
                            <Row className="colWithMarginBottom">
                                <Col>
                                    <div style={{display:'inline-block',width:'50%',paddingRight:'10px'}}>
                                        <label>
                                            {i18n.translate("initial_number")} 
                                        </label>
                                        <span className="requiredFieldsIcon">(*)</span>
                                        <input type="text" className="form-control stampInitialNumber inputStandard" name="initialNumber" 
                                        value={userStamp.initialNumber} disabled onChange={(e) => this.handleChangeUserForStamp(e,index,'initialNumber')}
                                        maxLength={7} 
                                        onKeyDown={handleKeyDown}
                                        onPaste={handlePaste}/>
                                    </div>
                                    <div style={{display:'inline-block',width:'50%',paddingLeft:'10px'}}>
                                        <label>
                                            {i18n.translate("next_number_to_use")} 
                                        </label>
                                        <span className="requiredFieldsIcon">(*)</span>
                                        <input type="text" className="form-control lastUsedNumber inputStandard" name="lastUsedNumber" 
                                        value={userStamp.lastUsedNumber} onChange={(e) => this.handleChangeUserForStamp(e,index,'lastUsedNumber')}
                                        onKeyDown={handleKeyDown}
                                        onPaste={handlePaste}/>
                                    </div>
                                </Col>
                                <Col>
                                    <label>
                                        {i18n.translate("numbering_series")} 
                                    </label>
                                    <span className="requiredFieldsIcon">(*)</span>
                                    <input type="text" className="form-control stampNumberingSeries inputStandard" name="numberingSeries" 
                                    value={userStamp.seriesNumber} onChange={(e) => this.handleChangeUserForStamp(e,index,'numberingSeries')}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}/>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
            </>
        )
    } 

    render(){
        return (
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner} 
                    <Row>
                        <div style={{paddingBottom:'15px',paddingTop:'15px'}}>
                            {this.state.currentPhaseView != this.state.currentBillingPhase && 
                                <button onClick={() => this.editPhase()} className={'buttonMzatePrimary'}>
                                    {i18n.translate("default_edit")} 
                                </button>
                            } 
                            {this.state.currentPhaseView == this.state.currentBillingPhase && 
                                <button onClick={() => this.saveElectronicBillingPhase()} className={'buttonMzatePrimary buttonNext'}>
                                    {this.state.isEdit ? i18n.translate("default_confirm") : i18n.translate("default_next")}
                                </button>
                            } 
                            {' '} 
                            {this.state.isEdit && 
                                <button onClick={() => this.cancelEdit()} className={'buttonMzateSecondary'}>
                                    {i18n.translate("cancel")}
                                </button>
                            }
                            {' '} 
                            <button onClick={() => this.showFaqSection()} className={'buttonMzateSecondary'}>
                                FAQ
                            </button>
                        </div>
                        <Col id="electronicInvoiceData">
                            <Row className="mzatePhasesContainerOptions" style={{marginRight:'2px',marginLeft:'2px'}}>
                                <Col onClick={()=> this.changeBillingPhase(1,'contentIssuerData')}
                                className={this.state.currentBillingPhase == 1 || this.state.currentBillingPhase == '1' ? 'activeItem' : 'inactiveItem'}
                                style={{marginRight: 'calc(var(--bs-gutter-x)*.5)'}}>
                                    <span style={{fontSize:'17px'}}>{i18n.translate("issuer_data")}</span>
                                </Col>
                                <Col onClick={()=> this.changeBillingPhase(2,'contentDigitalSignature')}
                                className={this.state.currentBillingPhase == 2 || this.state.currentBillingPhase == '2' ? 'activeItem' : 'inactiveItem'}
                                style={{marginRight: 'calc(var(--bs-gutter-x)*.5)'}}>
                                    <span style={{fontSize:'17px'}}>{i18n.translate("digital_signature")}</span> 
                                </Col>
                                <Col onClick={()=> this.changeBillingPhase(3,'contentStamp')}
                                className={this.state.currentBillingPhase == 3 || this.state.currentBillingPhase == '3' || this.state.currentBillingPhase == 0 || this.state.currentBillingPhase == '0' ? 'activeItem' : 'inactiveItem'}>
                                    <span style={{fontSize:'17px'}}>{i18n.translate("invoice_fiscal_stamp_number")}</span> 
                                </Col>
                            </Row>
                            <div id="contentIssuerData" 
                            className={this.state.currentBillingPhase == 1 || this.state.currentBillingPhase == '1' ? '' : 'displayNone'} >
                                {this.contenIssuerDataHtml()} 
                            </div>
                            <div id="contentDigitalSignature" 
                            className={this.state.currentBillingPhase == 2 || this.state.currentBillingPhase == '2' ? '' : 'displayNone'}>
                                {this.digitalSignatureDataHtml()} 
                            </div>
                            <div id="contentStamp" 
                            className={this.state.currentBillingPhase == 3 || this.state.currentBillingPhase == '3' || this.state.currentBillingPhase == 0 || this.state.currentBillingPhase == '0' ? '' : 'displayNone'}
                            style={{paddingBottom:'15px'}}>
                                {this.stampDataHtml()} 
                            </div>
                        </Col>
                        <Col id="colFAQ" className="displayNone">
                            <div style={{paddingTop: '15px'}}>
                                <h4>Preguntas frecuentes</h4>
                                <div>
                                    <div className="billingFaqOptions" onClick={()=> this.showHideAnswer('1')}>
                                        REQUISITOS PARA SER EMISOR DE DOCUMENTOS TRIBUTARIOS ELECTRÓNICOS 
                                        <div className="billingFaqAnswer displayNone" id="billingFaqAnswer-1">
                                            <ul>
                                                <li>
                                                    Estar habilitado por la administración tributaria como facturador electrónico
                                                </li>
                                                <li>
                                                    Adquirir el certificado digital de los prestadores de servicios de certificación autorizados, con el objetivo de garantizar la autenticidad, Integridad y no repudio de los documentos electrónicos que emita, para lo cual debe de contener el Registro Único de Contribuyente (RUC) del facturador electrónico.
                                                </li>
                                                <li>
                                                    Solicitar la autorización y timbrado a través del sistema de gestión tributaria Marangatu, utilizando para el efecto su clave de acceso confidencial de usuario, para lo cual deberán estar al día en el cumplimiento de sus obligaciones tributarias y contar con RUC activo.
                                                </li>
                                                <li>
                                                    Solicitar el código de seguridad del contribuyente (CSC) a la SET para la generación del QR a ser impreso
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="billingFaqOptions" onClick={()=> this.showHideAnswer('2')}>
                                        QUÉ CARACTERÍSTICAS TIENEN LOS DOCUMENTOS ELECTRÓNICOS? 
                                        <div className="billingFaqAnswer displayNone" id="billingFaqAnswer-2">
                                            <ul>
                                                <li>
                                                    Poseen validez jurídica
                                                </li>
                                                <li>
                                                    Están firmadas digitalmente por el emisor
                                                </li>
                                                <li>
                                                    Son validadas y aprobadas por la subsecretaría de estado de tributación
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="billingFaqOptions" onClick={()=> this.showHideAnswer('3')}>
                                        ¿CÓMO ES EL TIMBRADO PARA FACTURACIÓN ELECTRÓNICA?
                                        <div className="billingFaqAnswer displayNone" id="billingFaqAnswer-3">
                                            <ul>
                                                <li>
                                                    El timbrado de facturación electrónica es diferente al Timbrado tradicional. El mismo se solicita por Marangatú, para cada tipo de documento electrónico y comienza con una nueva secuencia numérica. Está compuesto por: El número  de timbrado, Establecimiento, Punto de expedición, Tipo de documento, Número de documento y Serie
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="billingFaqOptions" onClick={()=> this.showHideAnswer('4')}>
                                        ¿CÓMO OBTENER FIRMA DIGITAL?
                                        <div className="billingFaqAnswer displayNone" id="billingFaqAnswer-4">
                                            <ul>
                                                <li>
                                                La firma digital (firma electrónica certificada por un prestador habilitado), se adquiere a través de un “Prestador de Servicios de Certificación” (PSC), que es una entidad prestadora de servicios de certificación de firmas digitales acreditado por la Dirección General de Firma Digital y Comercio Electrónico del Ministerio de Industria y Comercio - MIC.
                                                Mayores informaciones en el siguiente enlace: <a href="https://www.acraiz.gov.py/" target="_blank">https://www.acraiz.gov.py/</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
} 

export default InvoiceData;