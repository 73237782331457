import React from "react";
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import {  Row, Col } from 'reactstrap';
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax } from "../../components/DefaultAjax";
import { toastFunction, catchErrorAjax, handleIndexDefaultSearchButton } from "../../components/mainFunctions";
import Select from 'react-select';
import DefaultPagination from '../../components/DefaultPagination';
import DefaultModalError from "../../components/DefaultModalError";


class Products extends React.Component{
    constructor(props){
        super(props);
        this.state={
            productsList: [],
            spinner: "",
            modalContent: "",
            modalContent2: "",
            currentProduct:"",
            taxes: [],
            unitOfMeasurement: [], 
            unitOfMeasurementSelected: "", 
            productForUpdateCreate: "",
            isCreate: false,
            offset: 0,
            perPage: 20,
            currentPage: 1,
            paginationHtml:"",
            contentPagination: ""  
        } 
    }

    componentDidMount() {
        this.getAllProducts(true);
        let nav = document.querySelector("#nav-top").offsetHeight;
        let altura = window.innerHeight - nav - 145;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        setTimeout(() => {
            this.keyDownHandler = (e) => handleIndexDefaultSearchButton(e, () => this.getAllProducts(false));
            document.addEventListener('keydown', this.keyDownHandler);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownHandler);
    }

    getAllProducts = (showAll) => {
        const renderProductRow = (product) => (
            <Col sm={12} md={3} lg={3} xl={3} key={product.id} onClick={() => this.executeShowProduct(product)}>
                <div className="productItems">
                    <div style={{textAlign:'center',fontWeight:'bolder'}}>
                        {product.fePyProductFields.productType == '0' ? i18n.translate("default_product") : i18n.translate("default_service")} 
                    </div>
                    <div className="bolder">
                        {product.code}-{product.description} 
                    </div>
                    <div>
                        <span className="bolder">{i18n.translate("storage_price")}:</span> {product.price} 
                    </div>
                    <div>
                        <span className="bolder">{i18n.translate("default_tax")}:</span> {product.tax.value}% 
                    </div>
                    <div>
                        <span className="bolder">{i18n.translate("unit_of_measurement")}:</span> {product.fePyProductFields.unitOfMeasurement}({product.fePyProductFields.symbolUnitOfMeasurement})
                    </div>
                </div>
            </Col>
        ) 

        this.setState({
            contentPagination: "",
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });

        if(showAll){
            document.querySelector("#productCode").value = '';
            document.querySelector("#productDescription").value = '';
        } 

        setTimeout(() => {
            const params = new URLSearchParams({
                productCode: document.querySelector("#productCode").value,
                productDescription: document.querySelector("#productDescription").value,
                max: this.state.perPage,
                pageNumber: this.state.currentPage
            });
    
            DefaultAjax('').get(`/fePyProducts?${params.toString()}`)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState({
                        productsList:result.productsList,
                        spinner:"",
                    })

                    const contentPagination = result.productsList.map(renderProductRow);
    
                    this.setState({
                        pageCount: Math.ceil(result.totalCount / this.state.perPage),
                        
                        contentPagination
                    })
                    setTimeout(()=> {
                        this.setState({
                            paginationHtml:<DefaultPagination className={'mzatePaginationClass'} data={result.productsList} 
                            handlePageClick={this.handlePageClick} perPage={this.state.perPage} totalCount={result.totalCount} 
                            />})
                    }, 100);
                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        }, 100);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected+1;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset,
            contentPagination: ""
        });
        this.getAllProducts(false);
    };

    getAllTaxes = (product,isUpdateValues,isCreate) => {
        if(this.state.taxes.length == 0){
            this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
            DefaultAjax('').get("/fePyTaxs")
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState({
                        taxes:result,
                        spinner:"",
                    })
                    setTimeout(()=> {
                        this.showCreateProduct(product,isUpdateValues,isCreate);
                    }, 10);
                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        }else{
            this.showCreateProduct(product,isUpdateValues,isCreate);
        }  
    } 

    showProductContent = (product,isCreate) => {
        const handleChangeUnitOfMeasurement = (option) => {
            this.setState({unitOfMeasurementSelected:option});
            setTimeout(()=> { 
                this.createProduct(product,true,isCreate);
            }, 10);
        };
        const changeValueProduct = (input,e) => {
            if(input == 'productCode'){
                product.code = e.target.value;
            }else if(input == 'productDescription'){
                product.description = e.target.value;
            }else if(input == 'price'){
                product.price = e.target.value;
            }else if(input == 'tax'){
                product.taxReplicaId = e.target.value;
            }else if(input == 'productType'){
                product.fePyProductFields.productType = e.target.value;
            }      
            this.createProduct(product,true,isCreate);
        }  
        return(
            <>
                <Row>
                    <Col>
                        <label className="bolder">{i18n.translate("type_of_product")}</label> 
                        <span className="requiredFieldsIcon">(*)</span>   
                        <select className="form-select selectStandard" 
                            onChange={(e) => changeValueProduct('productType',e)} id="selectTypeProduct">
                                <option value={0} selected={product.fePyProductFields.productType == '0' ? 'selected' : ''}>{i18n.translate("default_product")}</option>
                                <option value={1} selected={product.fePyProductFields.productType == '1' ? 'selected' : ''}>{i18n.translate("default_service")}</option>
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="bolder">{i18n.translate("default_code")}</label> 
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" value={product.code} 
                        onChange={(e) => changeValueProduct('productCode',e)}/>
                    </Col>
                    <Col>
                        <label className="bolder">{i18n.translate("description")}</label> 
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="text" className="form-control inputStandard" value={product.description} 
                        onChange={(e) => changeValueProduct('productDescription',e)}/>   
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="bolder">{i18n.translate("storage_price")}</label> 
                        <span className="requiredFieldsIcon">(*)</span>
                        <input type="number" className="form-control inputStandard" value={product.price} 
                        onChange={(e) => changeValueProduct('price',e)}/>
                    </Col>
                    <Col>
                        <label className="bolder">{i18n.translate("unit_of_measurement")}</label>
                        <span className="requiredFieldsIcon">(*)</span>
                        <Select onChange={handleChangeUnitOfMeasurement} menuShouldScrollIntoView={false} options={this.state.unitOfMeasurement} 
                        placeholder={i18n.translate("default_select")} value={this.state.unitOfMeasurementSelected} 
                        menuPortalTarget={document.body} // Renderiza el menú en el body
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }), // Asegura que el menú esté encima
                        }}/>
                        <input type="hidden" id="inputUnitOfMeasurement" value={this.state.unitOfMeasurementSelected.value}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="bolder">{i18n.translate("default_tax")}</label> 
                        <span className="requiredFieldsIcon">(*)</span>
                        <div>
                            <select className="form-select selectStandard" 
                            onChange={(e) => changeValueProduct('tax',e)} id="selectTaxes">
                                {this.state.taxes.map((tax) => {
                                    return(
                                        <option key={tax.id} value={tax.replicaId} selected={product.tax.value == tax.value ? 'selected' : ''}>{tax.value}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }  
    
    updateProduct = () => {
        let sifenFields = {};
        sifenFields.id = this.state.currentProduct.fePyProductFields.id;
        sifenFields.replicaId = this.state.currentProduct.fePyProductFields.replicaId;
        sifenFields.vPoductReplicaId = this.state.currentProduct.replicaId;
        sifenFields.productType = document.querySelector("#selectTypeProduct").value;
        sifenFields.currency = "PYG";
        sifenFields.codeUnitOfMeasurement = this.state.unitOfMeasurementSelected.value;
        sifenFields.symbolUnitOfMeasurement = this.state.unitOfMeasurementSelected.code;
        sifenFields.unitOfMeasurement = this.state.unitOfMeasurementSelected.unit;

        let productToUpdate = {
            "code": this.state.currentProduct.code,
            "description": this.state.currentProduct.description,
            "id": this.state.currentProduct.id,
            "price": this.state.currentProduct.price,
            "replicaId": this.state.currentProduct.replicaId,
            "taxReplicaId": document.querySelector("#selectTaxes").value,
        } 

        productToUpdate.sifenFields = sifenFields;

        if(!productToUpdate.taxReplicaId || !productToUpdate.sifenFields.codeUnitOfMeasurement || 
            !productToUpdate.code || !productToUpdate.description || 
            !productToUpdate.price || !productToUpdate.taxReplicaId){
                toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
        }else{
            this.setState({
                spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
                independent={document.querySelector("#independent").value}/>,
                modalContent2: ""
            })
            DefaultAjax('').put("/fePyProduct",productToUpdate)
            .then((res) => {
                if(res){
                    this.setState({spinner:""})
                    let result = JSON.parse(res.data);
                    this.handleAjaxResponseUpdateCreateProduct(result,i18n.translate("product_updated_successfully"))
                } 
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            })
        } 
    } 

    handleAjaxResponseUpdateCreateProduct = (result,message) => {
        if(result.status == "0" || result.status == 0){
            this.setState({
                unitOfMeasurementSelected: "",
                modalContent:"",
            });
            toastFunction(message,"success");
            this.getAllProducts();
        }else{
            this.setState({
                modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
            });
        }
    } 

    getAllUnitOfMeasurement = (product,isUpdateValues,isCreate) => {
        if(this.state.unitOfMeasurement.length == 0){
            this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>})
            DefaultAjax('').get("/fePyUnitOfMeasurement")
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    let unitOfMeasurement = [];
                    let label = ""; 
                    for(let r of result){
                        label = r.description + "(" + r.symbol + ")";
                        unitOfMeasurement.push({"value":r.code,"label":label,code:r.symbol,unit:r.description});
                    }
                    this.setState({
                        unitOfMeasurement:unitOfMeasurement,
                        spinner:""
                    })
                    setTimeout(()=> {
                        this.getAllTaxes(product,isUpdateValues,isCreate);
                    }, 10);
                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        }else{
            this.getAllTaxes(product,isUpdateValues,isCreate);
        }  
    } 
    
    createProduct = (product,isUpdateValues,isCreate) => {
        this.getAllUnitOfMeasurement(product,isUpdateValues,isCreate);
    } 

    showCreateProduct = (product,isUpdateValues,isCreate) => {
        if(!product){
            product = {
                id:"",
                code: "",
                description: "",
                price: "",
                tax: "",
                taxReplicaId: "",
                fePyProductFields: {},

            }
        }  

        this.setState({
            currentProduct:product,
            modalContent:"",
            isCreate: isCreate
        });
        let modalContent;
        modalContent = this.showProductContent(product,isCreate);
        if(isUpdateValues){
            this.modalProductContent(modalContent,isCreate);
        }else{
            setTimeout(()=> {
                this.modalProductContent(modalContent,isCreate);
            }, 10);
        }
    }
    
    executeShowProduct = (product) => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
        independent={document.querySelector("#independent").value}/>})
        DefaultAjax('').get("/fePyProduct?id="+product.id)
        .then((res) => {
            if(res){
                this.setState({spinner:""});
                let result = JSON.parse(res.data); 
                let option = {"code":result.fePyProductFields.symbolUnitOfMeasurement,
                "label":result.fePyProductFields.unitOfMeasurement+"("+result.fePyProductFields.symbolUnitOfMeasurement+")",
                "unit":result.fePyProductFields.unitOfMeasurement,"value":result.fePyProductFields.codeUnitOfMeasurement} 
                this.setState({unitOfMeasurementSelected:option});
                setTimeout(()=> { 
                    this.createProduct(result,false,false);
                }, 10);
            } 
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        })
    } 

    modalProductContent = (modalContent,isCreate) => {
        let buttonText = i18n.translate("update");
        let functionToExecute = this.updateProduct;
        if(isCreate){
            buttonText = i18n.translate("save");
            functionToExecute = this.saveProduct;
        } 
        let buttons = []; 
        buttons.push({text: buttonText, function: functionToExecute});
        if(!isCreate){
            buttons.push({text: i18n.translate("delete"), function: this.deleteProduct})
        } 
        buttons.push({text: i18n.translate("default_close"), function: this.setProductsVariables});
        this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_product")}
        content={modalContent} buttons={buttons} size='lg' closeFunction={this.setProductsVariables}/>})
    } 

    setProductsVariables = () => {
        this.setState({
            unitOfMeasurementSelected: "",
            modalContent:"",
        })
    } 

    deleteProduct = () => {
        let modalContent = i18n.translate("are_you_sure_you_want_to_delete_this_product");
        let buttons = [{text: i18n.translate("default_confirm"), function: this.executeDeleteProduct},
        {text: i18n.translate("cancel"), function: ""}
        ];
        this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("default_confirm")}
        content={modalContent} buttons={buttons} />})
    }
    
    executeDeleteProduct = () => {
        const productId = encodeURIComponent(this.state.currentProduct.id);
        const productReplicaId = encodeURIComponent(this.state.currentProduct.replicaId);
        const sifenFieldId = encodeURIComponent(this.state.currentProduct.fePyProductFields.id);
        const sifenFieldReplicaId = encodeURIComponent(this.state.currentProduct.fePyProductFields.replicaId);
        
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
        independent={document.querySelector("#independent").value}/>,modalContent2:""})
        DefaultAjax('').delete(`/fePyProduct?productId=${productId}&productReplicaId=${productReplicaId}&sifenFieldId=${sifenFieldId}&sifenFieldReplicaId=${sifenFieldReplicaId}`)
        .then((res) => {
            if(res){
                this.setState({spinner:""});
                let result = JSON.parse(res.data);
                if(result.status == "0" || result.status == 0){
                    this.setState({
                        unitOfMeasurementSelected: "",
                        modalContent:"",
                        modalContent2:"",
                    });
                    toastFunction(i18n.translate("product_removed_successfully"),"success");
                    this.getAllProducts();
                }else{
                    this.setState({
                        modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
                    });
                }   
            } 
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        })
    } 

    saveProduct = () => {

        let sifenFields = {};
        sifenFields.vPoductReplicaId = "";
        sifenFields.productType = document.querySelector("#selectTypeProduct").value;
        sifenFields.currency = "PYG";
        sifenFields.codeUnitOfMeasurement = this.state.unitOfMeasurementSelected.value;
        sifenFields.symbolUnitOfMeasurement = this.state.unitOfMeasurementSelected.code;
        sifenFields.unitOfMeasurement = this.state.unitOfMeasurementSelected.unit;

        this.state.currentProduct.taxReplicaId = document.querySelector("#selectTaxes").value;
        this.state.currentProduct.sifenFields = sifenFields;

        if(!this.state.currentProduct.taxReplicaId || !this.state.unitOfMeasurementSelected || 
            !this.state.currentProduct.code || !this.state.currentProduct.description || 
            !this.state.currentProduct.price || !this.state.currentProduct.taxReplicaId){
                toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
        }else{
            this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,modalContent2:""})
            DefaultAjax('').post("/fePyProduct",this.state.currentProduct)
            .then((res) => {
                if(res){
                    this.setState({spinner:""});
                    let result = JSON.parse(res.data);
                    this.handleAjaxResponseUpdateCreateProduct(result,i18n.translate("product_created_successfully"))
                } 
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            })
        }  
    }  

    render(){
        return (
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row style={{ width: '60%'}} className="contentInput">
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("default_code")}</label>
                            <input type="text" className="form-control inputStandard" id="productCode"/>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("description")}</label>
                            <input type="text" className="form-control inputStandard" id="productDescription"/>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "22px",width: '40%' }}>
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllProducts(false)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_search")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllProducts(true)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_show_all")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.createProduct('',false,true)}>
                                    <FaIcons.FaPlus />
                                    <span>{i18n.translate("create_product")}</span>
                                </button>
                            </div> 
                    </div>
                </nav>
                <br/>
                <div>
                    {this.state.spinner}
                    <div id="divTitle">
                        <span className="title">{i18n.translate("default_products")}</span>
                        {this.state.paginationHtml}
                    </div>
                    {' '} 
                    <div style={{paddingTop:'40px'}}>
                        <Row style={{padding: '0px 10px 0px 10px'}} className='bodyContent' id='bodyContent'>
                            {this.state.contentPagination}
                        </Row>
                    </div>
                </div>
            </>
        )
    } 
}

export default Products;