import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import i18n from "i18n-react";
import { redirectToPlatform } from "./mainFunctions"; 
const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 18px;

&:hover {
	background: #252831;
	border-left: 4px solid green;
	cursor: pointer;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background: #252831;
height: 60px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;

&:hover {
	background: green;
	cursor: pointer;
}
`;

const Menu = ({ item,platformUrl,pathRoot}) => {
const [subnav, setSubnav] = useState(false);

const showSubnav = () => setSubnav(!subnav);

const handleClick = event => {
	if(event.target.id == "tutorialVideos"){
		if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
			window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
		}
	} 
	let token = document.querySelector("#token").value;

	let goToPlatform = false;

	let html = "";

	if(event.target.id === "visitsPlatform" || event.target.id === "myAccountPlatform" || 
		event.target.id === "opportunitiesPlatform" || event.target.id === "ordersPlatform" || 
		event.target.id === "professionalVisitsPlatform" || event.target.id === "professionalManagementPlatform" ||
		event.target.id === "geofencesPlatform" || event.target.id === "routesPlatform" || 
		(event.target.id === "administrationPlatform" && document.querySelector("#currentModule").value != 'administrationMzate') ||
		(event.target.id === "controlPanelPlatform" && document.querySelector("#currentModule").value != 'administrationMzate')  
	){
		goToPlatform = true;
		html = "dashboardMain.html";
	}else if(event.target.id === "supportPlatform" || (event.target.id === "controlPanelPlatform" && document.querySelector("#currentModule").value === 'administrationMzate')){
		goToPlatform = true;
		html = "dashboard.html";
	} 

	if(goToPlatform){
		redirectToPlatform(platformUrl,pathRoot,token,html,event.target.id)
	} 

	let li = document.querySelectorAll(".fixed-top a");
	let arrayLi = Array.from(li);
	arrayLi.forEach((li) => {
		if(li.classList.contains("active")){
			li.classList.remove("active");
		} 
	})
	event.currentTarget.classList.add('active');

};

return (
	<>
		<SidebarLink to={item.path} onClick={handleClick} id={item.id} className={item.className}>
			<div id={item.id}>
			<SidebarLabel id={item.id}>{i18n.translate(item.title)}</SidebarLabel>
			</div>
			<div>
			{item.subNav && subnav
				? item.iconOpened
				: item.subNav
				? item.iconClosed
				: null}
			</div>
		</SidebarLink>
	</>
);
};

export default Menu;
