import React from "react";
import DefaultSpinner from "../../components/DefaultSpinner";
import { modalForbidden } from "../../components/DefaultAjax";
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {stopPropagation} from "../../components/SidebarFunctions";
import { downloadInvoicePdf, billingContentShow } from "./InvoiceFunctions";
import PropTypes from 'prop-types';

export const url = process.env.REACT_APP_API_URL;
class InvoiceShow extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalContent:"",
            modalContent2:"",
            instanceType:props.instanceType,
            instanceTypeDescription:"",
            independent:props.independent,
            castMzate:"",
            spinner:"",
            currentBillingShow: "",
            invoiceId: props.invoiceId,
            token: props.token,
            isFromInvoicePanel: props.isFromInvoicePanel,
            sifenAndStatusFields: props.sifenAndStatusFields
        } 
    } 

    componentDidMount(){
        window.addEventListener('message', this.handleMessage);
        let token;
        if(!this.state.token){
            this.setState({
                token: document.querySelector("#token").value
            });
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        }
        this.getInvoiceToShow();
    } 

    handleMessage = (event) => {
        let platformUrl = document.querySelector("#platformUrl").value;
        if (platformUrl.endsWith('/')) {
            platformUrl = platformUrl.slice(0, -1); 
        }

        if (event.origin !== platformUrl) return;
        
        let type = event.data.type;
        if (type === 'DOWNLOAD_BILLING') {
            this.downloadBillingPdf(event);
        }
    };

    getInvoiceToShow = () => {
        this.setState((prevState) => ({
            spinner:<DefaultSpinner instanceType={prevState.instanceType} independent={prevState.independent}/>
        }))
        
        axios({
            method: 'GET',
            url: url+"fePyInvoice/show?id="+this.state.invoiceId,
            headers: {'Authorization': document.querySelector("#token").value},
        }).then(response=>{  
            this.setState({
                spinner:""
            });      
            this.showElectronicBilling(response.data)                                                                                                                                                                                                                                              
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    showElectronicBilling = (billing) => {
        this.setState({currentBillingShow:billing});
        this.setState({modalContent:""});
        let modalContent;
        modalContent = billingContentShow(billing,this.state.isFromInvoicePanel,this.state.sifenAndStatusFields);
        this.setState({modalContent:modalContent})
    }

    downloadBillingPdf = (e,billing) => {
        stopPropagation(e);

        if(!billing){
            billing = this.state.currentBillingShow
        } 

        downloadInvoicePdf(billing)
        .then(success => {
            if (success) {
                this.setState({spinner:""})
            }
        })
        .catch(error => {
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    render(){
        return(
            <div className="invoiceShowContainer">
                {this.state.modalContent}
                {this.state.modalContent2}
                {this.state.spinner}
            </div>
        )
    } 
} 

InvoiceShow.propTypes = {
    token: PropTypes.string.isRequired, 
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired,
    invoiceId: PropTypes.string.isRequired,
    isFromInvoicePanel: PropTypes.bool.isRequired
};

export default InvoiceShow;
