import '../App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { size } from 'lodash';

const DefaultModal = ({ show, title, content, buttons, modalClass, closeFunction, size }) => {
    const [isOpenModal, setIsOpenModal] = useState(show);
    useEffect(()=>{
		setIsOpenModal(true);
	}, [])
   
    return (
        <Modal isOpen={isOpenModal} 
        modalTransition={{ timeout: 0 }} backdropTransition={{ timeout: 0 }}
        size={size} >
            <ModalHeader className='modalHeader'>
                {title} 
                <button className='close' data-dismiss="close" onClick={closeFunction != undefined && closeFunction != '' ? closeFunction : ()=>setIsOpenModal(false)}>x</button>
            </ModalHeader>
            <ModalBody className={'modalBody '+modalClass}>
                {content} 
            </ModalBody>
            <ModalFooter>
                {buttons.map((b,i) =>{
                    const buttonClass = b.class || '';
                    return (
                        <button key={i} className={i != 0 ? 'buttonMzateSecondary '+buttonClass : 'buttonMzatePrimary '+buttonClass}
                        onClick={b.function != '' ? b.function : ()=>setIsOpenModal(false)}>{b.text}</button> 
                    )
                })} 
            </ModalFooter>
        </Modal>
    ) 
};

DefaultModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            function: PropTypes.func,
            class: PropTypes.string.isRequired
        })
    ).isRequired,
    modalClass: PropTypes.string.isRequired,
    closeFunction: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired 
};
DefaultModal.defaultProps = {
    modalClass: '',
    closeFunction: null,
    size: 'md'
}; 
    
export default DefaultModal;

