import '../../App.css';
import React from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {  Row, Col } from 'reactstrap';
import i18n from "i18n-react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DefaultModal from '../../components/DefaultModal';
import { toastFunction } from '../../components/mainFunctions'; 
import * as FaIcons from "react-icons/fa";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {stopPropagation} from "../../components/SidebarFunctions";
import DefaultPagination from '../../components/DefaultPagination';
import DefaultSpinner from '../../components/DefaultSpinner';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";

export const url = process.env.REACT_APP_API_URL;

class IndexStorage extends React.Component {
    constructor(props) {
		super(props);
        this.state={
            modalContent:"",
            modalContent2:"",
            startDate: moment().add(-12, 'hour'),
            endDate: moment().add(12, 'hour'),
            multimediaList: [],
            multimediaMap: {},
            multimediaChecked: [],
            multimediaCheckedLength: 0,
            storageUsed: 0,
            totalStorage: 0,
            percentageUsedStorage: 0,
            classPercentageStorage:"",
            checkAll:false,
            offset: 0,
            perPage: 5,
            instanceType: props.instanceType,
            independent: props.independent,
            currentPage: 0,
            paginationHtml:"",
            extraStorageList:[],
            currentExtraStorageId:"",
            currentExtraStorageSize:"",
            newExtraStorageId:"",
            spinner:"",
            token: props.token,
            dataToDelete:[],
            language: props.language 
        };
	}

    extraStorageIndex = () => {
        this.showHideManageStorage("","none");
        let dto = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        } 
        document.querySelector("#rowOptionsForImage").style.display = "flex";
        let token;
        if(!this.state.token){
            this.setState({token: document.querySelector("#token").value});
            token = document.querySelector("#token").value;
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        } 
        DefaultAjax('').get("multimedia/getAllMultimedia?paramsJSON="+encodeURI(JSON.stringify(dto)))
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    multimediaList:result,
                    spinner:""
                });
                const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                
                const contentPagination = slice.map(mList => <React.Fragment key={mList.id}>
                    <Col sm={12} md={4} lg={4} xl={4} key={mList.id} style={{ marginTop: '10px'}}>
                        <div style={{textAlign:'center'}} className='imgThumbnail' id={'imgThumbnail-'+mList.id}>
                            <label style={{float: 'right'}}>{i18n.translate("creation_date")} : <b>{mList.creationDate}</b></label>
                            <div className='optionImgThumbnailSelect' onClick={(e)=>this.CheckImage(mList,e)}>
                                <FaIcons.FaCheck/>
                            </div>
                                <img height='100%' style={{maxHeight:'200px'}} className='img-thumbnail' src={"data:image/png;base64,"+mList.multimediaFile}/>
                            <div className='optionImgThumbnail'>
                                <div className='divOptionImgThumbnail' onClick={(e)=>this.downloadDeleteViewImage(mList,e,"download")}>
                                    <FaIcons.FaDownload size={15}/>
                                </div>
                                <div className='divOptionImgThumbnail' onClick={(e)=>this.downloadDeleteViewImage(mList,e,"delete")}>
                                    <FaIcons.FaTrash size={15}/>
                                </div>
                                <div className='divOptionImgThumbnail' onClick={(e)=>this.downloadDeleteViewImage(mList,e,"view")}>
                                    <FaIcons.FaArrowsAlt size={15}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </React.Fragment>)

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),
                    
                    contentPagination
                })
                setTimeout(()=> {
                    this.setState({
                        paginationHtml:<DefaultPagination data={result} handlePageClick={this.handlePageClick} perPage={this.state.perPage}
                        />})
                }, 100);
                if(document.querySelector("#inputCheckedAll").checked){
                    setTimeout(()=> {
                        this.CheckAllImage();
                    }, 100);
                }else{
                    this.DesCheckImage("","cancel");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    } 

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.extraStorageIndex()
        });

    };

    getStorageSize = () => {
        DefaultAjax('').get("extraStoragePack/getStorageData")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result.storageUsed){
                    this.setState({storageUsed:result.storageUsed});
                        this.setState({totalStorage: result.totalStorage});
                        this.setState({percentageUsedStorage:(Math.round(result.storageUsed)*100)/result.totalStorage});
                    if((Math.round(result.storageUsed)*100)/result.totalStorage >= 50 && (Math.round(result.storageUsed)*100)/result.totalStorage < 70){
                        this.setState({classPercentageStorage:"warning"})
                    }else if((Math.round(result.storageUsed)*100)/result.totalStorage >= 70){
                        this.setState({classPercentageStorage:"danger"})
                    }
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    } 

    componentDidMount = () => {
        this.setState({
            spinner:<DefaultSpinner instanceType={this.state.instanceType} 
            independent={this.state.independent}/>
        });
        this.extraStorageIndex();
        this.getStorageSize();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbarT").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 90;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        bodyContent.style.height = altura + "px";
    }

    CheckImage = (mList, e) => {
        stopPropagation(e);
        let listCounter = this.state.multimediaCheckedLength;
        if(document.querySelector("#imgThumbnail-"+mList.id+" .optionImgThumbnailSelect svg").classList.contains("iconCheckedImgThumbnail")){
            listCounter = listCounter - 1;
            this.setState({multimediaCheckedLength:listCounter});
            document.querySelector("#imgThumbnail-"+mList.id).style.boxShadow = '';
            document.querySelector("#imgThumbnail-"+mList.id+" .optionImgThumbnailSelect svg").classList.remove("iconCheckedImgThumbnail");
            if(this.state.checkAll === false){
                this.DesCheckImage(listCounter,"",mList);
            }else{
                document.querySelector("#inputCheckedAll").checked = false; 
                if(listCounter === 0){
                    this.DesCheckImage(0,"cancel")
                }
            }
        }else{
            listCounter = listCounter + 1;
            this.setState({multimediaCheckedLength:listCounter});
            document.querySelector("#imgThumbnail-"+mList.id).style.boxShadow = 'rgb(0 140 186) 0px 0px 3px 2px';
            document.querySelector("#imgThumbnail-"+mList.id+" .optionImgThumbnailSelect svg").classList.add("iconCheckedImgThumbnail");
            this.DesCheckImage(listCounter,"");
            if(listCounter === this.state.multimediaList.length){
                document.querySelector("#inputCheckedAll").checked = true;
            }
        }
    }

    DesCheckImage = (listCounter,action,mList) => {
        let allImgChecked =document.querySelectorAll(".optionImgThumbnailSelect");
        let rowOptionsForImage;

        const descheckAllImage = (imgChecked) => {
            imgChecked.style.visibility = "";
            imgChecked.parentNode.childNodes[1].classList.remove("optionImgThumbnailNoHover");
            imgChecked.parentNode.childNodes[1].classList.add("optionImgThumbnail");
            imgChecked.parentNode.childNodes[1].lastChild.style.visibility = 'hidden';
            imgChecked.parentNode.childNodes[1].childNodes[0].style.visibility = "";
            rowOptionsForImage = document.querySelectorAll(".rowOptionsForImage");
            for (let element of rowOptionsForImage) {
                element.style.display = "none";
            }
        } 

        const checkImages = (imgChecked) => {
            imgChecked.style.visibility = "inherit";
            imgChecked.parentNode.childNodes[1].classList.remove("optionImgThumbnail");
            imgChecked.parentNode.childNodes[1].classList.add("optionImgThumbnailNoHover");
            imgChecked.parentNode.childNodes[1].lastChild.style.visibility = 'initial';
            rowOptionsForImage = document.querySelectorAll(".rowOptionsForImage");
            for (let element of rowOptionsForImage) {
                element.style.display = "";
            }
        } 

        for (let imgChecked of allImgChecked) {
            if (listCounter === 0 || action === "cancel") {
                if (action === "cancel") {
                    this.setState({ multimediaCheckedLength: 0 });
                    imgChecked.firstChild.classList.remove("iconCheckedImgThumbnail");
                    imgChecked.parentNode.style.boxShadow = "";
                }
                descheckAllImage(imgChecked)
            } else {
                checkImages(imgChecked)    
            }
        }
        if(listCounter === 0 || action === "cancel"){
            document.querySelector("#inputCheckedAll").checked = false
        } 
    } 

    CheckAllImage = () => {
        let multimediaList;
        let action = "";
        if(document.querySelector("#inputCheckedAll").checked){
            multimediaList = this.state.contentPagination;
            let imgThumbnail = document.querySelectorAll(".imgThumbnail");
            for(let img of imgThumbnail){
                img.style.boxShadow = 'rgb(0 140 186) 0px 0px 3px 2px';
                img.childNodes[1].childNodes[0].classList.add("iconCheckedImgThumbnail");
            } 
            this.setState({checkAll:true}); 
        }else{
            multimediaList = []; 
            action = "cancel";
            this.setState({checkAll:false});
        } 
        this.setState({multimediaCheckedLength:multimediaList.length});
        this.DesCheckImage(multimediaList,action);
    } 

    downloadDeleteViewImage = (map, e, action) => {
        stopPropagation(e);
    
        const showModal = (title, content, buttons) => {
            this.setState({
                modalContent: <DefaultModal show={true} title={title} content={content} buttons={buttons} />
            });
        };
    
        const viewImage = (map) => {
            const modalContent = (
                <div className='imgThumbnail' style={{textAlign:'center'}}>
                    <img alt='' className='img-thumbnail' src={`data:image/png;base64,${map.multimediaFile}`} />
                </div>
            );
            setTimeout(() => {
                const buttons = [{ text: i18n.translate("cancel"), function: "" }];
                showModal(map.fileName, modalContent, buttons);
            }, 10);
        };
    
        const downloadAll = () => {
            const modalContent = (
                <div>{i18n.translate("confirm_download_all", { cant: this.state.multimediaList.length })}</div>
            );
            setTimeout(() => {
                const buttons = [
                    { text: i18n.translate("default_confirm"), function: this.executeDownloadAllMultimedia },
                    { text: i18n.translate("cancel"), function: "" }
                ];
                showModal(i18n.translate("default_confirm"), modalContent, buttons);
            }, 10);
        };
    
        const handleResponseError = (error) => {
            this.setState({
                modalContent: "",
                spinner: "",
                component: error.message
            });
            if (error.response && error.response.status === 403) {
                setTimeout(() => { 
                    this.setState({ modalContent: modalForbidden() });
                }, 10);
            }
        };
    
        const downloadImages = (data) => {
            axios({
                method: 'GET',
                url: `${url}multimedia/downloadSelected?multimediaIdListJSON=${encodeURI(JSON.stringify(data))}`,
                headers: { 'Authorization': document.querySelector("#token").value },
                responseType: 'blob',
            }).then(response => {
                this.handleResponseDownloadFile(response);
            }).catch(handleResponseError);
        };
    
        const deleteImages = (data) => {
            this.setState({ dataToDelete: data });
            const modalContent = <div>{i18n.translate("confirm_delete_multimedia")}</div>;
            setTimeout(() => {
                const buttons = [
                    { text: i18n.translate("default_confirm"), function: this.executeDeleteMultimedia },
                    { text: i18n.translate("cancel"), function: "" }
                ];
                showModal(i18n.translate("default_confirm"), modalContent, buttons);
            }, 10);
        };
    
        const getSelectedIds = () => {
            let data = [];
            if (map) {
                data.push(map.id);
            } else {
                const idsForDeleteDownload = document.querySelectorAll(".imgThumbnail");
                idsForDeleteDownload.forEach(element => {
                    if (element.style.boxShadow !== "") {
                        const idToSplited = element.id.split("imgThumbnail-");
                        data.push(idToSplited[1]);
                    }
                });
            }
            return data;
        };
    
        switch (action) {
            case "cancel":
                this.DesCheckImage("", action);
                break;
            case "view":
                viewImage(map);
                break;
            case "downloadAll":
                downloadAll();
                break;
            case "download":
                downloadImages(getSelectedIds());
                break;
            case "delete":
                deleteImages(getSelectedIds());
                break;
            default:
                break;
        }
    };

    handleResponseDownloadFile = (response) => {
        if(response.status === 200 || response.status === "200"){
                            
            const FileDownload = require('js-file-download');

            toastFunction(i18n.translate("download_started"),"success");
            this.DesCheckImage("","cancel");

            FileDownload(response.data, 'descarga.zip');

        }else{
            toastFunction(i18n.translate("an_error_has_ocurred"),"error");        
        }
    } 

    executeDownloadAllMultimedia = () => {
        axios({
            method: 'GET',
            url: url+"multimedia/downloadAll",
            headers: {'Authorization': document.querySelector("#token").value},
            responseType: 'blob',
        })
        .then(response=>{        
            this.handleResponseDownloadFile(response);                                                                                                                                                                                                                                                 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    executeDeleteMultimedia = () => {
        axios({
            method: 'DELETE',
            url: url+"multimedia/deleteSelected?multimediaIdListJSON="+encodeURI(JSON.stringify(this.state.dataToDelete)),
            headers: {'Authorization': document.querySelector("#token").value},
        }).then(response=>{        
            if(response.status === 200 || response.status == "200"){
                this.setState({modalContent:""});
                this.extraStorageIndex();
                this.getStorageSize();
                toastFunction(i18n.translate("image_deleted"),"success");
                this.DesCheckImage("","cancel");
            }else{
                toastFunction(i18n.translate("an_error_has_ocurred"),"error");        
            }                                                                                                                                                                                                                                                 
        })
    } 

    manageStorage = () => {
        document.querySelector("#rowOptionsForImage").style.display = "none";
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            paginationHtml:""
        });
        this.showHideManageStorage("none","");
        DefaultAjax(url,'').get("extraStoragePack/extraStoragePacks?isFromSupport=false")
        .then((res) => {
            if(res){
                let extraStorageList = JSON.parse(res.data);
                this.setState({extraStorageList: extraStorageList});

                DefaultAjax(url,'').get("extraStoragePack/getServiceExtraStoragePackId")
                .then((res) => {
                    if(res){
                        if(res.data !== "" && res.data !== undefined && res.data > 0 && res.data !== "0"){
                            extraStorageList.forEach((eL) =>{
                                if(eL.id === parseInt(res.data)){
                                    this.setState({currentExtraStorageSize:eL.storageSize})
                                } 
                            })
                            this.setState({
                                currentExtraStorageId:parseInt(res.data),
                            });
                            document.querySelector("#planList-"+res.data).classList.add("storageListContainer");
                            document.querySelector("#planListIcon-"+res.data).style.display = "";
                        } 
                        this.setState({
                            spinner:""
                        });
                    }
                }).catch((error) =>{
                    this.setState({
                        modalContent:"",
                        spinner:"",
                        component:error.message
                    });
                    if (error.response) {
                        if(error.response.status === 403){
                            setTimeout(()=> { 
                                this.setState({modalContent:modalForbidden()});
                            }, 10);
                        } 
                    }
                });
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    showHideManageStorage = (display1,display2) => {
        let rowIndexStorage = document.querySelectorAll(".rowIndexStorage");
        let rowIndexStorageIndex = Array.from(rowIndexStorage);
        let rowIndexStorageIndexItem = rowIndexStorageIndex.map((s) => s.style.display = display1);
        let rowManageStorage = document.querySelectorAll(".rowManageStorage");
        let rowManageStorageIndex = Array.from(rowManageStorage);
        let rowManageStorageItem = rowManageStorageIndex.map((s) => s.style.display = display2);
    } 

    selectNewPlan = (e,plan) => {
        if(plan.id === this.state.currentExtraStorageId){
            if(document.querySelector("#colSuspendPlan").style.display === "none"){
                document.querySelector("#colSuspendPlan").style.display = "";
                document.querySelector("#colConfirmNewPlan").style.display = "none";
                e.target.style.background = "#8DBF2633";
            }else{
                document.querySelector("#colSuspendPlan").style.display = "none";
                e.target.style.background = "";
            }
            if(this.state.newExtraStorageId){
                document.querySelector("#planList-"+this.state.newExtraStorageId).style.background = ""; 
                document.querySelector("#planList-"+this.state.newExtraStorageId).classList.remove("storageListContainer");
            }
        }else if(plan.storageSize < this.state.currentExtraStorageSize){
            toastFunction(i18n.translate("the_plan_to_hire_must_be_greater_than_the_current_one"),"warning");
        }else if(e.target.classList.contains("storageListContainer")){
            document.querySelector("#colConfirmNewPlan").style.display = "none";
            e.target.classList.remove("storageListContainer");
            e.target.style.background = "";
        }else{
            document.querySelector("#colConfirmNewPlan").style.display = "";
            let plansDefaultList = document.querySelectorAll(".plansList");
            for(let element of plansDefaultList ){
                if(element.id !== "planList-"+this.state.currentExtraStorageId){
                    element.classList.remove("storageListContainer");
                }
                element.style.background = "";
            } 
            e.target.classList.add("storageListContainer");
            e.target.style.background = "#8DBF2633";
            this.setState({newExtraStorageId:plan.id});
            document.querySelector("#colSuspendPlan").style.display = "none";
        } 
    } 

    getNewExtraStorage = () => {
        this.setState({modalContent:""});
        let modalContent;
        modalContent = <div>{i18n.translate("confirm_activate_plan")}</div>;
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmNewExtraStorage},
            {text: i18n.translate("cancel"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_confirm")}
            content={modalContent} buttons={buttons}/>})
        }, 10);
    } 

    confirmNewExtraStorage = () => {
        DefaultAjax(url,'').post("extraStoragePack/addDetailTemp?extraStoragePackId="+this.state.newExtraStorageId)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result === "0" || result === 0){
                    this.setState({modalContent:""});
                    document.querySelector("#planList-"+this.state.newExtraStorageId).classList.remove("storageListContainer");
                    document.querySelector("#planList-"+this.state.newExtraStorageId).style.background = "";
                    this.setState({newExtraStorageId:0});
                    this.manageStorage();
                    this.getStorageSize();

                    toastFunction(i18n.translate("plan_activated"),"success");
                    let token = document.querySelector("#token").value;
                    window.location.href = document.querySelector("#platformUrl").value+document.querySelector("#pathRoot").value+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=payExtraStorageFromAppWeb";

                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    suspendExtraStorage = () => {
        this.setState({modalContent:""});
        let modalContent;
        modalContent = <div>{i18n.translate("confirm_deactivate_plan")}</div>;
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmSuspendExtraStorage},
            {text: i18n.translate("cancel"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_confirm")}
            content={modalContent} buttons={buttons}/>})
        }, 10);
    } 

    confirmSuspendExtraStorage = () => {
        DefaultAjax(url,'').get("extraStoragePack/removeDetail?serviceExtraStoragePackId="+this.state.currentExtraStorageId)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result === 0){
                    this.setState({modalContent:""});
                    document.querySelector("#planList-"+this.state.currentExtraStorageId).classList.remove("storageListContainer");
                    document.querySelector("#planList-"+this.state.currentExtraStorageId).style.background = "";
                    this.setState({currentExtraStorageId:0});
                    this.manageStorage();
                    this.getStorageSize();
                    toastFunction(i18n.translate("plan_deactivated"),"success");
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    } 

    handleCallback = (start, end) => {
        this.setState({startDate: start});
        this.setState({endDate:end});
    }

    render(){
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
        const chartStyle = {
            height: '80px',
            width: '180px'
        }
        
        return(
            <>
                {this.state.modalContent} 
                {this.state.modalContent2} 
                <br/>
                <nav className='navbarT'>
                    <Row style={{marginBottom:'10px',display:'none'}} className='rowManageStorage'>
                        <Col>
                            <button className='buttonMzateSecondary' onClick={() => this.extraStorageIndex()}>
                                <FaIcons.FaLongArrowAltLeft />
                                <span>{i18n.translate("default_back")}</span>
                            </button>
                        </Col>
                        <Col id='colConfirmNewPlan' style={{display:'none'}}>
                            <button className='buttonMzatePrimary' onClick={() => this.getNewExtraStorage()}>
                                <FaIcons.FaCheck />
                                <span>{i18n.translate("default_activate")}</span>
                            </button>
                        </Col>
                        <Col id='colSuspendPlan' style={{display:'none'}}>
                            <button className='buttonMzatePrimary' onClick={() => this.suspendExtraStorage()}>
                                <FaIcons.FaTimes />
                                <span>{i18n.translate("default_suspend")}</span>
                            </button>
                        </Col>
                    </Row>
                    <Row className="rowIndexStorage">
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <DateRangePicker 
                            initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate,
                                locale : {
                                    applyLabel: this.state.language === 'es' ? 'Aplicar' : 'Apply',
                                    cancelLabel: this.state.language === 'es' ? 'Cancelar' : 'Cancel',
                                }
                             }}
                            onCallback={this.handleCallback}>
                                <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer",display:"inline-block",width:'50%'}} className="form-control inputStandard" />
                            </DateRangePicker>
                            {' '}
                            <button className='buttonMzateSecondary' onClick={()=>this.extraStorageIndex()}>{i18n.translate("default_search")}</button>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4} style={{textAlign:'-webkit-center'}}>
                                <FaIcons.FaCloud size={20}/>
                                    <span> {this.state.totalStorage > 1024 && 
                                            <>
                                                {(this.state.storageUsed/1024).toFixed(2)} Gb {i18n.translate("default_of")} {this.state.totalStorage/1024} Gb
                                            </>
                                            } 
                                           {this.state.storageUsed < 1024 && 
                                                <>
                                                    {(this.state.storageUsed).toFixed(2)} Mb {i18n.translate("default_of")} {this.state.totalStorage} Mb
                                                </>
                                            }        
                                    </span>
                                <ProgressBar animated now={this.state.percentageUsedStorage} variant={this.state.classPercentageStorage} 
                                label={`${this.state.percentageUsedStorage}%`}/>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <button style={{float: 'right'}} className='buttonMzateSecondary' onClick={()=>this.manageStorage()}>{i18n.translate("manage_storage")}</button>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Row style={{marginBottom:'10px'}} id="rowOptionsForImage">
                        <Col>
                            <label onClick={() => this.CheckAllImage()}>
                                <input id='inputCheckedAll' type='checkbox'/>
                                <span style={{marginLeft: '4px'}}>{i18n.translate("default_all")}</span>
                            </label>
                            <label style={{marginLeft:'10px',fontSize:'15px'}}>
                                {this.state.multimediaCheckedLength} {i18n.translate("image_selected")} 
                            </label>
                        </Col>
                        <Col>
                            <label onClick={(e)=>this.downloadDeleteViewImage("",e,"downloadAll")} style={{cursor:'pointer'}}>
                                {i18n.translate("default_download_all")} 
                                <FaIcons.FaDownload/>
                            </label>
                            <label onClick={(e)=>this.downloadDeleteViewImage("",e,"download")} className="rowOptionsForImage" style={{marginLeft:'10px',cursor:'pointer',display:'none'}}>
                                {i18n.translate("default_download")} 
                                <FaIcons.FaDownload/>
                            </label>
                            <label onClick={(e)=>this.downloadDeleteViewImage("",e,"delete")} className="rowOptionsForImage" style={{marginLeft:'10px',cursor:'pointer',display:'none'}}>
                                {i18n.translate("delete")}
                                <FaIcons.FaTrash/>
                            </label>
                            <label onClick={(e)=>this.downloadDeleteViewImage("",e,"cancel")} className="rowOptionsForImage" style={{marginLeft:'10px',cursor:'pointer',display:'none'}}>
                                {i18n.translate("cancel")}
                                <FaIcons.FaWindowClose/>
                            </label>
                        </Col>
                    </Row>
                </nav>
                <div id='bodyContent' className='bodyContent'>
                    {this.state.spinner}
                    <Row className="rowIndexStorage">
                    {this.state.contentPagination}
                    </Row>
                    <Row className='rowManageStorage' style={{display:'none'}}>
                        {this.state.extraStorageList.map((list) =>{
                            return(
                                <Col key={list.id} sm={6} md={3} lg={3} xl={3}>
                                    <div className='plansList' id={'planList-'+list.id}  style={{display:'inline-flex',padding:'10px',cursor:'pointer',height:'100px'}} onClick={(e) => this.selectNewPlan(e,list)}>
                                        <div className='storageNumber' style={{pointerEvents:'none'}}>{(list.storageSize/1024).toFixed(2)}</div>
                                        <div style={{textAlign:'left',paddingLeft:'30px',pointerEvents:'none',paddingTop:'10px',paddingRight:'25px'}}>
                                            <label style={{display:'block',fontWeight:'bolder',fontSize:'16px'}}>{list.moduleDescription}</label>
                                            <label style={{display:'block'}}>{list.price} USD</label>
                                        </div>
                                        <FaIcons.FaCheckCircle size={20} id={'planListIcon-'+list.id} style={{position: 'relative',top:'54px',color:'#a1d06d',display:'none'}}/>
                                    </div>
                                </Col>
                            )
                        })} 
                    </Row>
                </div>
                {this.state.paginationHtml}
            </>
        )
    } 
} 

export default IndexStorage;