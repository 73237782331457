import React from "react";
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, 
        Title, Tooltip, Legend, Filler} from 'chart.js';
import { Row, Col, Table, Card, CardBody, Spinner } from 'reactstrap';
import { catchErrorAjax, calculateScroll, toastFunction } from "../../components/mainFunctions";
import i18n from "i18n-react";
import { DefaultAjax } from "../../components/DefaultAjax";
import DefaultSpinner from "../../components/DefaultSpinner";

// Registra los elementos y escalas necesarios
Chart.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, Filler);

export const url = process.env.REACT_APP_API_URL;

class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalContent: "",
            modalContent2: "",
            spinner: "",
            expandedRow: null,
            periodsForFilter: {}, 
            loadingLine: true,
            loadingPie: true,
            loadingBar: true,
            loadingTable: true,
            errorLine: "",
            errorPie: "",
            errorBar: "",
            errorTable: "",
            pieData: {
                labels: [i18n.translate("default_available"),i18n.translate("default_used")],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ['#28a745', '#ff9800'],
                        hoverBackgroundColor: ['rgba(40, 167, 69, 0.5)', 'rgba(255, 152, 0, 0.5)'],
                    },
                ],
            },
            barData: {
                labels: [],
                datasets: [
                    {
                        label: i18n.translate("documents_used"),
                        backgroundColor: 'rgba(255, 193, 7, 0.4)', // Anaranjado claro
                        borderColor: '#ffc107', 
                        borderWidth: 1,
                        data: [],
                    },
                ],
            },
            areaData: {
                labels: [],
                datasets: [
                    {
                        label: i18n.translate("available_documents"),
                        fill: true,
                        backgroundColor: 'rgba(40, 167, 69, 0.2)', // Verde claro
                        borderColor: '#28a745', // Verde
                        data: [],
                    },
                    {
                        label: i18n.translate("documents_used"),
                        fill: true,
                        backgroundColor: 'rgba(255, 193, 7, 0.2)', // Amarillo anaranjado
                        borderColor: '#ffc107', // Amarillo anaranjado
                        data: [],
                    },
                ],
            },
            usersList : [],
            usersListFiltered: []  
        };
    }
    
    componentDidMount() {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });
        calculateScroll(15);
        this.periodsForFilter();
    }

    periodsForFilter = () => {
        DefaultAjax('').get("/fePyUsedDocument/getPeriods")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    periodsForFilter:result,
                    spinner: ""
                })
                setTimeout(() => {
                    this.searchByPeriod();
                    this.getChartLine();
                }, 10);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    searchByPeriod = (e) => {
        let periodForSearch = document.querySelector("#selectPeriods").value;
        if(e){
            periodForSearch = e.target.value;
        }
        this.getDataTable(periodForSearch);
        this.getChartBar(periodForSearch);
        this.getChartPie(periodForSearch);
    } 

    getChartPie = (periodForSearch) => {
        this.setState({ loadingPie: true, errorPie: null }); 
    
        const timeoutId = setTimeout(() => {
            this.setState({ loadingPie: false, errorPie: i18n.translate("the_request_has_taken_too_long") });
        }, 10000);

        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentPieChartStatistics?period="+periodForSearch)
        .then((res) => {
            clearTimeout(timeoutId)
            if(res){
                let result = JSON.parse(res.data);
                this.setState(prevState => ({
                    pieData: {
                        ...prevState.pieData,
                        datasets: [
                            {
                                ...prevState.pieData.datasets[0],
                                data: result,
                            }
                        ],
                    },
                    loadingPie: false
                }));
            }
        }).catch((error) =>{
            clearTimeout(timeoutId); 
            catchErrorAjax(error, this.setState.bind(this));
            this.setState({ loadingPie: false, errorPie: i18n.translate("an_error_occurred_while_loading_data") });
        });
    } 

    getChartLine = () => {
        this.setState({ loadingLine: true, errorLine: null }); 
    
        const timeoutId = setTimeout(() => {
            this.setState({ loadingLine: false, errorLine: i18n.translate("the_request_has_taken_too_long") });
        }, 10000); 
    
        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentStatisticsLineGraph")
            .then((res) => {
                clearTimeout(timeoutId); // Limpiar el timeout si la llamada es exitosa
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState(prevState => ({
                        areaData: {
                            ...prevState.areaData,
                            labels: result.labels,
                            datasets: [
                                {
                                    ...prevState.areaData.datasets[0],
                                    data: result.totalDocuments,
                                },
                                {
                                    ...prevState.areaData.datasets[1],
                                    data: result.usedDocuments,
                                },
                            ],
                        },
                        loadingLine: false
                    }));
                }
            }).catch((error) => {
                clearTimeout(timeoutId); // Limpiar el timeout si hay un error
                catchErrorAjax(error, this.setState.bind(this));
                this.setState({ loadingLine: false, errorLine: i18n.translate("an_error_occurred_while_loading_data") });
            });
    }; 

    getChartBar = (periodForSearch) => {
        this.setState({ loadingBar: true, errorBar: null }); 
    
        const timeoutId = setTimeout(() => {
            this.setState({ loadingBar: false, errorBar: i18n.translate("the_request_has_taken_too_long") });
        }, 10000); 

        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentstatisticsBarChart?period="+periodForSearch)
        .then((res) => {
            clearTimeout(timeoutId);
            if(res){
                let result = JSON.parse(res.data);
                this.setState(prevState => ({
                    barData: {
                        ...prevState.barData,
                        labels: result.labels,
                        datasets: [
                            {
                                ...prevState.barData.datasets[0],
                                data: result.usedDocuments,
                            }
                        ],
                    },
                    loadingBar: false
                }));
            }
        }).catch((error) =>{
            clearTimeout(timeoutId); 
            catchErrorAjax(error, this.setState.bind(this));
            this.setState({ loadingBar: false, errorBar: i18n.translate("an_error_occurred_while_loading_data") });
        });
    } 

    getDataTable = (periodForSearch) => {
        this.setState({ loadingTable: true, errorTable: null }); 
    
        const timeoutId = setTimeout(() => {
            this.setState({ loadingTable: false, errorTable: i18n.translate("the_request_has_taken_too_long") });
        }, 10000); 
        
        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentStatistics?period="+periodForSearch)
        .then((res) => {
            clearTimeout(timeoutId);
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    usersList:result,
                    usersListFiltered: result,
                    loadingTable: false,
                })
            }
        }).catch((error) =>{
            clearTimeout(timeoutId);
            catchErrorAjax(error, this.setState.bind(this));
            this.setState({ loadingTable: false, errorTable: i18n.translate("an_error_occurred_while_loading_data") });
        });
    } 

    toggleRow = (index) => {
        this.setState((prevState) => ({
            expandedRow: prevState.expandedRow === index ? null : index,
        }));
    };

    filterTable = (e) => {
        let searchTerm = e.target.value.toLowerCase(); 
        this.setState((prevState) => {
            const filteredList = prevState.usersList.filter(item => {
                const { branch, printer, username, fullname } = item;

                const matchBranch = branch?.toLowerCase().includes(searchTerm);
                const marchPrinter =  printer?.toLowerCase().includes(searchTerm);
                const matchUsername = username?.toLowerCase().includes(searchTerm);
                const matchFullname = fullname?.toLowerCase().includes(searchTerm);

                return matchBranch || marchPrinter || matchUsername || matchFullname
            })
            return{
                usersListFiltered: filteredList
            } 
        })
    } 
    

    render() {
        const areaDataOptions = {
            responsive: true,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: i18n.translate("default_periods"),
                        font: {
                            weight: 'bold', 
                            size: 14, 
                        },
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: i18n.translate("number_of_documents"),
                        font: {
                            weight: 'bold',
                            size: 14, 
                        },
                    },
                },
            },
            plugins: {
                tooltip: {
                    enabled: true, 
                    mode: 'index', // Muestra tooltips para todos los datasets en el mismo índice
                    intersect: true, // Permite que los tooltips aparezcan incluso cuando el mouse no está directamente sobre el punto
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label || '';
                            const dataPoint = context.raw;
                            return `${datasetLabel}: ${dataPoint}`;
                        },
                    },
                },
                title: {
                    display: true,
                    text: i18n.translate("last_6_periods"),
                    font: {
                        weight: 'bold',
                        size: 16, 
                    },
                }
            },
        };
        
        const pieDataOptions = {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: i18n.translate("default_documents"),
                    font: {
                        weight: 'bold',
                        size: 14, 
                    },
                }
            }    
        } 

        const barOptions = {
            responsive: true,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            weight: 'bold', 
                            size: 16,
                        },
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: i18n.translate("default_users"),
                        font: {
                            weight: 'bold',
                            size: 14,
                        },
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: i18n.translate("number_of_documents"),
                        font: {
                            weight: 'bold',
                            size: 14,
                        },
                    },
                },
            },
        };

        const { loadingLine, errorLine, areaData, loadingTable, 
            errorTable, usersListFiltered, loadingBar, errorBar, 
            barData, loadingPie, errorPie, pieData } = this.state;

        let lineChartContent;

        if (loadingLine) {
            lineChartContent = (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <Spinner color="primary" />
                </div>
            );
        } else if (errorLine) {
            lineChartContent = (
                <div className="alert alert-danger">{errorLine}</div>
            );
        } else {
            lineChartContent = (
                <Line 
                    data={areaData} 
                    options={{
                        ...areaDataOptions,
                        responsive: true,
                        maintainAspectRatio: false, 
                    }} 
                    height={undefined}
                />
            );
        } 
        
        let tableContent;

        if(loadingTable){
            tableContent = (
                <tr>
                    <td colSpan={9} className="text-center" style={{ height: '100px' }}>
                        <Spinner color="primary" />
                    </td>
                </tr>
            );
        } else if (errorTable){
            tableContent = (
                <tr>
                    <td colSpan={9} className="text-center" style={{ height: '100px' }}>
                        <div className="alert alert-danger">{this.state.errorTable}</div>
                    </td>
                </tr>
            );
        } else {
            tableContent = (
                usersListFiltered.map((user, index) => (
                    <>
                        <tr style={{ cursor: 'pointer' }} key={user.printer} onClick={() => this.toggleRow(index)}>
                            <td className='text-center'>{user.branch}</td>
                            <td className='text-center'>{user.printer}</td>
                            <td className='text-center'>{user.username}-{user.fullname}</td>
                            <td className='text-center'>{user.defaultQty}</td>
                            <td className='text-center'>{user.extraQty}</td>
                            <td className='text-center'>{user.ondemandQty}</td>
                            <td className='text-center'>{user.totalQty}</td>
                            <td className='text-center'>{user.usedQty}</td>
                            <td className='text-center'>{user.availableQty}</td>
                        </tr>
                        {this.state.expandedRow === index && user.detail && (
                            <tr className="tr_sub_table-container">
                                <td className="firstcell sub_table-container" colSpan={9}>
                                    <Table className='table table-sm without_margin sub_table'>
                                        <tbody className="subTableBody">
                                            <tr>
                                                <th className='firstcell'>{i18n.translate("subscription_date")}</th>
                                                <th className='firstcell'>{i18n.translate("default_name")}</th>
                                                <th className='firstcell'>{i18n.translate("description")}</th>
                                                <th className='firstcell'>{i18n.translate("default_amount")}</th>
                                            </tr>
                                            {user.detail.map((det) => (
                                                <tr key={det.addedDate}>
                                                    <td className='text-center'>{det.addedDate}</td>
                                                    <td className='text-center'>{det.packName}</td>
                                                    <td className='text-center'>{det.packDescription}</td>
                                                    <td className='text-center'>{det.qty}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        )}
                    </>
                ))
            );
        }
        
        let barChartContent;

        if(loadingBar){
            barChartContent = (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <Spinner color="primary" />
                </div>
            ) 
        }else if(errorBar){
            barChartContent = (
                <div className="alert alert-danger">{errorBar}</div> 
            )
        }else{
            barChartContent = (
                <Bar data={barData}
                options={{
                    ...barOptions,
                    responsive: true,
                    maintainAspectRatio: false, 
                }} 
                height={undefined}/>
            )
        }  
        
        let pieChartContent;

        if(loadingPie){
            pieChartContent = (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <Spinner color="primary" />
                </div>
            );
        }else if(errorPie){
            pieChartContent = (
                <div className="alert alert-danger">{errorPie}</div> 
            )
        }else{
            pieChartContent = (
                <Pie data={pieData} options={pieDataOptions} />
            );
        }   

        return (
            <div className='bodyContent' id='bodyContent'>
                <div className="firstBlock" style={{padding: '10px'}}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <div style={{ width: '100%', height: '50vh' }}>
                                        {lineChartContent} 
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <br />
                <div className="secondBlock" style={{padding: '10px'}}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <label style={{display: "block"}}>Periodo</label>
                                    <select className="form-select selectStandard" id="selectPeriods" onChange={(e)=> {this.searchByPeriod(e)}}>
                                        {Object.entries(this.state.periodsForFilter).map(([label, value]) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col>
                                    <label style={{display: "block"}}>{i18n.translate("default_search")}</label>
                                    <input type="text" className="form-control inputStandard" onChange={(e) => {this.filterTable(e)}}/>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={3} lg={3} xl={3}>
                                    <div style={{ width: '100%', height: '100%' }}>
                                        {pieChartContent} 
                                    </div>
                                </Col>
                                <Col xs={12} md={9} lg={9} xl={9}>
                                    <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                        <Table className='table table-hover' style={{ tableLayout: 'fixed', width: '100%' }}>
                                            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                                                <tr>
                                                    <th className='firstcell'>{i18n.translate("office_type_1")}</th>
                                                    <th className='firstcell'>{i18n.translate("default_printer")}</th>
                                                    <th className='firstcell'>{i18n.translate("default_user")}</th>
                                                    <th className='firstcell'>{i18n.translate("documents_default")}</th>
                                                    <th className='firstcell'>{i18n.translate("plan_documents")}</th>
                                                    <th className='firstcell'>{i18n.translate("onDemand_documents")}</th>
                                                    <th className='firstcell'>{i18n.translate("total_documents")}</th>
                                                    <th className='firstcell'>{i18n.translate("documents_used")}</th>
                                                    <th className='firstcell'>{i18n.translate("available_documents")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableContent} 
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <div style={{ width: '100%', height: '50vh' }}>
                                    {barChartContent} 
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default Statistics;
