import axios from "axios";
import i18n from "i18n-react";
export const url = process.env.REACT_APP_API_URL;

export async function getConfigs() {
    try {
      const axiosResponse = {
        data: {},
        status: 200,
        statusText: 'OK',
        config: {},
        headers: {'Authorization': 'Bearer my-token'},
        url: url+"accessControl/accessControl"
      };
      const configs = await axios.get(axiosResponse);
      return configs.data;
    } catch (err) {
      return new Map();
    }
}


export const saveEditConfigApi = (newConfiguration) => {
    let result;
    if(newConfiguration.minutesIntervalThreshold == "" ||
    newConfiguration.numberOfInvocations == "" ||
    newConfiguration.secondsBetweenInvocations == "" ||
    newConfiguration.tenantId == ""){
        result = 1
    }else{
        result = 0;
    }    
    
    return result
} 