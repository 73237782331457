import React from "react";
import i18n from "i18n-react";
import {  Row, Col } from 'reactstrap';
import { toastFunction } from "../../components/mainFunctions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultSpinner from '../../components/DefaultSpinner';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";

class ImportProducts extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fileUploadState:"",
            importsAuditList: [],
            spinner: "" 
        }
        this.inputReference = React.createRef(); 
    }

    componentDidMount() {
        let nav = document.querySelector("#nav-top").offsetHeight;
        let altura = window.innerHeight - nav - 90;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        this.getImportsAudit();
    }

    fileUploadAction = () =>this.inputReference.current.click();

    fileUploadInputChange = (e) => {
        this.setState({fileUploadState:e.target.value});
    } 

    getImportsAudit = () => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})
        DefaultAjax('').get("electronicBilling/importsAudit")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({spinner:"",importsAuditList:result});
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }  

    importFile = () => {
        if(document.querySelector("#importFile").files[0]){
            this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})
            let fd = new FormData();
		    fd.append('importFile',document.querySelector("#importFile").files[0]);
            DefaultAjax('').get("electronicBilling/importModel")
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    if(result.status == "-1" || result.status == -1){
                        toastFunction(result.message,"error");
                    }else{
                        toastFunction(result.message,"success");
                        this.getImportsAudit();
                        this.setState({spinner:"",fileUploadState:""});
                    }
                }
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }else{
            toastFunction(i18n.translate("import_file_null"),"warning");
        }  
    } 

    exportModel = () => {
        let location = "electronicBilling/exportModel";
        
        window.location = location;
    }

    render(){
        return(
            <>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                <Row style={{borderBottom: '1px solid #DCDCDC',padding: '12px'}}>
                    {this.state.spinner}
                    <Col className="col-sm-12 col-md-9 col-lg-7 col-xl-7">
                        <label style={{marginRight:'10px'}}>
                            {i18n.translate("attach_the_import_file")}:
                        </label>
                        <input type="file" hidden ref={this.inputReference} onChange={this.fileUploadInputChange} id="importFile" 
                        accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excels"/>
                        <button className="ui button buttonMzateSecondary" onClick={this.fileUploadAction}>
                            {i18n.translate("select_file")} 
                        </button>
                        {this.state.fileUploadState}
                    </Col>
                    <Col className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-1" onClick={() => this.importFile()}>
                        <button className='buttonMzateSecondary'>{i18n.translate("default_import")}</button>
                    </Col>
                    <Col className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" onClick={() => this.exportModel()}>
                        <button className='buttonMzateSecondary'>{i18n.translate("export_model")}</button>
                    </Col>
                </Row>
                <div className='bodyContent' id='bodyContent'>
                    <div style={{paddingTop:'10px',paddingBottom: '10px'}}>
                        <span className="title">
			           	 	{i18n.translate("the_file_have_maximum_1000_records")} 
                        </span>
                    </div>
                    <Row>
                        <Col xl={6}>
                            <table className="table table-hover">
                                <thead>
									<tr>
									    <th className="firstcell cellTitle" colSpan={5}>
                                            {i18n.translate("required_fields")} 
                                        </th>
									</tr>
								</thead>
                                <tbody>
									<tr>
										<td className="firstcell text">
                                            <label id="productCodeText" for="code">
                                                {i18n.translate("default_code")} 
                                            </label>
                                        </td>
										<td className="cellgroup text">
                                            <label id="productDescriptionText" for="description">
                                                {i18n.translate("description")} 
                                            </label>
                                        </td>
										<td className="cellgroup text">
                                            <label id="productPriceText" for="price">
                                                {i18n.translate("storage_price")}
                                            </label>
                                        </td>
										<td className="cellgroup text">
                                            <label id="productTaxText" for="tax">
                                                {i18n.translate("default_name")}
                                            </label>
                                        </td>
										<td className="cellgroup text">
                                            <label id="productTaxValueText" for="taxValue">
                                                {i18n.translate("default_value")}
                                            </label>
                                        </td>
									</tr>
								</tbody>
                            </table>
                        </Col>
                        <Col xl={6}>
                            <table className="table table-hover">
                                <thead>
							   		<tr>
							   			<th className="firstcell cellTitle" colSpan={3}>
                                            <label id="priceLisText">
                                                {i18n.translate("price_list")} 
                                            </label>
                                        </th>
							   		</tr>
							   	</thead>
                                <tbody>
                                    <tr>
                                        <td className="firstcell" style={{textAlign:'left'}} rowSpan={5}>
                                            <label id="priceListHelp">
                                                {i18n.translate("import_product_instructions")} 
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div className="result table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="firstcell cellTitle">
                                        <label id="startDateText" for="start">
                                            {i18n.translate("start_date")} 
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="endDateText" for="start">
                                            {i18n.translate("end_date")}
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="usernameText" for="username">
                                            {i18n.translate("username")} 
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="fullnameText" for="fullname">
                                            {i18n.translate("fullname")} 
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="statusText" for="status">
                                            {i18n.translate("state")} 
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="filenameText" for="file">
                                            {i18n.translate("file_name")} 
                                        </label>
                                    </th>
                                    <th className="cellgroup cellTitle">
                                        <label id="resultText" for="result">
                                            {i18n.translate("default_results")}  
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    } 
} 

export default ImportProducts;