import React, { useEffect, useState } from "react";
import { SidebarDataTasks } from "./SidebarDataTasks";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import FooterSidebar from "./FooterSidebar";
import { handleSidebarOptions } from "./mainFunctions";

const SidebarTasks = () => {
	const [openMenu, setOpenMenu] = useState(null);
	const [menuData, setMenuData] = useState(
		SidebarDataTasks.map(item => ({ ...item, isClicked: false }))
	);

	useEffect(() => {
		function handleResize() {
		  let nav = document.querySelector("#nav-top").offsetHeight;
		  let contentDiv = document.querySelector("#contentTasks");
		  let altura = window.innerHeight - nav;
		  contentDiv.style.height = altura + "px";
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	  }, []);

	const handleMenuClick = (id,event,isFromSubMenu) => {
		handleSidebarOptions(event,isFromSubMenu);
		
		setMenuData(prevMenu =>
		  prevMenu.map(menu =>
			menu.id === id
			  ? { ...menu, isClicked: true, className: 'active' }
			  : { ...menu, isClicked: false, className: '' }
		  )
		);
		setOpenMenu(prevMenu => (prevMenu === id ? null : id));
	};
	 
	return (
		<IconContext.Provider value={{ color: "#fff" }}>
			<ul className="ulSidebar" id="ulSidebar">
				{menuData.map((item, index) => (
				<SubMenu
					item={item}
					key={index}
					openMenu={openMenu}
					setOpenMenu={setOpenMenu}
					handleMenuClick={handleMenuClick}
				/>
				))}
			</ul>
			<FooterSidebar url="tasks"/>
			
		</IconContext.Provider>
	);
};

export default SidebarTasks;
