import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarMyAccount from "../components/SidebarMyAccount";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions"; 
import IndexStorage from "./adminContent/IndexStorage";
import PropTypes from 'prop-types';
import { windowHistoryReplaceState } from "../components/mainFunctions"; 

const MyAccount = ({token,independent,instanceType,language}) =>{
    let content = <IndexStorage token={token} independent={independent} instanceType={instanceType} language={language}/>;

    windowHistoryReplaceState(independent,instanceType);

    let flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
    

    return (
        <div className="tab-pane fade" id="nav-controlMyAccount" role="tabpanel" aria-labelledby="nav-controlMyAccount-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarMyAccount/>
            </div>
            
            <div className="content" id="contentMyAccount">
                {content}
            </div>
        </div>
    );
} 

MyAccount.propTypes = {
    token: PropTypes.string.isRequired,
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
};

export default MyAccount;