import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarAdministration from "../components/SidebarAdministration";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";
import NotificationEvents from "../pages/adminContent/NotificationEvents"; 
import PropTypes from 'prop-types';
import { windowHistoryReplaceState } from "../components/mainFunctions"; 

const Administration = ({token,independent,instanceType}) => {
    let content = <NotificationEvents token={token} independent={independent} instanceType={instanceType}/>;

    windowHistoryReplaceState(independent,instanceType);

    let flag = 0;
    const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }

    return (
        <div className="tab-pane fade" id="nav-controlAdministration" role="tabpanel" aria-labelledby="nav-controlAdministration-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarAdministration/>
            </div>
            
            <div className="content" id="contentAdministration">
                {content}
            </div>
        </div>
    );
} 

Administration.propTypes = {
    token: PropTypes.string.isRequired, 
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired
};

export default Administration;