import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import i18n from 'i18n-react';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #252831;
  padding-left: 3rem;
  display: block;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: green;
  }
`;

const SubMenu = ({ item, openMenu, setOpenMenu, handleMenuClick }) => {
  const handleMouseEnter = () => {
    // Si el menú no está clicado, abrirlo
    if (!item.isClicked) {
      setOpenMenu(item.id);
    }
  };

  const handleMouseLeave = () => {
    // Solo cerramos si no está clicado
    if (!item.isClicked) {
      setOpenMenu(null);
    }
  };

  return (
    <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SidebarLink
        to={item.path}
        onClick={(e) => handleMenuClick(item.id,e)}
		className={item.className && 'active'}
      id={item.id}  
      >
        <div>
          {item.icon}
          <SidebarLabel>{i18n.translate(item.title)}</SidebarLabel>
        </div>
		<div>
			{item.subNav && item.iconOpened}	
		</div>
      </SidebarLink>
      {(openMenu === item.id || item.isClicked) && item.subNav && (
        <div className="submenu">
          {item.subNav.map((subItem, index) => (
            <DropdownLink
              to={subItem.path}
              key={index}
              onClick={(e) => handleMenuClick(item.id,e,true)}
              id={subItem.id}
            >
				{subItem.icon}
              <SidebarLabel>{i18n.translate(subItem.title)}</SidebarLabel>
            </DropdownLink>
          ))}
        </div>
      )}
    </li>
  );
};

export default SubMenu;
