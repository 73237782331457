import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectGIE from '../../components/SelectGIE';
import {  Row, Col, Table } from 'reactstrap';
import { toastFunction } from '../../components/mainFunctions';
import 'react-toastify/dist/ReactToastify.css';
import DefaultSpinner from '../../components/DefaultSpinner';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import AddContent from "../../img/add_content.png";
import RemoveContent from "../../img/remove_content.png";
import DefaultModal from '../../components/DefaultModal';
import DefaultDayAndHours from '../../components/DefaultDayAndHours';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import DefaultPortfolio from '../../components/DefaultPortfolio';
import Switch from "react-switch";
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';


class NotificationEvents extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            spinner: "",
            selectOrg: "",
            token: props.token,
            notificationType:"",
            modalContent:"",
            modalContent2:"",
            instanceType: props.instanceType,
            independent: props.independent,
            eventNotificationList: [],
            eventNotificationListForFilter:[],  
            notificationIdForDelete: "",
            notificationArrayToUpdate: [],
            portfolioHTML: "", 
            isUpdate: false,
            eventNotificationToCompare: "",
            settingsNotificationToCompare: "",
            generalOperationType: "N",
            eventListOperationType: "N",
            recipientsListOperationType: "N",
            settingsOperationType: "N",
            newNotification: "",
            clickInPortfolio: false,
            eventListToUpdate: [],
            hasGeofencingModule:false,
            checkedSwitchBatteryReset: false,
            checkedSwitchDesconectionReset: false,
            checkedSwitchGeofencingReset: false,
            checkedSwitchBattery: false,
            checkedSwitchDesconection: false,
            checkedSwitchGeofencing: false,
            notifyEmailStatus:false,
            notifyWebStatus:false,
            notifyMobileStatus:false
        } 
    } 

    componentDidMount = () => {
        let token;
        if(!this.state.token){
            this.setState({token: document.querySelector("#token").value});
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        }

        this.setState((prevState) => ({
            spinner:<DefaultSpinner instanceType={prevState.instanceType} 
            independent={prevState.independent}/>,
            selectOrg: <SelectGIE moduleCode={null} classSelect={"col-sm-12 col-md-4 col-lg-4 col-xl-4 selectGIE"} classSelectType={"col-sm-12 col-md-4 col-lg-4 col-xl-4"}/> 
        }))
        
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbar").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 50;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        bodyContent.style.height = altura + "px";

        setTimeout(()=> {
            this.getNotificationsList();
            this.getNotificationType();
        }, 100);
    }

    showAll = () => {
        this.setState({
            selectOrg:"",
            eventNotificationList: [],
            eventNotificationListForFilter: [] 
        });

        setTimeout(()=> {
            this.setState({selectOrg:<SelectGIE moduleCode={null} classSelect={"col-sm-12 col-md-4 col-lg-4 col-xl-4 selectGIE"} classSelectType={"col-sm-12 col-md-4 col-lg-4 col-xl-4"}/>});
        }, 10);

        setTimeout(()=> {
            this.getNotificationsList();
        }, 200);
    } 

    getNotificationsList = () => {
        let orgNodeId,type;
        if(document.querySelector(".contentInput .selectType").value == "1"){
            orgNodeId = document.querySelector(".contentInput #profileSelected").value
        }else if(document.querySelector(".contentInput .selectType").value == "2"){
            orgNodeId = document.querySelector(".contentInput #groupSelected").value
        }else{
            orgNodeId = document.querySelector(".contentInput #userSelected").value;
        }
        type = document.querySelector(".contentInput .selectType").value;

        DefaultAjax('').get("adminNotifications/index?type="+type+"&orgNodeId="+orgNodeId)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    eventNotificationList:result,
                    eventNotificationListForFilter:result,
                    spinner:""
                });
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }  

    changeItemSelect = (itemselected) => {
        if(itemselected == "notificationEventConfiguration"){
            this.setState({clickInPortfolio: true});
            this.setUpPortfolio();
            document.querySelector("#notificationEventConfiguration").classList.remove("activeItem");
            document.querySelector("#notificationEventConfigurationContent").style.display = "none";
            document.querySelector("#notificationEventRecipients").classList.add("activeItem");
            document.querySelector("#notificationEventRecipientsContent").style.display = "";
        }else{
            document.querySelector("#notificationEventConfiguration").classList.add("activeItem");
            document.querySelector("#notificationEventConfigurationContent").style.display = "";
            document.querySelector("#notificationEventRecipients").classList.remove("activeItem");
            document.querySelector("#notificationEventRecipientsContent").style.display = "none";
        }  
    }
    
    setUpPortfolio = () => {
        let inputcheckBox = document.querySelectorAll("input[type='checkbox'][level]");
        for(let input of inputcheckBox){
            input.removeAttribute("disabled");
            // input.checked = false;
            input.addEventListener('click', this.changeVisibilityOfTheDocument);
        }
        let spanCheckBox = document.querySelectorAll('.unselectable span');
        for(let span of spanCheckBox){
            span.style.display = '';
        }
    } 

    changeVisibilityOfTheDocument = (e) => {
        const element = e.target;
        const level = element.getAttribute('level') ? parseInt(element.getAttribute('level')) : 1;
        const isChecked = element.checked;
        const isMember = this.hasClass(element, "members");
        const elementIdSplited = element.id.split("-");
        
        // Obtener los checkboxes relevantes
        const getCheckboxes = (selector) => document.querySelectorAll(selector);
        
        const updateCheckboxes = (checkboxes, checked) => {
            checkboxes.forEach(checkbox => {
                checkbox.disabled = checked;
                checkbox.checked = checked;
                checkbox.parentNode.classList.toggle("labelDisabled", checked);
            });
        };
    
        const handleMemberCheckbox = () => {
            this.ch(element);
        };
    
        const handleNormalCheckbox = () => {
            const checkBoxes = getCheckboxes(`input[type='checkbox'][level='${level}'].${element.id}`);
            const checkBoxes2 = getCheckboxes(`input[type='checkbox'][level='${level}'][class*='${element.className.trim()}-']:not(.members)`);
    
            updateCheckboxes(checkBoxes2, isChecked);
            
            for (const checkBox of checkBoxes) {
                if (element.id !== checkBox.id) {
                    if (isChecked) {
                        checkBox.parentNode.classList.add("labelDisabled");
                    } else {
                        checkBox.parentNode.classList.remove("labelDisabled");
                        const isFind = this.ch(checkBox, true);
                        if (!isFind) {
                            checkBox.checked = isChecked;
                        }
                    }
                    checkBox.disabled = isChecked;
                }
            }
        };
    
        // Obtener los checkboxes del nivel actual
        const parentElements = getCheckboxes(`input[type='checkbox'][level='${level}']:checked:enabled`) ||
            getCheckboxes(`input[type='checkbox'][level='${level}']:checked:disabled`);
    
        // Manejar la visibilidad de los checkboxes
        if (isMember) {
            handleMemberCheckbox();
        } else {
            handleNormalCheckbox();
        }
    };
    

    hasClass = (el,cls) => {
        if (!el.className) {
            return false;
        } else {
            let newElementClass = ' ' + el.className + ' ';
            let newClassName = ' ' + cls + ' ';
            return newElementClass.indexOf(newClassName) !== -1;
        }
    }
    
    ch = (element, isGroup) => {
        const member = parseInt(element.getAttribute('member')) || 0;
        const group = element.getAttribute('group') || 0;
        const ion = element.getAttribute('ion') || 0;
        
        const getMemberCheckboxes = (member) => document.querySelectorAll(`input.member_${member}[type='checkbox']`);
        const getCheckedMemberCheckboxes = (member) => document.querySelectorAll(`input.member_${member}[type='checkbox']:checked:enabled`);
        
        const getElementById = (id) => id ? document.querySelector(`#${id}`) : null;
    
        const memberElements = getMemberCheckboxes(member);
        const memberCheckedElements = getCheckedMemberCheckboxes(member);
        
        const groupElement = getElementById(group);
        const ionElement = getElementById(ion);
        
        let find = false;
    
        if (isGroup) {
            find = memberCheckedElements.length > 0;
        } else {
            if (groupElement && ionElement) {
                for (const memberElement of memberElements) {
                    if (!memberElement.disabled && !(groupElement.checked && ionElement.checked)) {
                        memberElement.checked = element.checked;
                    }
                }
            }
        }
    
        return find;
    };
    
    

    getNotificationType = () => {
        DefaultAjax('').get("adminNotifications/adminNotifications")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    notificationType:result,
                    spinner:""
                });
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    updateEventNotificationsModalContent = (isUpdate,eventNotification) => {
        let buttonText = i18n.translate("save");
        if(this.state.isUpdate == true){
            buttonText = i18n.translate("update");
        } 
        setTimeout(()=> {
            this.setState({modalContentDefaultStorage:this.modalContentNewAndUpdate(isUpdate,eventNotification)});
        }, 5);
        setTimeout(()=> {
            let buttons = [{text: buttonText, function: this.validateNotifications},
            {text: i18n.translate("cancel"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("notifications")}
            content={this.state.modalContentDefaultStorage} buttons={buttons} size={'lg'} modalClass={'modalWithOptionsItem'} />})
        }, 10);
    } 

    modalContentNewAndUpdate = (isUpdate,eventNotification) => {
        let checkDays = [{"monday":false,"tuesday":false,"wednesday":false,"thursday":false,
        "friday":false,"saturday":false,"sunday":false,"startHour":"07:00","endHour":"17:00",
        "administrativeNotificationInstanceId": 0,"id": 0, "operationType":"A"}];

        if(isUpdate){
            checkDays = eventNotification.settings;
            setTimeout(()=> {
                this.setCheckBoxValue(eventNotification);
            }, 100);
        } 

        this.setState({
            clickInPortfolio:false
        }); 

        const criteriaValue = [
            { value: "05"},{ value: "10"},{ value: "15"},{ value: "20"},{ value: "25"},{ value: "30"},
            { value: "35"},{ value: "40"},{ value: "45"},{ value: "50"},{ value: "55"},{ value: "60"},
            { value: "65"},{ value: "70"},{ value: "75"},{ value: "80"},{ value: "85"},{ value: "90"},
            { value: "95"}
             
        ] 

        const criteriaValueDisconection = [
            { value: "15"},{ value: "20"},{ value: "25"},{ value: "30"},
            { value: "35"},{ value: "40"},{ value: "45"},{ value: "50"},{ value: "55"},{ value: "60"},
            { value: "65"},{ value: "70"},{ value: "75"},{ value: "80"},{ value: "85"},{ value: "90"},
            { value: "95"}
             
        ]

        this.handleChange = (e,swichToCheck,index) => {
            let isForReset = false;
            let isForMainEvent = false;
            
            if(swichToCheck == 'checkedSwitchBatteryReset'){
                this.setState({checkedSwitchBatteryReset:e});
                isForReset = true;
            }else if(swichToCheck == 'checkedSwitchDesconectionReset'){
                this.setState({checkedSwitchDesconectionReset:e});
                isForReset = true;
            }else if(swichToCheck == 'checkedSwitchGeofencingReset'){
                this.setState({checkedSwitchGeofencingReset:e});
                isForReset = true;
            }else if(swichToCheck == 'checkedSwitchBattery'){
                this.setState({checkedSwitchBattery:e});
                if(e == false){
                    this.setState({checkedSwitchBatteryReset:false});
                } 
                isForMainEvent = true;
            }else if(swichToCheck == 'checkedSwitchDesconection'){
                this.setState({checkedSwitchDesconection:e});
                if(e == false){
                    this.setState({checkedSwitchDesconectionReset:false});
                }
                isForMainEvent = true;
            }else if(swichToCheck == 'checkedSwitchGeofencing'){
                this.setState({checkedSwitchGeofencing:e});
                if(e == false){
                    this.setState({checkedSwitchGeofencingReset:false});
                }
                isForMainEvent = true;
            } 

            if(eventNotification){
                eventNotification.notifyEmail = document.querySelector("#email").checked;
                eventNotification.notifyMobile = document.querySelector("#movil").checked;
                eventNotification.notifyWeb = document.querySelector("#web").checked;
                eventNotification.operationType = this.state.generalOperationType;
            } 
            
            this.updateEventNotificationsModalContent(this.state.isUpdate,eventNotification)
            
        }
        
        this.checkboxNotifyByChange = (e,typeNotification) => {
            let operationTypeGeneral = 'A';

            const setOptionsWeb = (eventNotification,e) => {
                eventNotification.notifyWeb = e.target.checked;
                if(e.target.checked != this.state.notifyWebStatus){
                    eventNotification.operationType = 'M';
                }else{
                    eventNotification.operationType = 'N';
                }
            }
            
            const setOptionsEmail = (eventNotification,e) => {
                eventNotification.notifyEmail = e.target.checked;
                if(e.target.checked != this.state.notifyEmailStatus){
                    eventNotification.operationType = 'M';
                }else{
                    eventNotification.operationType = 'N';
                }
            }

            const setOptionsMobile = (eventNotification,e) => {
                eventNotification.notifyMobile = e.target.checked;
                if(e.target.checked != this.state.notifyMobileStatus){
                    eventNotification.operationType = 'M';
                }else{
                    eventNotification.operationType = 'N';
                }
            } 

            if(this.state.isUpdate){
                switch(typeNotification){
                    case 'web':
                        setOptionsWeb(eventNotification,e);
                        break;
                    case 'email':
                        setOptionsEmail(eventNotification,e);
                        break;
                    case 'mobile':
                        setOptionsMobile(eventNotification,e);
                        break;
                    default:
                        break;
                }
                operationTypeGeneral = eventNotification.operationType;
            }

            this.setState({generalOperationType:operationTypeGeneral});
            this.updateEventNotificationsModalContent(this.state.isUpdate,eventNotification)
        }

        this.updateIntervalDayAndHour = (arrayToUpdate) => {
            arrayToUpdate.forEach((settings,index) => {
                if(this.state.isUpdate && settings.operationType != 'D' && settings.operationType != 'A'){
                    if(settings.friday == this.state.settingsNotificationToCompare[index].friday && 
                        settings.monday == this.state.settingsNotificationToCompare[index].monday && 
                        settings.saturday == this.state.settingsNotificationToCompare[index].saturday && 
                        settings.sunday == this.state.settingsNotificationToCompare[index].sunday && 
                        settings.thursday == this.state.settingsNotificationToCompare[index].thursday && 
                        settings.tuesday == this.state.settingsNotificationToCompare[index].tuesday && 
                        settings.wednesday == this.state.settingsNotificationToCompare[index].wednesday && 
                        settings.startHour == this.state.settingsNotificationToCompare[index].startHour && 
                        settings.endHour == this.state.settingsNotificationToCompare[index].endHour
                    ){
                        settings.operationType = 'N';
                    } 
                } 
            }) 

            let arrayToUpdateClone = cloneDeep(arrayToUpdate);
            if(eventNotification){
                eventNotification.settings = arrayToUpdateClone;
            } 

            this.setState({notificationArrayToUpdate:arrayToUpdateClone});
            this.updateEventNotificationsModalContent(this.state.isUpdate,eventNotification)
        }

        return(
            <>
                <Row style={{borderBottom:'1px solid darkgray',cursor:'pointer',textAlign:'center',marginBottom:'10px'}}>
                    <Col id='notificationEventConfiguration' className='activeItem' onClick={() => this.changeItemSelect("notificationEventRecipients")}>
                        {i18n.translate("default_configuration")} 
                    </Col>
                    <Col id='notificationEventRecipients' onClick={() => this.changeItemSelect("notificationEventConfiguration")}>
                        {i18n.translate("recipients")} 
                    </Col>
                </Row>
                <div id='notificationEventConfigurationContent'>
                    <Row>
                        <Col>
                            {i18n.translate("notify_via")} 
                        </Col>
                        <Col style={{display:'inline-flex'}}>
                            <div style={{marginRight: '10px'}}>
                                <input type="checkbox" onChange={(e)=> this.checkboxNotifyByChange(e,'web')} checked={eventNotification.notifyWeb}  id="web" name="web" />
                                <label htmlFor="web">
                                    {i18n.translate("default_web")} 
                                </label>
                            </div>
                            <div style={{marginRight: '10px'}}>
                                <input type="checkbox" onChange={(e)=> this.checkboxNotifyByChange(e,'email')} checked={eventNotification.notifyEmail} id="email" name="email" />
                                <label htmlFor="email">
                                    {i18n.translate("default_email")}
                                </label>
                            </div>
                            <div style={{marginRight: '10px'}}>
                                <input type="checkbox" onChange={(e)=> this.checkboxNotifyByChange(e,'mobile')} checked={eventNotification.notifyMobile} id="movil" name="movil" />
                                <label htmlFor="movil">
                                    {i18n.translate("default_movil")}
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{paddingTop:'15px'}}>
                        <Col>
                            {i18n.translate("default_schedule")} 
                        </Col>
                        <Col>
                            <DefaultDayAndHours checkDays={checkDays} isUpdate={this.state.isUpdate} 
                            updateIntervalDayAndHour={this.updateIntervalDayAndHour} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 style={{textAlign:'center'}}>{i18n.translate("default_general")}</h5>
                        </Col>
                    </Row>
                    {this.state.notificationType.map((nT,index) => {
                        return(
                            <>
                                {nT.moduleCode == "GENERAL" && 
                                    <Row style={{marginBottom:'20px'}}>
                                        <Col style={{textAlign:'center',padding:'5% 5%',paddingTop:'48px'}} className={'colForInputCheck-'+nT.id}>
                                            {nT.code == 'disconnection' && 
                                                <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchDesconection',index)} checked={this.state.checkedSwitchDesconection}></Switch>
                                            } 
                                            {nT.code != 'disconnection' && 
                                                <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchBattery',index)} checked={this.state.checkedSwitchBattery} />
                                            }
                                        </Col>
                                        <Col>
                                            <p style={{marginBottom:'0px',textAlign:'center',fontWeight:'bolder'}}>
                                                {i18n.translate(nT.code.replaceAll(".", "_"))}
                                            </p> 
                                            <label style={{fontSize:'14px',textAlign:'center'}}>{nT.description}</label> 
                                        </Col>
                                        <Col style={{padding:'3% 3%',textAlign:'center'}}>
                                            <select className={'form-select selectStandard selectEventNotificationValue selectStandard'+nT.id} id={nT.id} name={nT.code} 
                                            style={{width:'120px',display:'inline-block'}} onChange={this.changeEventTypeValue}>
                                                {nT.code == 'disconnection' && <>
                                                    {criteriaValueDisconection.map((minutes) => {
                                                        return(
                                                            <option value={minutes.value} key={minutes.value}>
                                                                {minutes.value} {nT.unitOfMeasurement} 
                                                            </option>
                                                        )
                                                    })}
                                                </>}
                                                {nT.code != 'disconnection' && <>
                                                    {criteriaValue.map((minutes) => {
                                                        return(
                                                            <option value={minutes.value} key={minutes.value}>
                                                                {minutes.value} {nT.unitOfMeasurement} 
                                                            </option>
                                                        )
                                                    })}
                                                </>}
                                            </select>  
                                        </Col>
                                        <Col style={{textAlign: 'center'}}>
                                            <label>{i18n.translate("notify_on_reset")}</label>
                                            {nT.code == 'disconnection' && 
                                                <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchDesconectionReset',index)} 
                                                checked={this.state.checkedSwitchDesconectionReset} className={this.state.checkedSwitchDesconection ? '' : 'noPointerEvents'}/>
                                            } 
                                            {nT.code != 'disconnection' && 
                                                <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchBatteryReset',index)} 
                                                checked={this.state.checkedSwitchBatteryReset} className={this.state.checkedSwitchBattery ? '' : 'noPointerEvents'}/>
                                            }
                                        </Col>
                                    </Row>
                                } 
                            </>
                        )
                    })} 
                    {this.state.hasGeofencingModule && 
                        <Row>
                            <Col>
                                <h5 style={{textAlign:'center'}}>{i18n.translate("geofences_title")}</h5>
                            </Col>
                        </Row>
                    } 
                    {this.state.notificationType.map((nT,index) => {
                        return (
                            <>
                                {nT.moduleCode == "GEOFENCING" && 
                                    <Row style={{marginBottom:'20px'}}>
                                        <Col style={{textAlign:'center',padding:'5% 5%',paddingTop:'48px'}} className={'colForInputCheck-'+nT.id}>
                                            <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchGeofencing',index)} checked={this.state.checkedSwitchGeofencing} />
                                        </Col>
                                        <Col>
                                            <p style={{marginBottom:'0px',textAlign:'center',fontWeight:'bolder'}}>
                                                {i18n.translate(nT.code.replaceAll(".", "_"))}
                                            </p> 
                                            <label style={{fontSize:'14px',textAlign:'center'}}>{nT.description}</label>   
                                        </Col>
                                        <Col style={{padding:'3% 3%',textAlign:'center'}}>
                                            <select className={'form-select selectStandard selectEventNotificationValue selectStandard'+nT.id} id={nT.id} name={nT.code} 
                                            style={{width:'120px',display:'inline-block'}} onChange={this.changeEventTypeValue}>
                                                {criteriaValue.map((minutes) => {
                                                    return(
                                                        <option value={minutes.value} key={minutes.value}>
                                                            {minutes.value} {nT.unitOfMeasurement}
                                                        </option>
                                                    )
                                                })} 
                                            </select>  
                                        </Col>
                                        <Col style={{textAlign: 'center'}}>
                                            <label>{i18n.translate("notify_on_reset")}</label>
                                            <Switch onChange={(e)=> this.handleChange(e,'checkedSwitchGeofencingReset',index)} 
                                            checked={this.state.checkedSwitchGeofencingReset} className={this.state.checkedSwitchGeofencing ? '' : 'noPointerEvents'}/>
                                        </Col>
                                    </Row>
                                }
                            </>
                        )
                    })} 
                </div>
                <div id='notificationEventRecipientsContent' style={{display:'none'}}>
                    <input type='hidden' id='clickedInPortafolio' value={true}/>
                    {this.state.portfolioHTML} 
                </div>
            </>
        )
    }
    changeEventTypeValue = (e) => {
        if(this.state.isUpdate){
            this.state.eventNotificationToCompare.eventList.forEach((event) => {
                if(event.administrativeNotificationId == parseInt(e.target.id)){
                    if(e.target.classList.contains("inputNotificationEventCheckbox") && !e.target.checked){
                        e.target.classList.add("delete");
                    }else if(e.target.classList.contains("inputNotificationEventCheckbox") && e.target.checked){
                        e.target.classList.remove("delete");
                    }else if(!e.target.classList.contains("inputNotificationEventCheckbox")){
                        if(event.criteriaValue != e.target.value){
                            e.target.classList.add("update");
                        }else{
                            e.target.classList.remove("update");
                        }  
                    }
                } 
            }) 
            
        } 
    } 

    setCheckBoxValue = (eventNotification) => {
        let trWeek = document.querySelectorAll(".trWeek");
        let count = 0;
        let settingsToCompare = []; 
        while(count < trWeek.length){
            settingsToCompare.push({
                "monday":document.querySelector(".weekDay-"+count+" input[name='monday']").checked,
                "tuesday":document.querySelector(".weekDay-"+count+" input[name='tuesday']").checked,
                "wednesday":document.querySelector(".weekDay-"+count+" input[name='wednesday']").checked,
                "thursday":document.querySelector(".weekDay-"+count+" input[name='thursday']").checked,
                "friday":document.querySelector(".weekDay-"+count+" input[name='friday']").checked,
                "saturday":document.querySelector(".weekDay-"+count+" input[name='saturday']").checked,
                "sunday":document.querySelector(".weekDay-"+count+" input[name='sunday']").checked,
                "startHour": eventNotification.settings[count].startHour,
                "endHour":eventNotification.settings[count].endHour
            })
            count++;
        } 
        this.setState({settingsToCompare:settingsToCompare})
    } 

    setGonList = () => {
        let gonIdList=[];
        let checkboxesGonIdList = document.querySelectorAll("input[name='gonIdList']:checked:enabled");
        for(let checkbox of checkboxesGonIdList){
            let value = checkbox.value;
            gonIdList.push(value);
        }
        return gonIdList;
    }

    setEonList = () => {
        let eonIdList=[];
        let checkboxesEonIdList = document.querySelectorAll("input[name='eonIdList']:checked:enabled");
        for(let checkbox of checkboxesEonIdList){
            let value = checkbox.value;
            eonIdList.push(value);
        }
        return eonIdList;
    }

    setIonList = () => {
        let ionIdList=[];
        let checkboxesIonIdList = document.querySelectorAll("input[name='ionIdList']:checked:enabled");
        for(let checkbox of checkboxesIonIdList){
            let value = checkbox.value;
            ionIdList.push(value);
        }
        return ionIdList;
    } 

    getEventListToSetId = (id) => {
        let mapToReturn;
        this.state.eventNotificationToCompare.eventList && this.state.eventNotificationToCompare.eventList.forEach((events) => {
            if(parseInt(id) == events.administrativeNotificationId){
                mapToReturn = events;
            } 
        }) 
        return mapToReturn;
    } 

    getEventList = () => {
        let operationType;
        let eventList = []; 
        let mapId = {};  
        let administrativeNotificationInstanceId,id;
        let selectEventNotificationValue = document.querySelectorAll(".selectEventNotificationValue");
        let addNewNotification = false;
        let notifyPositiveEventToSet = false;

        const setValuesToUpdate = () => {
            const notificationStates = {
                "battery.percentage": {
                    checked: this.state.checkedSwitchBattery,
                    reset: this.state.checkedSwitchBatteryReset
                },
                "disconnection": {
                    checked: this.state.checkedSwitchDesconection,
                    reset: this.state.checkedSwitchDesconectionReset
                },
                "out.of.geofencing": {
                    checked: this.state.checkedSwitchGeofencing,
                    reset: this.state.checkedSwitchGeofencingReset
                }
            };
        
            const { code, criteriaValue, notifyPositiveEvent, administrativeNotificationId } = mapId;
            const { checked, reset } = notificationStates[code] || {};
        
            const currentCriteriaValue = document.querySelector(`.selectStandard${administrativeNotificationId}`)?.value;
        
            if (checked !== undefined) {
                addNewNotification = true;
                notifyPositiveEventToSet = reset;
                if (checked && (criteriaValue !== currentCriteriaValue || notifyPositiveEvent !== reset)) {
                    operationType = "M";
                } else if (!checked) {
                    operationType = "D";
                } else {
                    operationType = "N";
                }
            }
        };

        const setValuesCreate = (selectNotification) => {
            const notificationStateMap = {
                "disconnection": this.state.checkedSwitchDesconection,
                "out.of.geofencing": this.state.checkedSwitchGeofencing,
                "battery.percentage": this.state.checkedSwitchBattery
            };

            const notificationResetStateMap = {
                "disconnection": this.state.checkedSwitchDesconectionReset,
                "out.of.geofencing": this.state.checkedSwitchGeofencingReset,
                "battery.percentage": this.state.checkedSwitchBatteryReset
            };
        
            addNewNotification = notificationStateMap[selectNotification.name] || false;
            notifyPositiveEventToSet = notificationResetStateMap[selectNotification.name];

            operationType = "A";
            administrativeNotificationInstanceId = 0;
            id = 0;
        } 

        for(let selectNotification of selectEventNotificationValue){
            addNewNotification = false;
            mapId = this.getEventListToSetId(selectNotification.id);
            if(mapId && this.state.isUpdate){
                addNewNotification = true;
                administrativeNotificationInstanceId = mapId.administrativeNotificationId;
                id = mapId.id;
                setValuesToUpdate()
            }else{
                setValuesCreate(selectNotification)
            } 
            if(addNewNotification){
                eventList.push({"administrativeNotificationId":selectNotification.id,
                "administrativeNotificationInstanceId": administrativeNotificationInstanceId,"criteriaValue":document.querySelector(".selectStandard"+selectNotification.id).value,
                "id":id,"operationType": operationType,"notifyPositiveEvent": notifyPositiveEventToSet
                })
            }
        } 
        return eventList;
    } 

    validateNotifications = () => {
        let recipientsList2;
        let eventList = this.getEventList();
        if(this.state.isUpdate && !this.state.clickInPortfolio){
            recipientsList2 = this.state.eventNotificationToCompare.recipientsList;
        }else{
            let gonIdList = this.setGonList();
            let eonIdList = this.setEonList();
            let ionIdList = this.setIonList();
            let recipientsList = gonIdList.concat(eonIdList);
            recipientsList2 = recipientsList.concat(ionIdList);
        }  
        let newNotification = {
            "eventList": eventList,
            "id": 0,
            "notifyEmail": document.querySelector("input[type='checkbox']#email").checked,
            "notifyMobile": document.querySelector("input[type='checkbox']#movil").checked,
            "notifyWeb": document.querySelector("input[type='checkbox']#web").checked,
            "operationType": this.state.generalOperationType,
            "recipientsList": recipientsList2,
            "settings": this.state.notificationArrayToUpdate,
        } 
        let selectDays = false;
        let arrayToSearchIsChecked = []; 
        newNotification.settings.forEach((hourDay) => {
            selectDays = false;
            if(hourDay.monday ||  hourDay.tuesday || hourDay.wednesday || hourDay.thursday 
                || hourDay.friday || hourDay.saturday || hourDay.sunday || hourDay.operationType == 'D'){
                    selectDays = true;
            } 
            arrayToSearchIsChecked.push(selectDays)
        })

        selectDays = true;
        arrayToSearchIsChecked.forEach((isChecked) => {
            if(!isChecked){
                selectDays = false;
            } 
        })

        if(newNotification.notifyEmail === false && newNotification.notifyMobile === false 
            && newNotification.notifyWeb === false){
            toastFunction(i18n.translate("must_select_means_to_notify"),"warning");
        }else if(!selectDays){
            toastFunction(i18n.translate("must_select_at_least_one_day_of_week"),"warning");
        }else if(newNotification.eventList.length == 0){
            toastFunction(i18n.translate("must_select_at_least_one_notification"),"warning");
        }else if(recipientsList2.length == 0){
            toastFunction(i18n.translate("must_select_at_least_one_recipient"),"warning");
            this.changeItemSelect("notificationEventConfiguration");
        }else{
            newNotification = {
                "eventList": eventList,
                "id": 0,
                "notifyEmail": document.querySelector("input[type='checkbox']#email").checked,
                "notifyMobile": document.querySelector("input[type='checkbox']#movil").checked,
                "notifyWeb": document.querySelector("input[type='checkbox']#web").checked,
                "operationType": this.state.generalOperationType,
                "recipientsList": recipientsList2,
                "settings": this.state.notificationArrayToUpdate,
            } 
            if(this.state.isUpdate){
                newNotification.id = this.state.eventNotificationToCompare.id;
            }

            this.validateEventNotifications(newNotification);
        }     
    } 

    usersWithoutEmailOrApp = (result) => {
        return (
            <Row>  
                {result.unconfiguredUsersList.length > 0 && 
                    <>
                        <h5 style={{textAlign:'center'}}>{i18n.translate("unconfigured_users")}</h5>
                        <p>{i18n.translate("unconfigured_users_description")}</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='firstcell'>{i18n.translate("default_user")}</th>
                                    <th className='firstcell'>{i18n.translate("last_monitoring")}</th>
                                    <th className='firstcell'>{i18n.translate("time_elapsed")}</th>
                                </tr>
                            </thead>
                            
                                {result.unconfiguredUsersList.map((users) => {
                                    return (
                                        <tr key={users.id} className={users.className} style={{textAlign:'center'}}>
                                            <td>{users.username} - {users.fullname}</td>
                                            <td>
                                                {!users.lastDeviceInfoDate && <>
                                                    {i18n.translate("does_not_have")}
                                                </>}
                                                {users.lastDeviceInfoDate}    
                                            </td>
                                            <td>{users.timeElapsed}</td>
                                        </tr>
                                    )
                                })} 
                            
                        </Table>
                    </>
                } 
                {result.usersWithoutEmailList.length > 0 && 
                    <Col>
                        <h5 style={{textAlign:'center'}}>{i18n.translate("users_without_email")}</h5>
                        <p>{i18n.translate("users_without_email_description")}</p>
                        <ul>
                            {result.usersWithoutEmailList.map((users) => {
                                return (
                                    <li key={users.id}>{users.username} - {users.fullname}</li>
                                )
                            })}
                        </ul>
                    </Col>
                } 
            </Row>
        )
    } 

    validateEventNotifications = (newNotification) => {
        this.setState({newNotification:newNotification})
        DefaultAjax('').post("adminNotifications/validateRecipients",newNotification)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                
                if(result.unconfiguredUsersList.length > 0 || result.usersWithoutEmailList.length > 0){
                    let functionToExecute;
                    if(this.state.isUpdate){
                        functionToExecute = this.executeUpdateNotification;
                    }else{
                        functionToExecute = this.executeCreateEventNotification;
                    } 
                    this.setState({
                        modalContent2:""
                    });
                    let modalContent = this.usersWithoutEmailOrApp(result);
                    setTimeout(() => {
                        let buttons = [{text: i18n.translate("default_accept"), function: functionToExecute},
                        {text: i18n.translate("cancel"), function: ""}];  
                        this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("may_not_receive_notifications")}
                        content={modalContent} buttons={buttons} size={'lg'} modalClass={'modalWithOptionsItem'} />})
                    }, 10);
                }else if(this.state.isUpdate){
                    this.executeUpdateNotification(newNotification);
                }else if(!this.state.isUpdate){
                    this.executeCreateEventNotification(newNotification);
                }    
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    executeUpdateNotification = (newNotification) => {
        if(!newNotification.id){
            newNotification = this.state.newNotification;
            this.setState({modalContent2:""})
        }
        DefaultAjax('').put("adminNotifications/adminNotificationInstance",newNotification)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseAjaxUpdateCreateNotification(result,i18n.translate("notification_updated")) 
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    handleResponseAjaxUpdateCreateNotification = (result,message) => {
        if(result == 0 || result == '0'){
            toastFunction(message,"success");
            this.setState({modalContent:""});
            setTimeout(()=> {
                this.showAll();
            }, 100);
        }else{
            this.setState({modalContent2:""});
            toastFunction(i18n.translate("an_error_has_ocurred"),"error");
        }
    } 

    executeCreateEventNotification = (newNotification) => {
        if(!newNotification.id){
            newNotification = this.state.newNotification;
            this.setState({modalContent2:""})
        }
        DefaultAjax('').post("adminNotifications/adminNotificationInstance",newNotification)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.handleResponseAjaxUpdateCreateNotification(result,i18n.translate("new_notification_created"))
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 
    
    addNotification = () => {
        if(document.querySelector("#geofencesPlatform")){
            this.setState({hasGeofencingModule:true});
        } 
        this.setState({
            modalContentDefaultStorage: "",
            modalContent:"",
            portfolioHTML: "",
            isUpdate: false,
            generalOperationType: "A",
            checkedSwitchBatteryReset: false,
            checkedSwitchDesconectionReset: false,
            checkedSwitchGeofencingReset: false,
            checkedSwitchBattery: false,
            checkedSwitchDesconection: false,
            checkedSwitchGeofencing: false,
            notificationArrayToUpdate: []
        });
        this.setState({portfolioHTML: <DefaultPortfolio instanceId={0} instanceName={'administrative_notification_recipients'} />})
        setTimeout(() => {
            this.setState({modalContentDefaultStorage:this.modalContentNewAndUpdate(false,'',false)});
        },5)
        setTimeout(() => {
            let buttons = [{text: i18n.translate("save"), function: this.validateNotifications},
            {text: i18n.translate("cancel"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("notifications")}
            content={this.state.modalContentDefaultStorage} buttons={buttons} size={'lg'} modalClass={'modalWithOptionsItem'} />})
        }, 10);
    } 

    showEventNotification = (notification) => {
        if(document.querySelector("#geofencesPlatform")){
            this.setState({hasGeofencingModule:true});
        }
        this.setState({
            modalContentDefaultStorage: "",
            modalContent:"",
            portfolioHTML: "",
            isUpdate: true,
            generalOperationType: "N",
            checkedSwitchBatteryReset: false,
            checkedSwitchDesconectionReset: false,
            checkedSwitchGeofencingReset: false,
            checkedSwitchBattery: false,
            checkedSwitchDesconection: false,
            checkedSwitchGeofencing: false
        });
        this.setState({portfolioHTML: <DefaultPortfolio instanceId={notification.notificationInstanceId} instanceName={'administrative_notification_recipients'} />})
        DefaultAjax('').get("adminNotifications/show?instanceId="+notification.notificationInstanceId)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);

                // Realizar una copia profunda del mapa antes de asignarlo al estado
                let settingsCopy = cloneDeep(result.settings);
                let eventNotificationToCompare = cloneDeep(result);
                
                this.setState({
                    settingsNotificationToCompare: settingsCopy,
                    notificationArrayToUpdate: settingsCopy,
                    eventNotificationToCompare: eventNotificationToCompare
                });

                this.setState({
                    notifyEmailStatus:result.notifyEmail,
                    notifyWebStatus: result.notifyWeb,
                    notifyMobileStatus: result.notifyMobile,
                });

                result.eventList.forEach((eventL) => {
                    if(eventL.code == 'disconnection' && eventL.notifyPositiveEvent){
                        this.setState({checkedSwitchDesconectionReset:eventL.notifyPositiveEvent});
                    }else if(eventL.code == 'battery.percentage' && eventL.notifyPositiveEvent){
                        this.setState({checkedSwitchBatteryReset:eventL.notifyPositiveEvent});
                    }else if(eventL.code == 'out.of.geofencing' && eventL.notifyPositiveEvent){
                        this.setState({checkedSwitchGeofencingReset:eventL.notifyPositiveEvent});
                    }
                    if(eventL.code == 'disconnection' && eventL.criteriaValue != ""){
                        this.setState({checkedSwitchDesconection:true});
                    }else if(eventL.code == 'battery.percentage' && eventL.criteriaValue != ""){
                        this.setState({checkedSwitchBattery:true});
                    }else if(eventL.code == 'out.of.geofencing' && eventL.criteriaValue != ""){
                        this.setState({checkedSwitchGeofencing:true});
                    }
                })

                setTimeout(() => {
                    this.setState({modalContentDefaultStorage:this.modalContentNewAndUpdate(true,result,false)});
                },5)
                
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("update"), function: this.validateNotifications},
                    {text: i18n.translate("cancel"), function: ""}];  
                    this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("notifications")}
                    content={this.state.modalContentDefaultStorage} buttons={buttons} size={'lg'} modalClass={'modalWithOptionsItem'} />})
                }, 10);
                setTimeout(() => {
                    result.eventList.forEach((eventL) => {
                        document.querySelector(".selectStandard"+eventL.administrativeNotificationId).value = eventL.criteriaValue;
                    })
                },15)
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        }); 
    } 

    deleteEventNotification = (idEventNotification,event) => {
        event.stopPropagation();
        this.setState({
            modalContent:"",
            notificationIdForDelete: idEventNotification
        });
        let modalContent;
        modalContent = i18n.translate("delete_confirm_configuration");
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmDeleteNotification},
            {text: i18n.translate("cancel"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_confirm")}
            content={modalContent} buttons={buttons}/>})
        }, 10);
    }

    confirmDeleteNotification = () => {
        DefaultAjax('').delete("adminNotifications/adminNotifications?instanceId="+this.state.notificationIdForDelete)
        .then((res) => {
            if(res){
                if(res.status == 200){
                    toastFunction(i18n.translate("notification_deleted"),"success");
                    this.setState({modalContent:""});
                    setTimeout(()=> {
                        this.showAll();
                    }, 100);
                }else{
                    let result = JSON.parse(res.data);
                    this.setState({modalContent2:""});
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                    setTimeout(()=> {
                        let buttons = [{text: i18n.translate("default_accept"), function: ""}]  
                        this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("default_errors")}
                        content={this.errorsListHtml(result.errorsList)} buttons={buttons}/>}) 
                    }, 10);
                }  
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 
    

    render(){
        return (
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <br/>
                <nav className='navbar'>
                    <div className='contentInput row' style={{width:'80%'}}>
                        {this.state.selectOrg} 
                    </div>
                    <div style={{width:'20%',paddingTop: "22px"}}>
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={() => this.getNotificationsList()}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_search")}</span>
                            </button>
                        </div>
                        {' '}
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={() => this.showAll()}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_show_all")}</span>
                            </button>
                        </div>
                    </div>
                </nav>
                <div id='bodyContent' className='bodyContent'>
                    {this.state.spinner}
                    <Table className='table table-hover divIndexTable'>
                        <thead>
                            <tr>
                                <th className='firstcell'>{i18n.translate("description")}</th>
                                <th className='firstcell'>{i18n.translate("notify_by")}</th>
                                <th className='firstcell'>{i18n.translate("default_schedule")}</th>
                                <th className='firstcell'>{i18n.translate("recipients")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.eventNotificationList.map((eL) => {
                                return(
                                    <tr key={eL.id} className='cellOptions' onClick={() => this.showEventNotification(eL) }>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <div style={{display:'inline-block',padding: '3% 0'}}>
                                                    <img alt='' className='removeContent' onClick={(event) => this.deleteEventNotification(eL.notificationInstanceId,event)}  
                                                    src={RemoveContent} width="20px" height="20px" style={{margin: '30px 0px'}}/>
                                                </div>
                                                <div>
                                                    <ul style={{display:'inline-block'}}>
                                                        {eL.notificationList.map((notification) => {
                                                            return(
                                                                <li key={notification.description}>
                                                                    {notification.description} 
                                                                    <span className='bolder'>({notification.criteriaValue}{notification.unitOfMeasurement})</span>
                                                                </li>
                                                            )
                                                        })} 
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text cellgroup'>
                                            <ul>
                                                {eL.notifyBy.map((n,index) => {
                                                    return(
                                                        <li key={index}>
                                                            {n} 
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                        <td className='text cellgroup'>
                                            <ul>
                                                {eL.days.map((dh,index) => {
                                                    return (
                                                        <li key={index}>
                                                            {dh.days} 
                                                            <div>
                                                                {i18n.translate("default_from")} {dh.startHour} {i18n.translate("default_to")} {dh.endHour}
                                                            </div> 
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                        <td className='text cellgroup'>
                                            <ul>
                                                {eL.recipients.map((r,index) => {
                                                    return(
                                                        <li key={index}>
                                                            {r} 
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </Table>
                </div>
                <div>
                    <img className='addContent' src={AddContent} width="25px" height="25px" onClick={() => this.addNotification()} />
                </div>
            </>
        )
    } 
} 

NotificationEvents.propTypes = {
    token: PropTypes.string.isRequired, 
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired
};

export default NotificationEvents;