import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import i18n from "i18n-react"
import PropTypes from 'prop-types';

const url = process.env.REACT_APP_API_URL;

class SelectGIE extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideLabel: props.modal,
            classSelect: props.classSelect,
            classSelectType: props.classSelectType,
            display: "inline-block",
            paddingBottom: "",
            selectTypeDefault: props.selectTypeDefault,
            users: [],
            userSelected: "",
            userSelectedDescription:"",
            usernameSelected: "",
            userDefaultSelected: props.userDefaultSelected,
            userDefaultSelectedValue: props.userDefaultSelectedValue,
            groups:[],
            groupSelected:"",
            groupSelectedDescription:"",
            profiles:[],
            profileSelected:"",
            profileSelectedDescription:"",
            allUsers: props.allUsers,
            modalReport: props.modalReport,
            userSelect:"",
            groupSelect:"",
            profileSelect:"",
            moduleCode: props.moduleCode || null
        }
        if(props.modal === "true" || props.modalReport){
            this.state.classSelect = "col-sm-12 col-md-12 col-lg-12 col-xl-12 selectGIE";
            this.state.classSelectType = "col-sm-12 col-md-12 col-lg-12 col-xl-12";
            if(!props.modalReport){
                this.state.display = "none";
            }
            this.state.paddingBottom = "12px"
        }
    }
    componentDidMount() {
        this.getAllOrgNodeList();
    }


    getAllOrgNodeList = () => {
        let stylesForSelectContent = {
            menuPortal: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'}),
            menu: provided => ({ ...provided, zIndex: 9999, fontSize: '11px'})
       }
        let users = [];
        let groups = [];
        let profiles = [];
        axios({
            method: 'GET',
            url: url+"orgNode/allOrgNodeList?moduleCode="+this.state.moduleCode,
            headers: {'Authorization': document.querySelector("#token").value},
            transformResponse: [(data) => {
                let result = JSON.parse(data)
                if(!this.state.hideLabel || this.state.allUsers){
                    users.push({"value":"","label":i18n.translate("default_all"),"username":""})
                } 
                for(const eon of result.eonList){
                    users.push({"value":eon.id,"label":eon.fullname+" - "+eon.username,"username":eon.username})
                } 
                let labelUserSelected = users[0].label;
                let valueUserSelected = users[0].value;
                if(this.state.userDefaultSelected){
                    labelUserSelected = this.state.userDefaultSelected;
                    valueUserSelected =  this.state.userDefaultSelectedValue;
                } 
                this.setState({
                    users:users,
                    userSelectedDescription: labelUserSelected,
                    userSelected: valueUserSelected,  
                    userSelect: <Select menuShouldScrollIntoView={false} onChange={this.handleChangeUser} maxMenuHeight={220} 
                    options={users} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                    defaultValue={{ label: labelUserSelected , value: valueUserSelected }}/>
                });
                for (const gon of result.gonList) {
                    groups.push({ "value": gon.id, "label": gon.description });
                }
                this.setState({
                    groups:groups,
                    groupSelectedDescription:groups[0].label,
                    groupSelected: groups[0].value,
                    groupSelect: <Select menuShouldScrollIntoView={false} onChange={this.handleChangeGroup} maxMenuHeight={220} 
                    options={groups} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent} 
                    defaultValue={{ label: groups[0].label, value: groups[0].value }}/>
                });
                for(const ion of result.ionList){
                    profiles.push({"value":ion.id,"label":ion.description})
                } 
                this.setState({
                    profiles:profiles,
                    profileSelectedDescription:profiles[0].label,
                    profileSelected: profiles[0].value,  
                    profileSelect:<Select menuShouldScrollIntoView={false} onChange={this.handleChangeProfile} maxMenuHeight={220} 
                    options={profiles} menuPortalTarget={document.body} menuPosition={'fixed'} styles={stylesForSelectContent}
                    defaultValue={{ label: profiles[0].label, value: profiles[0].value }}/>
                }); 
            }]
        })
    } 

    handleChange=e=>{
        let selects;
        let isLabelIfLabelGIE = false;
        let labelIfLabelGIE = "";
        if(this.state.hideLabel === "true"){
            selects = document.querySelectorAll(".modalBody .selectGIE");
        }else{
            selects = document.querySelectorAll(".contentInput .selectGIE");
        }
        if(document.querySelector(".typeLabelGIE")){
            isLabelIfLabelGIE = true;
        } 
        
        let arraySelects = Array.from(selects);
        let arraySelectsItem = arraySelects.map((s) => s.style.display = "none");
        if(e.target.value === "1"){
            if(this.state.hideLabel === "true"){
                document.querySelector(".modalBody #profileSelect").style.display = "inline-block";
            }else{
                document.querySelector(".contentInput #profileSelect").style.display = "inline-block";
            }
            labelIfLabelGIE = i18n.translate("default_profile")+":";
        }else if(e.target.value === "2"){
            if(this.state.hideLabel === "true"){
                document.querySelector(".modalBody #groupSelect").style.display = "inline-block";
            }else{
                document.querySelector(".contentInput #groupSelect").style.display = "inline-block";
            }
            labelIfLabelGIE = i18n.translate("default_group")+":";
        }else{
            if(this.state.hideLabel === "true"){
                document.querySelector(".modalBody #userSelect").style.display = "inline-block";
            }else{
                document.querySelector(".contentInput #userSelect").style.display = "inline-block";
            }
            labelIfLabelGIE = i18n.translate("default_user")+":";
        }
        if(isLabelIfLabelGIE){
            document.querySelector(".typeLabelGIE").textContent = labelIfLabelGIE;
        } 
    }

    handleChangeUser = (option) => {
        this.setState({userSelected:option.value,usernameSelected:option.username,userSelectedDescription:option.label});
        setTimeout(() => {
            const inputElement = document.querySelector("#userSelectedDescription");
            if (inputElement) {

                const event = new Event('input', {
                    bubbles: true,
                    cancelable: true
                });
                inputElement.dispatchEvent(event); // Despachar el evento
            }
        }, 10);
    };

    handleChangeGroup = (option) => {
        this.setState({groupSelected:option.value})
        this.setState({groupSelectedDescription:option.label});
    }

    handleChangeProfile = (option) => {
        this.setState({profileSelected:option.value})
        this.setState({profileSelectedDescription:option.label});
    }

    render() {
        return (
            <>
                <div style={{ display: 'inline-block',marginBottom: this.state.paddingBottom }} className={this.state.classSelectType}>
                    {this.state.hideLabel !== "true" && <label>{i18n.translate("default_type")}</label>}
                    <select className="form-select selectStandard selectType" onChange={this.handleChange}>
                    {this.state.hideLabel === "true" && !this.state.modalReport && <option value='' selected>{i18n.translate("default_please_select")}</option>}
                        <option value="3" id="user">{i18n.translate("default_user")}</option>
                        <option value="1" id="profile">{i18n.translate("default_profile")}</option>
                        <option value="2" id="group">{i18n.translate("default_group")}</option>
                    </select>
                </div>
                {' '}
                {this.state.hideLabel === "true" && <div>
                    
                    </div>}
                <div style={{ display: this.state.display }} id="userSelect" className={this.state.classSelect}>
                    {this.state.hideLabel !== "true" && <label>{i18n.translate("default_user")}</label>}
                    <input id='userSelected' type="text" readOnly style={{ display: 'none' }} value={this.state.userSelected}/>
                    <input id='userSelectedDescription' readOnly type="text" style={{ display: 'none' }} value={this.state.userSelectedDescription}/>
                    <input id='usernameSelected' readOnly type="text" style={{ display: 'none' }} value={this.state.usernameSelected}/> 
                    {this.state.userSelect}
                </div>
                {' '}
                <div style={{ display: 'none' }} id="groupSelect" className={this.state.classSelect}>
                    {this.state.hideLabel !== "true" && <label>{i18n.translate("default_group")}</label>}
                    <input id='groupSelected' type="text" readOnly style={{ display: 'none' }} value={this.state.groupSelected}/>
                    <input id='groupSelectedDescription' readOnly type="text" style={{ display: 'none' }} value={this.state.groupSelectedDescription}/>
                    {this.state.groupSelect} 
                </div>
                {' '}
                <div style={{ display: 'none' }} id="profileSelect" className={this.state.classSelect}>
                    {this.state.hideLabel !== "true" && <label>{i18n.translate("default_profile")}</label>}
                    <input id='profileSelected' type="text" readOnly style={{ display: 'none' }} value={this.state.profileSelected}/>
                    <input id='profileSelectedDescription' readOnly type="text" style={{ display: 'none' }} value={this.state.profileSelectedDescription}/>
                    {this.state.profileSelect}
                </div>
            </>
        )
    }
}

SelectGIE.propTypes = {
    userDefaultSelected: PropTypes.string.isRequired,
    userDefaultSelectedValue: PropTypes.string.isRequired
} 

export default SelectGIE;