import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { Table, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {saveEditConfigApi} from "../adminContent/AbmFunctions";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import i18n from "i18n-react";
import { toastFunction } from '../../components/mainFunctions';

const url = process.env.REACT_APP_API_URL;

class AbmAudit extends React.Component {
  constructor(props) {
		super(props);
    this.state={
      data: [],
      listConfig: [],
      form:{
        id:'',
        minutesIntervalThreshold: '',
        numberOfInvocations:'',
        secondsBetweenInvocations: '',
        tenantId: '',
        serviceName: ''
      },
      modalInsertar: false,
      modalModificar: false,
      modalEliminar:false,
      enterprises:[],
      listEnterprises:[],
    };
	}
  
  getConfigs=()=>{
    DefaultAjax('').get("accessControl/accessControl")
    .then((res) => {
        if(res){
          let result = JSON.parse(res.data);
          this.setState({
            data:result,
            listConfig:result
          });
        }
    }).catch((error) =>{
        this.setState({
            modalContent:"",
            spinner:"",
            component:error.message
        });
        if (error.response) {
            if(error.response.status === 403){
                setTimeout(()=> { 
                    this.setState({modalContent:modalForbidden()});
                }, 10);
            } 
        }
    });
  }

  getEnterprises=()=>{
    DefaultAjax('').get("accessControl/getServices")
    .then((res) => {
        if(res){
          let result = JSON.parse(res.data);
          this.setState({
            enterprises:result,
            listEnterprises:result
          });
        }
    }).catch((error) =>{
        this.setState({
            modalContent:"",
            spinner:"",
            component:error.message
        });
        if (error.response) {
            if(error.response.status === 403){
                setTimeout(()=> { 
                    this.setState({modalContent:modalForbidden()});
                }, 10);
            } 
        }
    });

  } 


  componentDidMount(){
    this.getConfigs();
  }
  
  handleChange=e=>{
    if(e.target.options){
      let index = e.target.selectedIndex;
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: e.target.options[index].id,
          serviceName: e.target.options[index].value
        }
      });
    }else{
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: e.target.value,
        }
      });
    } 
  }

  mostrarModalInsertar =()=>{
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        id: "",
        minutesIntervalThreshold: "",
        numberOfInvocations: "",
        secondsBetweenInvocations: "",
        tenantId: "",
        serviceName: ""
      }
    }));
    this.getEnterprises();
    this.setState({modalInsertar:true})
  }
  cerrarModalInsertar =()=>{
    this.setState({modalInsertar:false})
  }

  mostrarModalModificar =(config)=>{
    this.setState({modalModificar:true,form:config})
  }
  cerrarModalModificar =()=>{
    this.setState({modalModificar:false})
  }

  insertarConfiguracion =()=>{
    let newConfiguration = {...this.state.form};
    let status = saveEditConfigApi(newConfiguration);
    if(status == 1){
      toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
    }else{
      DefaultAjax('').post("accessControl/accessControl",newConfiguration)
      .then((res) => {
          if(res){
            let result = JSON.parse(res.data);
            if(result.status == 0|| result.status == "0"){
              toastFunction(i18n.translate("configuration_created"),"success");
              this.getConfigs();
              this.setState({modalInsertar: false});
              newConfiguration.id = result.id;
              this.mostrarModalModificar(newConfiguration);
            }else{
              toastFunction(result.message,"error");
            }
          }
      }).catch((error) =>{
          this.setState({
              modalContent:"",
              spinner:"",
              component:error.message
          });
          if (error.response) {
              if(error.response.status === 403){
                  setTimeout(()=> { 
                      this.setState({modalContent:modalForbidden()});
                  }, 10);
              } 
          }
      });
    } 
    
  }

  actualizarConfiguracion =(config)=>{
    if(config.minutesIntervalThreshold == "" || 
      config.numberOfInvocations == "" || 
      config.secondsBetweenInvocations == "" || 
      config.tenantId == ""){
      toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
    }else{
      DefaultAjax('').put("accessControl/accessControl",config)
      .then((res) => {
          if(res){
            let result = JSON.parse(res.data);
            if(result.status == 0|| result.status == "0"){
              toastFunction(i18n.translate("configuration_modified"),"success");
              this.getConfigs();
            }else{
              toastFunction(result.message,"error");
            }
          }
      }).catch((error) =>{
          this.setState({
              modalContent:"",
              spinner:"",
              component:error.message
          });
          if (error.response) {
              if(error.response.status === 403){
                  setTimeout(()=> { 
                      this.setState({modalContent:modalForbidden()});
                  }, 10);
              } 
          }
      });
    } 
  }

  modalEliminarConfigurcion = (config)=>{
    this.setState({modalEliminar:true,form:config})
  }
  cerrarModalEliminar =()=>{
    this.setState({modalEliminar:false})
  } 

  eliminarConfiguracion =(id)=>{
    DefaultAjax('').delete("accessControl/accessControl/"+id)
    .then((res) => {
        if(res){
          let result = JSON.parse(res.data);
          if(result.status == 0|| result.status == "0"){
            toastFunction(i18n.translate("configuration_deleted"),"success");
            this.setState({modalEliminar:false});
            this.getConfigs();
          }else{
            toastFunction(result.message,"error");
          }
        }
    }).catch((error) =>{
        this.setState({
            modalContent:"",
            spinner:"",
            component:error.message
        });
        if (error.response) {
            if(error.response.status === 403){
                setTimeout(()=> { 
                    this.setState({modalContent:modalForbidden()});
                }, 10);
            } 
        }
    });
    
  } 

  filterConfig =e=>{
    let filterSearch = e.target.value.toLowerCase()

    let searchRes = this.state.data.filter((e) => {
      let serviceName = e.serviceName.toLowerCase();
      return serviceName.indexOf(filterSearch) !== -1
    })
      if(e.target.value === ''){
        searchRes = this.state.listConfig;
      }
      this.setState({data:searchRes})
  }
  
  render(){
    return (
      <>
        <div>
          <br/>
          <div style={{display:'inline-block'}}>
            <input className="form-control inputStandard" type='text' onChange={this.filterConfig}/>
          </div>
          {' '}
          <div style={{display:'inline-block'}}>
            <button className='buttonMzateSecondary' onClick={()=>this.mostrarModalInsertar()}>Nueva configuración</button>
          </div>
          <br/><br/>
          
          <Table>
            <thead className="table-dark">
              <tr>
                <th>minutesIntervalThreshld</th>
                <th>numberOfInvocations</th>
                <th>secondsBetweenInvocations</th>
                <th>tenantId</th>
                <th>ServiceName</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
             {this.state.data.map((elemento)=>(
               <tr>
                 <td>{elemento.minutesIntervalThreshold}</td>
                 <td>{elemento.numberOfInvocations}</td>
                 <td>{elemento.secondsBetweenInvocations}</td>
                 <td>{elemento.tenantId}</td>
                 <td>{elemento.serviceName}</td>
                 <td>
                    <FaEdit title='Editar' onClick={()=>this.mostrarModalModificar(elemento)} style={{fontSize: '25px', cursor: 'pointer'}}/>
                    {" "} 
                    <FaTrash title='Eliminar' onClick={()=>this.modalEliminarConfigurcion(elemento)} style={{fontSize: '25px', cursor: 'pointer'}}/>
                 </td>
               </tr>
               ))} 
            </tbody>
          </Table>
        </div>

        <Modal isOpen={this.state.modalInsertar}>
          <ModalHeader className='modalHeader'>
            Nueva Configuracion
            <button className='close' data-dismiss="close" onClick={()=>this.cerrarModalInsertar()}>x</button>
          </ModalHeader>
          <ModalBody className='modalBody'>
              <FormGroup>
              <label>
                ServiceName
                <select class="form-select" onChange={this.handleChange} name="tenantId">
                  <option value="">Seleccione</option>
                  {this.state.enterprises.map((elemento)=>(
                    <option id={elemento.serviceId}>{elemento.name}</option>
                  ))}
                </select>
              </label>
              </FormGroup>
              <FormGroup>
                  <label>minutesIntervalThreshld</label>
                  <input placeholder='Intervalo de tiempo para control de acceso' className='form-control inputStandard' type="number" name="minutesIntervalThreshold" onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup>
                  <label>numberOfInvocations</label>
                  <input placeholder='Cantidad de invocaciones' className='form-control inputStandard' type="number" name="numberOfInvocations" onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup>
                  <label>secondsBetweenInvocations</label>
                  <input placeholder='Tiempo entre invocaciones' className='form-control inputStandard' type="number" name="secondsBetweenInvocations" onChange={this.handleChange}/>
              </FormGroup>
          </ModalBody>
          <ModalFooter>
              <button className="buttonMzatePrimary" onClick={()=>this.insertarConfiguracion()}>Guardar</button>
              <button className="buttonMzateSecondary" onClick={()=>this.cerrarModalInsertar()}>Cancelar</button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalModificar}>
          <ModalHeader className='modalHeader'>
            Modificar Configuracion
            <button className='close' data-dismiss="close" onClick={()=>this.cerrarModalModificar()}>x</button>
          </ModalHeader>
          <ModalBody className='modalBody'>
              <FormGroup>
                  <label>ServiceName</label>
                  <input readOnly className='form-control inputStandard' type="text" name="serviceName" value={this.state.form.serviceName} onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup>
                  <label>minutesIntervalThreshld</label>
                  <input className='form-control inputStandard' type="number" name="minutesIntervalThreshold" value={this.state.form.minutesIntervalThreshold} onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup>
                  <label>numberOfInvocations</label>
                  <input className='form-control inputStandard' type="number" name="numberOfInvocations" value={this.state.form.numberOfInvocations} onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup>
                  <label>secondsBetweenInvocations</label>
                  <input className='form-control inputStandard' type="number" name="secondsBetweenInvocations" value={this.state.form.secondsBetweenInvocations} onChange={this.handleChange}/>
              </FormGroup>
          </ModalBody>
          <ModalFooter>
              <button className="buttonMzatePrimary" onClick={()=>this.actualizarConfiguracion(this.state.form)}>Actualizar</button>
              <button className="buttonMzateSecondary" onClick={()=>this.cerrarModalModificar()}>Cancelar</button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalEliminar}>
          <ModalHeader className='modalHeader'>
            Eliminar Configuración
            <button className='close' data-dismiss="close" onClick={()=>this.cerrarModalEliminar()}>x</button>
          </ModalHeader>
          <ModalBody className='modalBody'>
              <FormGroup>
                  <label>Confirma que desea eliminar la configuración?</label>
              </FormGroup>
          </ModalBody>
          <ModalFooter>
              <button className="buttonMzatePrimary" onClick={()=>this.eliminarConfiguracion(this.state.form.id)}>Eliminar</button>
              <button className="buttonMzateSecondary" onClick={()=>this.cerrarModalEliminar()}>Cancelar</button>
          </ModalFooter>
        </Modal>
        
      </>
    );
  }
  
}

export default AbmAudit;