import React from "react";
import { toastFunction, dateRangeForSearh, handleDateRangeCallback } from "../../components/mainFunctions";
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {  Row, Col } from 'reactstrap';
import DefaultModal from '../../components/DefaultModal';
import AddContent from "../../img/add_content.png";
import RemoveContent from "../../img/remove_content.png";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import DefaultSpinner from '../../components/DefaultSpinner';

class PriceList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            spinner: "",
            modalContent: "",
            modalContent2: "",
            dateRange:[],
            startDate: moment().add(-12, 'hour'),
            endDate: moment().add(12, 'hour'),
            priceList: [],
            currentPriceList: "",
            priceListForFilter: "" 
        }
        this.state.dateRange.push(dateRangeForSearh(new Date(moment().add(-12, 'hour'))));
        this.state.dateRange.push(dateRangeForSearh(new Date(moment().add(12, 'hour')))); 
    }

    componentDidMount() {
        this.getAllPriceList();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let altura = window.innerHeight - nav - 90;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
    }

    handleCallback = (start, end) => {
        handleDateRangeCallback(start, end, this.setState.bind(this));
    }

    getAllPriceList = (showAll) => {
        // let priceList = [
        //     {
        //         id:1,
        //         priceListName: "Lista1",
        //         priceListDescription: "Descripcion1",
        //         totalClients: 2,
        //         statePriceList: 0,
        //         priceListValidFrom: "2024-04-09",
        //         priceListValidTo: "",
        //         products: [
        //             {
        //                 id:1,
        //                 productCode:"01",
        //                 productDescription:"product1",
        //                 price:"1000",
        //                 tax:"10",
        //             },
        //             {
        //                 id:2,
        //                 productCode:"02",
        //                 productDescription:"product2",
        //                 price:"2000",
        //                 tax:"10",
        //             }
        //         ], 
        //         offices: [
        //             {
        //                 id:1,
        //                 officeCode:1111,
        //                 customerName:"customerName1",
        //                 officeName: "officeName1",
        //                 officeDescription: "officeDescription1",
        //                 officeType:0
        //             } 
        //         ],
        //         officesToAdd: [
        //             {
        //                 id:2,
        //                 officeCode:2222,
        //                 customerName:"customerName2",
        //                 officeName: "officeName2",
        //                 officeDescription: "officeDescription2",
        //                 officeType:1
        //             },
        //             {
        //                 id:3,
        //                 officeCode:3333,
        //                 customerName:"customerName3",
        //                 officeName: "officeName3",
        //                 officeDescription: "officeDescription3",
        //                 officeType:2
        //             } 
        //         ],
        //         productsToAdd: [
        //             {
        //                 id:3,
        //                 productCode:"03",
        //                 productDescription:"product3",
        //                 price:"3000",
        //                 tax:"10",
        //             },
        //             {
        //                 id:4,
        //                 productCode:"04",
        //                 productDescription:"product4",
        //                 price:"4000",
        //                 tax:"11",
        //             }
        //         ]   
        //     },
        //     {
        //         id:2,
        //         priceListName: "Lista2",
        //         priceListDescription: "Descripcion2",
        //         totalClients: 2,
        //         statePriceList: 0,
        //         priceListValidFrom: "2024-04-09",
        //         priceListValidTo: "2024-04-09",
        //         products: [
        //             {
        //                 id:3,
        //                 productCode:"03",
        //                 productDescription:"product3",
        //                 price:"3000",
        //                 tax:"10",
        //             },
        //             {
        //                 id:4,
        //                 productCode:"04",
        //                 productDescription:"product4",
        //                 price:"4000",
        //                 tax:"11",
        //             }
        //         ],
        //         productsToAdd: [
        //             {
        //                 id:1,
        //                 productCode:"01",
        //                 productDescription:"product1",
        //                 price:"1000",
        //                 tax:"10",
        //             },
        //             {
        //                 id:2,
        //                 productCode:"02",
        //                 productDescription:"product2",
        //                 price:"2000",
        //                 tax:"10",
        //             }
        //         ], 
        //         offices: [
        //             {
        //                 id:1,
        //                 officeCode:1111,
        //                 customerName:"customerName1",
        //                 officeName: "officeName1",
        //                 officeDescription: "officeDescription1",
        //                 officeType:0
        //             } 
        //         ],
        //         officesToAdd: [
        //             {
        //                 id:2,
        //                 officeCode:2222,
        //                 customerName:"customerName2",
        //                 officeName: "officeName2",
        //                 officeDescription: "officeDescription2",
        //                 officeType:1
        //             },
        //             {
        //                 id:3,
        //                 officeCode:3333,
        //                 customerName:"customerName3",
        //                 officeName: "officeName3",
        //                 officeDescription: "officeDescription3",
        //                 officeType:2
        //             } 
        //         ]   
        //     },
        //     {
        //         id:3,
        //         priceListName: "Lista3",
        //         priceListDescription: "Descripcion3",
        //         totalClients: 2,
        //         statePriceList: 1,
        //         priceListValidFrom: "2024-04-09",
        //         priceListValidTo: "",
        //         products: [],
        //         offices: [],
        //         productsToAdd: []   
        //     },
        //     {
        //         id:4,
        //         priceListName: "Lista4",
        //         priceListDescription: "Descripcion4",
        //         totalClients: 2,
        //         statePriceList: 1,
        //         priceListValidFrom: "2024-04-09",
        //         priceListValidTo: "",
        //         products: [],
        //         offices: [],
        //         productsToAdd: []  
        //     },
        //     {
        //         id:5,
        //         priceListName: "Lista5",
        //         priceListDescription: "Descripcion5",
        //         totalClients: 2,
        //         statePriceList: 0,
        //         priceListValidFrom: "2024-04-09",
        //         priceListValidTo: "2024-04-09",
        //         products: [],
        //         offices: [],
        //         productsToAdd: []  
        //     }
        // ] 
        if(showAll){
            document.querySelector("#priceListName").value = '';
            document.querySelector("#priceListDescription").value = '';
            this.setState({
                startDate: '',
                endDate: '',
                dateRange: ['',''] 
            })
        } 

        let dto = {};

        dto.startDate = this.state.startDate;
        dto.endDate = this.state.endDate;
        dto.priceListName = document.querySelector("#priceListName").value;
        dto.priceListDescription = document.querySelector("#priceListDescription").value;

        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})

        DefaultAjax('').get("electronicBilling/priceList?paramsJSON="+encodeURI(JSON.stringify(dto)))
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data)
                this.setState({
                    priceList:result,
                    spinner:"",
                })
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    processProductCreateUpdateResult = (result) => {
        if(result.status == "-1" || result.status == -1){
            this.setState({spinner:""});
            this.getAllPriceList();
        }else{
            toastFunction(i18n.translate("an_error_has_ocurred"),"error");
        } 
    } 

    createPriceList = () => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})
        DefaultAjax('').post("/electronicBilling/priceList",this.state.currentPriceList)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.processProductCreateUpdateResult(result);
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        })
    } 


    showPriceListContent = (priceList,isCreate) => {
        const changeValuePriceList = (input,e) => {
            if(input == 'priceListName'){
                priceList.priceListName = e.target.value;
            }else if(input == 'priceListDescription'){
                priceList.priceListDescription = e.target.value;
            }else if(input == 'priceListValidFrom'){
                priceList.priceListValidFrom = e.target.value;
            }else if(input == 'priceListValidTo'){
                priceList.priceListValidTo = e.target.value;
            }   
            this.showPriceList(priceList,true,isCreate);
        }

        const filterProductsOfPriceList = (e) =>{
            let filterSearch = e.target.value.toLowerCase();

            this.arrayProductToFilter(priceList.productsToAdd,filterSearch);

            this.arrayProductToFilter(priceList.products,filterSearch)

        } 
        return(
            <>
                <div className="form-group row">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label>
                            {i18n.translate("default_name")} 
                        </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                        <input type="text" className="form-control inputStandard" 
                        maxLength={30} value={priceList.priceListName} onChange={(e) => changeValuePriceList('priceListName',e)}/>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label>
                            {i18n.translate("description")}
                        </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                        <input type="text" className="form-control inputStandard" 
                        maxLength={100} value={priceList.priceListDescription} onChange={(e) => changeValuePriceList('priceListDescription',e)}/>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label>
                            {i18n.translate("state")}
                        </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" style={{display:'flex'}}>
                        <div> 
                            {priceList.statePriceList == 0 ? 
                                <label for="active"><input id="active" type="radio" name="priceListStatus" value="active" checked />{i18n.translate("default_active")}</label> 
                             : 
                                <label for="active"><input id="active" type="radio" name="priceListStatus" value="active" />{i18n.translate("default_active")}</label>
                            } 
                        </div>
                        <div style={{marginLeft: '20%'}}> 
                            {priceList.statePriceList == 1 ? 
                                <label for="inactive"><input id="inactive" type="radio" name="priceListStatus" value="inactive" checked/>{i18n.translate("default_inactive")}</label> 
                             : 
                                <label for="inactive"><input id="inactive" type="radio" name="priceListStatus" value="inactive"/>{i18n.translate("default_inactive")}</label>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label style={{display:'block'}}>
                            {i18n.translate("date_from")}
                        </label>
                        <input type="date" id="priceListValidFrom" className="form-control inputStandard" 
                        value={priceList.priceListValidFrom} onChange={(e) => changeValuePriceList('priceListValidFrom',e)}/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label style={{display:'block'}}>
                            {i18n.translate("date_to")}
                        </label>
                        <input type="date" id="priceListValidTo" className="form-control inputStandard" 
                        value={priceList.priceListValidTo} onChange={(e) => changeValuePriceList('priceListValidTo',e)}/>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <label>{i18n.translate("default_search")}</label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input type="text" className="form-control inputStandard" onChange={(e) => filterProductsOfPriceList(e)}/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <button className='buttonMzateSecondary' onClick={() => this.showOffices()}>
                            {i18n.translate("stablishments")} 
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {priceList.productsToAdd.map((productsAdd,index) => {
                                return(
                                    <div className="productDiv" id={'productDiv'+productsAdd.id} key={productsAdd.id}>
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <label>{productsAdd.productCode}-{productsAdd.productDescription}</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label>{i18n.translate("current_price")}</label>
                                                <input type="text" readOnly value={productsAdd.price} className="form-control inputStandard"/>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label>{i18n.translate("new_price")}</label>
                                                <input type="number" value={productsAdd.price} className="form-control inputStandard"/>
                                            </div>
                                        </div>
                                        <img className='addContent' onClick={()=> this.addRemoveProduct(index,'remove')} src={RemoveContent} style={{marginLeft: '25px'}} width="20px" height="20px"/>
                                    </div>
                                )
                            })}
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {priceList.products.map((products,index) => {
                                return(
                                    <div className="productDiv" id={'productDiv'+products.id} key={products.id}>
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <label>{products.productCode}-{products.productDescription}</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label>{i18n.translate("current_price")}</label>
                                                <input type="text" readOnly value={products.price} className="form-control inputStandard"/>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label>{i18n.translate("new_price")}</label>
                                                <input type="number" value={products.price} className="form-control inputStandard"/>
                                            </div>
                                        </div>
                                        <img className='addContent' onClick={()=> this.addRemoveProduct(index,'add')} src={AddContent} style={{marginLeft: '25px'}} width="20px" height="20px"/>
                                    </div>
                                )
                            })} 
                    </div>
                </div>
            </>
        )
    } 

    arrayProductToFilter = (arrayProduct,filterSearch) => {
        arrayProduct.filter((e) => {
            let productDescription = e.productDescription.toLowerCase();
            let productCode = e.productCode.toLowerCase();
            let price = e.price.toString().toLowerCase();
            if(productDescription.indexOf(filterSearch) !== -1 || productCode.indexOf(filterSearch) !== -1 
            || price.indexOf(filterSearch) !== -1){
                document.querySelector("#productDiv"+e.id).style.display = '';
            }else{
                document.querySelector("#productDiv"+e.id).style.display = 'none';
            } 
            if(filterSearch == ''){
                let allLi = document.querySelectorAll(".productDiv");
                 
                allLi.forEach(li => {
                    li.style.display = '';
                })
            }  
        })
    } 

    officeModalContent = (currentPriceList) => {
        let addRemoveOfficePriceList = (index,addOrRemove) => {
            let officesToAdd = this.state.currentPriceList.officesToAdd;
            let offices = this.state.currentPriceList.offices;
            let currentPriceList = this.state.currentPriceList;
            if(addOrRemove == 'add'){
                officesToAdd.push(offices[index]);
                offices.splice(index,1);
            }else{
                offices.push(officesToAdd[index]);
                officesToAdd.splice(index,1);
            }  
            currentPriceList.officesToAdd = officesToAdd;
            currentPriceList.offices = offices;
            this.showOffices(true,currentPriceList);
        }

        let searchOfficesToAdd = (e, typeOffices) => {
            let filterSearch = e.target.value.toLowerCase();

            let mapToSearch;

            if(typeOffices == 'officesToAdd'){
                mapToSearch = currentPriceList.officesToAdd;
            }else{
                mapToSearch = currentPriceList.offices;
            }  

            mapToSearch.filter((e) => {
                let officeDescription = e.officeDescription.toLowerCase();
                let officeName = e.officeName.toLowerCase();
                let officeCode = e.officeCode.toString().toLowerCase();
                if(officeDescription.indexOf(filterSearch) !== -1 || officeName.indexOf(filterSearch) !== -1 
                || officeCode.indexOf(filterSearch) !== -1){
                    document.querySelector("#liOffice"+e.id).style.display = '';
                }else{
                    document.querySelector("#liOffice"+e.id).style.display = 'none';
                } 
                if(filterSearch == ''){
                    let allLi;
                    if(typeOffices == 'officesToAdd'){
                        allLi = document.querySelectorAll(".officesToAdd li");
                    }else{
                        allLi = document.querySelectorAll(".offices li");
                    } 
                    allLi.forEach(li => {
                        li.style.display = '';
                    })
                }  
            })
        } 

        return(
            <Row>
                <Col>
                    <div>
                        <input type="text" className="form-control inputStandard" 
                        placeholder="Buscar no asignados" onChange={(e)=> searchOfficesToAdd(e,'offices')}/>
                    </div>
                    <div>
                        <ul className="officeToAddOrRemove offices">
                            {currentPriceList.offices.map((office,index) => {
                                return(
                                    <li key={office.id} id={'liOffice'+office.id}>
                                        <div style={{width:'100%',display:'inline-flex'}}>
                                            <div style={{width:'90%'}}>
                                                <div style={{fontWeight:'bolder',fontSize:'11px'}}>
                                                    {office.officeName}-{office.officeDescription} 
                                                </div> 
                                                <div style={{fontSize:'10px'}}>
                                                    {office.officeCode}-{i18n.translate("office_type_"+office.officeType)}
                                                </div>
                                            </div>
                                            <div style={{width:'5%'}}>
                                                <img className='addContent' onClick={()=> addRemoveOfficePriceList(index,'add')} src={AddContent} style={{marginLeft: '25px',float: 'right'}} width="20px" height="20px"/>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="text" className="form-control inputStandard" 
                        placeholder="Buscar asignados" onChange={(e)=> searchOfficesToAdd(e,'officesToAdd')}/>
                    </div>
                    <div>
                        <ul className="officeToAddOrRemove officesToAdd">
                            {currentPriceList.officesToAdd.map((toAdd,index) => {
                                return(
                                    <li key={toAdd.id} id={'liOffice'+toAdd.id}>
                                        <div style={{width:'100%',display:'inline-flex'}}>
                                            <div style={{width:'90%'}}>
                                                <div style={{fontWeight:'bolder',fontSize:'11px'}}>
                                                    {toAdd.officeName}-{toAdd.officeDescription} 
                                                </div> 
                                                <div style={{fontSize:'10px'}}>
                                                    {toAdd.officeCode}-{i18n.translate("office_type_"+toAdd.officeType)}
                                                </div>
                                            </div>
                                            <div style={{width:'5%'}}>
                                                <img className='removeContent' onClick={()=> addRemoveOfficePriceList(index,'remove')} src={RemoveContent} style={{marginLeft: '25px',float: 'right'}} width="20px" height="20px"/>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Col>
            </Row>
        )
    } 


    showOffices = (isUpdateValues,currentPriceList) => {
        if(!currentPriceList){
            currentPriceList = this.state.currentPriceList;
        } 
        this.setState({
            modalContent2:""
        });
        let modalContent;

        modalContent = this.officeModalContent(currentPriceList);

        if(isUpdateValues){
            this.modalOfficeContent(modalContent);
        }else{
            setTimeout(()=> {
                this.modalOfficeContent(modalContent);
            }, 10);
        }
        
    } 

    modalOfficeContent = (modalContent) => {
        let buttons = [{text: i18n.translate("default_accept"), function: ""}];  
        this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("stablishments")}
        content={modalContent} buttons={buttons} size='lg'/>})
    } 

    addRemoveProduct = (index,removeAdd) => {
        let productsToAdd = this.state.currentPriceList.productsToAdd;
        let products = this.state.currentPriceList.products;
        let currentPriceList = this.state.currentPriceList
        if(removeAdd == 'add'){
            productsToAdd.push(products[index]);
            products.splice(index,1);
        }else{
            products.push(productsToAdd[index]);
            productsToAdd.splice(index,1);
        } 
        currentPriceList.products = products;
        currentPriceList.productsToAdd = productsToAdd;
        this.showPriceList(currentPriceList,true,this.state.isCreate);
    } 

    showPriceList = (priceList,isUpdateValues,isCreate) => {
        if(!priceList){
            // priceList = {
            //     id:"",
            //     priceListName: "",
            //     priceListDescription: "",
            //     totalClients: 0,
            //     statePriceList: 0,
            //     priceListValidFrom: "",
            //     priceListValidTo: "",
            //     products: [],
            //     offices: [],
            //     officesToAdd: [
            //         {
            //             id:2,
            //             officeCode:2222,
            //             customerName:"customerName2",
            //             officeName: "officeName2",
            //             officeDescription: "officeDescription2",
            //             officeType:1
            //         },
            //         {
            //             id:3,
            //             officeCode:3333,
            //             customerName:"customerName3",
            //             officeName: "officeName3",
            //             officeDescription: "officeDescription3",
            //             officeType:2
            //         }
            //     ], 
            //     productsToAdd: [
            //         {
            //             id:3,
            //             productCode:"03",
            //             productDescription:"product3",
            //             price:"3000",
            //             tax:"10",
            //         },
            //         {
            //             id:4,
            //             productCode:"04",
            //             productDescription:"product4",
            //             price:"4000",
            //             tax:"11",
            //         }
            //     ]
            // }
        }
        this.setState({
            currentPriceList:priceList,
            modalContent:"",
            isCreate: isCreate
        });
        let modalContent;
        modalContent = this.showPriceListContent(priceList,isCreate);
        if(isUpdateValues){
            this.modalPriceListContent(modalContent,isCreate);
        }else{
            setTimeout(()=> {
                this.modalPriceListContent(modalContent,isCreate);
            }, 10);
        } 
    }

    updatePriceList = () => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})
        DefaultAjax('').put("/electronicBilling/priceList",this.state.currentPriceList)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.processProductCreateUpdateResult(result);  
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        })
    } 
    
    modalPriceListContent = (modalContent,isCreate) => {
        let buttonText = i18n.translate("update");
        let functionToExecute = this.updatePriceList;
        if(isCreate){
            buttonText = i18n.translate("save");
            functionToExecute = this.createPriceList;
        }
        let buttons = [{text: buttonText, function: functionToExecute},
        {text: i18n.translate("default_close"), function: ""}];  
        this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("price_list")}
        content={modalContent} buttons={buttons} size='lg'/>})
    } 

    render(){
        return (
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row style={{ width: '60%'}} className="contentInput">
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("default_name")}</label>
                            <input type="text" className="form-control inputStandard" id="priceListName"/>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("description")}</label>
                            <input type="text" className="form-control inputStandard" id="priceListDescription"/>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("date")}</label>
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                onCallback={this.handleCallback}
                            >
                                <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                            </DateRangePicker>
                            <input type="hidden" id="dateInInputStart"/>
                            <input type="hidden" id="dateInInputEnd"/>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "22px",width: '40%' }}>
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllPriceList(false)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_search")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllPriceList(true)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_show_all")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.showPriceList('',false,true)}>
                                    <FaIcons.FaPlus />
                                    <span>{i18n.translate("create_price_list")}</span>
                                </button>
                            </div> 
                    </div>
                </nav>
                <br/>
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner}
                    <div id="divTitle">
                        <span className="title">{i18n.translate("price_list")}</span>
                    </div>
                    <Row style={{padding: '0px 15px 0px 15px'}}>
                        {this.state.priceList.map((price) => {
                            return(
                                <Col key={price.id} onClick={() => this.showPriceList(price)} sm={12} md={6} lg={6} xl={6} style={{marginBottom:'10px'}}>
                                    <div className="priceListDiv">
                                        <div className="bolder">{price.priceListName}</div>
                                        <Row className="priceListTotalDiv">
                                            <Col>
                                                <label className="bolder">
                                                    {i18n.translate("total_clients")} 
                                                </label>
                                                <input type="text" disabled value={price.totalClients} className="form-control inputStandard"/>
                                            </Col>
                                            <Col>
                                                <label className="bolder">
                                                    {i18n.translate("state")} 
                                                </label>
                                                <input type="text" disabled className="form-control inputStandard"
                                                value={price.statePriceList == 0 ? i18n.translate("default_active"): i18n.translate("default_inactive")} />
                                            </Col>
                                        </Row>
                                        <div>
                                            <p>
                                                <span className="bolder">{i18n.translate("valid_from")}: </span>{price.priceListValidFrom}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                {price.priceListDescription}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })} 
                    </Row>
                </div>
            </>
        )
    } 
}

export default PriceList;